import { FC } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type Props = {
    /**
     * Заголовок.
     */
    title: string;

    /**
     * Функция возврата.
     */
    back: () => void;
};

const Header: FC<Props> = ({ back, title, children }) => {
    return (
        <div className='mb-8 flex items-center cursor-pointer' onClick={back}>
            <ChevronLeftIcon />
            <p className='text-xl font-bold'>
                {children}
                {title}
            </p>
        </div>
    );
};

export default Header;
