import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/lab/LoadingButton';

import { useMutation, useQueryClient } from 'react-query';
import { reserveDeposit } from 'api/deposits';

interface ConfirmDepositAssignDialogInterface extends DialogProps {
        depositId: number;
}

const ConfirmDepositAssignDialog: React.FC<ConfirmDepositAssignDialogInterface> = (props) => {
    const queryClient = useQueryClient();

    const assignMutation = useMutation(reserveDeposit, {
        onSuccess: () => {
            queryClient.invalidateQueries('transfers');
            queryClient.invalidateQueries('depositsTableau');
            props.onClose({}, 'backdropClick');
        }
    });

    return (
        <Dialog {...props} maxWidth="sm">
            <DialogTitle>Забронировать депозит?</DialogTitle>
            <DialogActions>
                <Button color="secondary" disabled={assignMutation.isLoading} onClick={() => props.onClose({}, 'backdropClick')}>
                    Отмена
                </Button>
                <Button color="primary" type="submit" disabled={assignMutation.isLoading} onClick={() => assignMutation.mutate({ deposit_id: props.depositId })}>
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDepositAssignDialog;
