import React from 'react';

import { Box, Chip } from '@mui/material';
import LeaderboardPlayers from './Lower/LowerLeaderboard';
import LeaderboardCurators from './Upper/UpperLeaderboard';

const Leaderboard: React.FC = () => {
    const [tab, setTab] = React.useState(0);

    return (
        <>
            <Box className='flex flex-row justify-center'>
                <Box className='flex gap-1'>
                    <Chip label='Нижний' color={tab === 0 ? 'primary' : 'default'} onClick={() => setTab(0)} clickable />
                    <Chip label='Верхний' color={tab === 1 ? 'primary' : 'default'} onClick={() => setTab(1)} clickable />
                </Box>
            </Box>
            <Box className="mt-4 p-1">
                {tab === 0 && <LeaderboardPlayers />}
                {tab === 1 && <LeaderboardCurators />}
            </Box>
      </>
    );
};

export default Leaderboard;
