import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';

import { useQuery, useQueryClient, useMutation } from 'react-query';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { range } from 'lodash';

import theme from 'themes/mainTheme';

import { getCustomStatsFields, createCustomStats } from 'api/curator';

interface CustomStatsDialogProps {
    studentIds: number[];
    open: boolean;

    onClose: () => void;
}

const CustomStatsDialog: React.FC<CustomStatsDialogProps> = ({ studentIds, ...props }) => {
    const queryClient = useQueryClient();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { control, handleSubmit } = useForm();

    const { data } = useQuery('custom-stats-fields', getCustomStatsFields, {
        initialData: [],
    });

    const { mutate, isLoading } = useMutation(createCustomStats, {
        onSuccess: () => {
            queryClient.invalidateQueries('student-stats');

            closeDialog();
        },
    });

    const closeDialog = () => {
        props.onClose();
    };

    const onSubmit = handleSubmit(data => {
        mutate({
            ...data,
            custom_stats: data.custom_stats.filter(val => !!val.value),
            studentIds,
        });
    });

    return (
        <Dialog open={props.open} onClose={closeDialog} maxWidth='sm' fullWidth fullScreen={fullScreen}>
            <DialogTitle>
                Добавить статистику
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ overflow: 'hidden', display: 'flex' }}>
                <form className='flex-1 flex flex-col overflow-hidden' onSubmit={onSubmit}>
                    <Paper className="mb-3 p-3" variant="outlined" square>
                        <Typography className="opacity-70" variant="caption" color="disabled">
                            Добавляем базы от 80к раздач, не реже раза в два месяца. Если за 2 месяца отыграно меньше 80к рук, то берем последние 80к раздач, даже если часть из них была в прошлом разборе.
                        </Typography>
                    </Paper>
                    <div className='space-y-3 flex flex-col overflow-y-auto'>
                        <Controller
                            control={control}
                            name='note'
                            defaultValue=''
                            render={({ field }) => <TextField {...field} label='Комментарий' size='small' fullWidth />}
                        />
                        {data.map((group, groupI) => (
                            <React.Fragment key={group[0].category.id}>
                                <p className='py-2 bg-gray-100 text-center font-semibold'>{group[0].category.name}</p>
                                {group.map((val, i) => {
                                    const index =
                                        range(0, groupI).reduce((acc, item) => {
                                            return data[item].length + acc;
                                        }, 0) + i;

                                    return (
                                        <React.Fragment key={val.id}>
                                            <Controller
                                                control={control}
                                                name={`custom_stats[${index}].custom_stat_id`}
                                                defaultValue={val.id}
                                                render={() => <div hidden />}
                                            />
                                            <Controller
                                                control={control}
                                                name={`custom_stats[${index}].value`}
                                                defaultValue=''
                                                render={({ field }) => <TextField {...field} label={val.name} size='small' type='number' fullWidth />}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className='w-full pt-4'>
                        <LoadingButton type='submit' variant='contained' loading={isLoading} fullWidth>
                            Создать
                        </LoadingButton>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CustomStatsDialog;
