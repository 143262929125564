import { Dispatch, FC, SetStateAction } from 'react';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { useMutation, useQueryClient } from 'react-query';
import { toggleVideoWatched } from 'api/videos';

type Props = {
    /**
     * Массив видео.
     */
    video: any;

    /**
     * Функция выбора просмотра видео.
     */
    selectVideo: Dispatch<SetStateAction<{ id: any }>>;
};

const VideoCard: FC<Props> = ({ video, selectVideo }) => {
    const queryClient = useQueryClient();

    const videoWatchedMutation = useMutation(toggleVideoWatched, {
        onSuccess: () => {
            queryClient.invalidateQueries('videos');
        },
    });

    // const rateVideoMutation = useMutation(rateVideo, {
    //     onSuccess: () => {
    //         queryClient.invalidateQueries('videos');
    //     },
    // });
    //
    // const onRateVideo = (val: number) => {
    //     rateVideoMutation.mutate({ id: video.id, rating: val / 20 });
    // };

    const toggleWatched = () => {
        videoWatchedMutation.mutate(video.id);
    };

    return (
        <div onClick={() => selectVideo(video)} className={clsx('w-full bg-white p-5 shadow flex flex-col cursor-pointer', video.watched ? 'opacity-60' : '')}>
            <div className="flex items-center justify-between">
                <p className="font-medium">{video.title}</p>
                <div className="ml-5" onClick={e => e.stopPropagation()}>
                    <Tooltip title={video.watched ? 'Пометить не просмотренным' : 'Пометить просмотренным'}>
                        <Checkbox onChange={toggleWatched} className="p-0" checked={video.watched} disableRipple />
                    </Tooltip>
                </div>
            </div>
            <p className="font-light mb-2">{video.description}</p>
            <div className="w-full flex items-center justify-between mt-auto">
                <p className="text-gray-500 mb-2">Автор(ы): {video.authors.join(', ')}</p>
                {/*<div className='flex items-center space-x-1' onClick={e => e.stopPropagation()}>
                    <Rating size={20} onClick={onRateVideo} initialValue={video.user_rating} ratingValue={0} />
                    {video.avg_rating ? <p className='leading-none'>{video.avg_rating.toFixed(1)}</p> : null}
                </div>*/}
            </div>
            <div className="flex items-center justify-between w-full">
                <div className="flex items-center text-xs flex-1 flex-wrap">
                    {video.tags.map((val, i) => (
                        <p key={i} className="text-white bg-gray-500 px-2 mr-1 mt-1 leading-none py-1 rounded-full font-semibold">
                            {val}
                        </p>
                    ))}
                </div>
                <p className="text-gray-500">{video.recorded_date}</p>
            </div>
        </div>
    );
};

export default VideoCard;
