import React from 'react';
import Chip from '@mui/material/Chip';
import { useQuery } from 'react-query';
import moment from 'moment';

import { getHistory, getPlayerAccounts } from 'api/history';

import Table from 'components/Table/Table';

const History: React.FC = () => {
    const [rooms, setRooms] = React.useState([]);
    const [period, setPeriod] = React.useState('bundle');
    const [cashflow, setCashflow] = React.useState(false);
    const [transfers, setTransfers] = React.useState(false);

    const playerAccountsQuery = useQuery('playerAccounts', getPlayerAccounts, {
        onSuccess: data => setRooms(prev => (prev.length ? prev : data.map(val => val.id))),
    });

    const historyQuery = useQuery(['history', rooms, cashflow, transfers, period], getHistory);

    const toggleRoom = ({ id }: { id: number }) => {
        setRooms(oldRooms => {
            const newRooms = oldRooms.slice();

            if (oldRooms.includes(id)) newRooms.splice(newRooms.indexOf(id), 1);
            else newRooms.push(id);

            return newRooms;
        });
    };

    return (
        <div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 mb-8">
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Рум</p>
                    <div className="flex flex-wrap">
                        {(playerAccountsQuery.data || []).map((val, index) => (
                            <div className="mr-2 mb-2" key={`${val.name} ${index}`}>
                                <Chip
                                    label={val.name}
                                    className={rooms.includes(val.id) ? 'bg-blue-400' : 'bg-gray-300'}
                                    color={rooms.includes(val.id) ? 'primary' : 'default'}
                                    onClick={() => toggleRoom(val)}
                                    clickable
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Период</p>
                    <div className="flex space-x-2">
                        <Chip
                            label="Все время"
                            className={period === 'alltime' ? 'bg-blue-400' : 'bg-gray-300'}
                            color={period === 'alltime' ? 'primary' : 'default'}
                            onClick={() => setPeriod('alltime')}
                            clickable
                        />
                        <Chip
                            label="Текущий пакет"
                            className={period === 'bundle' ? 'bg-blue-400' : 'bg-gray-300'}
                            color={period === 'bundle' ? 'primary' : 'default'}
                            onClick={() => setPeriod('bundle')}
                            clickable
                        />
                    </div>
                </div>
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Типы средств</p>
                    <div className="flex space-x-2">
                        <Chip
                            label="Личные средства"
                            className={cashflow ? 'bg-blue-400' : 'bg-gray-300'}
                            color={cashflow ? 'primary' : 'default'}
                            onClick={() => setCashflow(prevVal => !prevVal)}
                            clickable
                        />
                        <Chip
                            label="Трансферы"
                            className={transfers ? 'bg-blue-400' : 'bg-gray-300'}
                            color={transfers ? 'primary' : 'default'}
                            onClick={() => setTransfers(prevVal => !prevVal)}
                            clickable
                        />
                    </div>
                </div>
            </div>
            <div className="shadow-md alternate-table rounded overflow-hidden bg-white">
                <Table
                    struct={[
                        { name: 'Рум', field: 'room_name' },
                        { name: 'Аккаунт', field: 'account_record_name' },
                        { name: 'Валюта', field: 'currency_name' },
                        { name: 'Сумма', field: 'amount' },
                        { name: 'Тип', field: 'type' },
                        {
                            name: 'Дата',
                            field: 'created_datetime',
                            render: val => moment(val).format('DD.MM.YYYY HH:mm:ss'),
                        },
                    ]}
                    loading={historyQuery.isLoading}
                    rows={(historyQuery.data || []) as any[]}
                />
            </div>
        </div>
    );
};

export default History;
