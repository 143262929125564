import React from 'react';
import moment from 'moment';

import LockOutlined from '@mui/icons-material/LockOutlined';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { useQuery } from 'react-query';

import Table from 'components/Table/Table';

import { getTransfers } from 'api/transfers';
import { Typography } from '@mui/material';

interface TransfersTableProps {
    dispatch: React.Dispatch<any>;
}

const TransfersTable: React.FC<TransfersTableProps> = ({ dispatch }) => {
    const transfersQuery = useQuery('transfers', getTransfers, { staleTime: 60000 });

    return (
        <Table
            size="small"
            loading={transfersQuery.isLoading}
            struct={[
                {
                    field: 'room_short_name',
                    name: 'Рум трансфера',
                    render: (val, row) => {
                        return (
                            <span>
                                {val} <Typography variant="caption"> {row.currency_name}</Typography>
                            </span>
                        );
                    },
                },
                {
                    field: 'type',
                    name: 'Действие',
                    render: val => <span className={val === 'Отправить' ? 'text-yellow-600' : 'text-green-600'}>{val}</span>,
                },
                { field: 'amount', name: 'Сумма' },
                {
                    field: 'send_account_nickname',
                    name: 'Никнейм',
                    render: (val, row) => {
                        let cellValue = '';

                        // if (row.type === 'Получить') cellValue = val === null ? '' : `${val}${val.email ? ` (${val.email})` : ''}`;
                        if (row.type === 'Получить') {
                            cellValue = val;
                        } else {
                            if (row.admin_comment && row.admin_comment.length > 3) {
                                cellValue = '';
                            } else if (row.room_name === 'GGNetwork' && row.receive_ewallet && row.receive_ewallet.type.id === 6 /* GG */) {
                                cellValue = `${row.receive_ewallet.address}`;
                            } else if (row.room_name === 'PokerOK' && row.receive_ewallet && row.receive_ewallet.type.id === 2 /* PokerOK */) {
                                cellValue = `${row.receive_ewallet.address}`;
                            } else if (row.room_name === 'RedStar' && row.receive_ewallet && row.receive_ewallet.type.id === 5 /* Login(RS) redstar */) {
                                cellValue = `${row.receive_ewallet.address}`;
                            } else if (
                                row.room_name === 'WPN' &&
                                ((row.receive_ewallet && [8, 10].includes(row.receive_ewallet.type.id)) ||
                                    (row.receive_ewallet && row.receiver_is_admin && [9, 11, 12].includes(row.receive_ewallet.type.id))) /* WPN */
                            ) {
                                cellValue = `${row.receive_ewallet.address}`;
                            } else if (row.room_name === 'Chico' && row.receive_ewallet && [9, 11, 12].includes(row.receive_ewallet.type.id) /* Chico */) {
                                cellValue = `${row.receive_ewallet.address}`;
                            } else {
                                cellValue = `${row.receive_account_nickname}`;
                            }
                        }

                        // else if (row.receive_account) {
                        //     // display email for GGNetwork, nickname for all other rooms
                        //     if (row.room.name === "GGNetwork" && row.receive_account.ewallet && row.receive_account.ewallet.type.name === 'GG') {
                        //         cellValue = `${row.receive_account.ewallet.address}`;
                        //     } else if (row.room.name === "PokerOK" && row.receive_account.ewallet && row.receive_account.ewallet.type.name === "PokerOK") {
                        //         cellValue = `${row.receive_account.ewallet.address}`;
                        //     } else if (row.room.name === 'RedStar' && row.receive_account.ewallet && row.receive_account.ewallet.type.id === 5){
                        //         cellValue = `${row.receive_account.ewallet.address}`;
                        //     } else {
                        //         cellValue = `${row.receive_account.name}${row.receive_account.email ? ` (${row.receive_account.email})` : ''}`;
                        //     }
                        // };

                        return (
                            <span className="cursor-pointer" onClick={() => navigator.clipboard.writeText(cellValue)}>
                                {cellValue}
                            </span>
                        );
                    },
                },
                {
                    field: 'created_datetime',
                    name: 'Дата',
                    render: val => moment(val).format('DD.MM.YYYY'),
                },
                { field: 'admin_comment', name: 'Комментарий' },
                {
                    field: 'actions',
                    name: '',
                    render: (_, row) => (
                        <div className="flex items-center space-x-2">
                            <Tooltip title="Зарезервировать баланс">
                                <div>
                                    <IconButton
                                        color="secondary"
                                        size="small"
                                        disabled={row.is_balance_hold}
                                        className={row.type === 'Получить' ? 'invisible' : ''}
                                        onClick={() => dispatch({ reserveTransferOpen: true, transfer: row })}
                                    >
                                        <LockOutlined fontSize="small" />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title={`Подтвердить ${row.type === 'Получить' ? `получение` : `отправку`} средств`}>
                                <IconButton color="primary" size="small" onClick={() => dispatch({ confirmTransferOpen: true, transfer: row })}>
                                    <CheckOutlined fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ),
                },
            ]}
            rows={transfersQuery.data || []}
        />
    );
};

export default TransfersTable;
