import React from 'react';
import ReactDOM from 'react-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@date-io/moment';
import moment from 'moment';

import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'moment/locale/ru';

import { setupInterceptors } from 'axios_template';
import { setupBlobInterceptors } from 'helpers/blobAxios';
import reportWebVitals from './reportWebVitals';
import store from 'store/configureStore';
import mainTheme from 'themes/mainTheme';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import './index.css';

moment.updateLocale('en', {
    week: { dow: 1 }
});

moment.locale('ru');

setupInterceptors(store);

const client = new QueryClient();

setupBlobInterceptors(store, client);

ReactDOM.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale="ru">
            <QueryClientProvider client={client}>
                <ThemeProvider theme={mainTheme}>
                    <BrowserRouter>
                        <Provider store={store}>
                            <App />
                        </Provider>
                    </BrowserRouter>
                </ThemeProvider>
            </QueryClientProvider>
        </LocalizationProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
