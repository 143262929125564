import React from 'react';
import moment from 'moment';

import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import Table from 'components/Table/Table';

import { deleteDepositRequest, getDepositRequests } from 'api/deposits';

interface DepositsTableProps {
}

const DepositsTable: React.FC<DepositsTableProps> = () => {
    const queryClient = useQueryClient();

    const depositRequestsQuery = useQuery('depositrequests', getDepositRequests, { staleTime: 60000 });

    const deleteDepositRequestMutation = useMutation(deleteDepositRequest, {
        onSuccess: () => queryClient.invalidateQueries('depositrequests')
    });

    const deleteTransfer = ({ id }) => deleteDepositRequestMutation.mutate(id);

    return (
        <Table
            size="small"
            loading={depositRequestsQuery.isLoading}
            struct={[
                { field: 'room_short_name', name: 'Рум запроса' },
                { field: 'account_record_name', name: 'Аккаунт' },
                { field: 'amount', name: 'Сумма' },
                { field: 'comment', name: 'Комментарий' },
                {
                    field: 'created_datetime',
                    name: 'Создан',
                    render: val => moment(val).format('DD.MM.YYYY HH:mm:ss')
                },
                {
                    field: 'processed_datetime',
                    name: 'Поступил в работу',
                    render: val => !val ? '' : moment(val).format('DD.MM.YYYY HH:mm:ss')
                },
                {
                    field: 'actions',
                    name: '',
                    render: (_, row) => (
                        <div className="flex items-center">
                            {
                                deleteDepositRequestMutation.isLoading && deleteDepositRequestMutation.variables === row.id ? (
                                    <CircularProgress size={15} className="mr-2" />
                                ) : null
                            }
                            <Tooltip title="Удалить запрос на депозит">
                                <IconButton
                                    onClick={() => deleteTransfer(row)}
                                    color="secondary"
                                    size="small"
                                    disabled={deleteDepositRequestMutation.isLoading}
                                >
                                    <DeleteOutlined fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                },
            ]}
            rows={depositRequestsQuery.data || []}
            showPagination
        />
    );
};

export default DepositsTable;
