import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppState } from 'store/configureStore';
import { Badge } from '@mui/material';

interface DashboardLinkProps {
    handleDrawerClose: () => void;
    route: {
        icon: any;
        name: string;
        route: string;
        permission: string;
    };
    badgeContent: number;

    /**
     * Флаг нового выполненного задания.
     */
    attention?: boolean;
}

const DashboardLink: React.FC<DashboardLinkProps> = ({ route: val, handleDrawerClose, badgeContent, attention}) => {
    const permissions = useSelector((state: AppState) => state.auth.permissions);

    if (val.permission && !permissions.includes(val.permission)) return null;

    return (
        <NavLink onClick={handleDrawerClose} to={val.route} end>
            <div className='router-link'>
                <Badge badgeContent={badgeContent} color='info' max={99}>
                    <val.icon />
                </Badge>
                <p>{val.name}</p>
                {attention && <Badge badgeContent="!" color='warning' className='ml-4'/>}
            </div>
        </NavLink>
    );
};

export default DashboardLink;
