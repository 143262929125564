import { FC, useEffect } from 'react';

import { createNote, getNoteTypes, updateNote } from 'api/curator';
import { IconButton, DialogTitle, DialogContent, TextField, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

interface NoteModalProps {
    item: any;
    playerIds: number[];

    close: () => void;
}

const NoteModal: FC<NoteModalProps> = ({ playerIds, item, ...props }) => {
    const queryClient = useQueryClient();

    const { data } = useQuery('note-types', getNoteTypes);

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            text: '',
            note_type_id: '',
        },
    });

    const newNoteMutation = useMutation(createNote, {
        onSuccess: () => {
            queryClient.invalidateQueries('notes');

            props.close();
            reset();
        },
    });

    const updateNoteMutation = useMutation(updateNote, {
        onSuccess: () => {
            queryClient.invalidateQueries('notes');

            props.close();
            reset();
        },
    });

    useEffect(() => {
        if (item) {
            setValue('note_type_id', item.note_type?.id || '');
            setValue('text', item.text);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const onSubmit = handleSubmit(data => {
        if (!item) newNoteMutation.mutate({ playerIds, ...data });
        else updateNoteMutation.mutate({ noteId: item.id, ...data });
    });

    return (
        <>
            <DialogTitle>
                Записка
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='space-y-4 pt-2' onSubmit={onSubmit}>
                    <Controller
                        control={control}
                        name='note_type_id'
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel id='demo-simple-select-label'>Тип</InputLabel>
                                <Select {...field} labelId='demo-simple-select-label' id='demo-simple-select' label='Тип'>
                                    {data?.map(val => (
                                        <MenuItem key={val.id} value={val.id}>
                                            {val.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                    <Controller control={control} name='text' render={({ field }) => <TextField {...field} label='Текст' rows={3} multiline fullWidth />} />
                    <div className='pt-4'>
                        <Button fullWidth variant='contained' size='large' type='submit' loading={newNoteMutation.isLoading || updateNoteMutation.isLoading}>
                            Сохранить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default NoteModal;
