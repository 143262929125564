import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import { AppState } from 'store/configureStore';

import { useQueryClient, useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import { changeRoomBalance } from 'api/rooms';
import { RoomCard } from 'types/backend/RoomCard';

export interface ChangeRoomBalanceProps {
    room: RoomCard;
    onClose: () => void;
};

const schema = yup.object().shape({
    balance: yup.number().required().min(0, 'Должно быть положительное число'),
    tournaments_count: yup.number().min(0, 'Должно быть положительное число').max(40, 'Максимум 40')
});

const ChangeRoomBalance: React.FC<ChangeRoomBalanceProps> = (props) => {
    const allow_untracked_networks = useSelector((state: AppState) => state.app.settings.allow_untracked_input);

    const queryClient = useQueryClient();

    const { handleSubmit, reset, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const mutation = useMutation(changeRoomBalance, {
        onSuccess: () => {
            queryClient.invalidateQueries('dashboardrooms');
            queryClient.invalidateQueries('contract');
            queryClient.invalidateQueries('lastcontract');
            queryClient.invalidateQueries('contracttournaments');
            queryClient.invalidateQueries('bundle');
            queryClient.invalidateQueries('bundletournaments');

            closeDialog();
        }
    });

    const changeBalance = handleSubmit(data => mutation.mutate({ account_record: props.room.account_record.id, ...data } as any));

    const closeDialog = () => {
        reset({ balance: '' });

        props.onClose();
    };

    return (
        <>
            <DialogTitle>Внесение баланса {props.room?.room.name}</DialogTitle>
            <form onSubmit={changeBalance} noValidate autoComplete="off">
                <DialogContent>
                    <Controller
                        name="balance"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                autoFocus
                                error={!!errors.balance?.message}
                                helperText={errors.balance?.message}
                                label="Баланс"
                                type="number"
                                variant="outlined"
                                className="w-full"
                                required
                                {...field}
                            />
                        )}
                    />
                </DialogContent>
                {allow_untracked_networks.includes(props.room?.room.id) ? <DialogContent>
                    <Controller
                        name="tournaments_count"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                style={{ backgroundColor: '#f0e3c7' }}
                                error={!!errors.balance?.message}
                                helperText={errors.balance?.message}
                                label="Кол-во турниров"
                                type="number"
                                variant="outlined"
                                className="w-full"
                                required
                                {...field}
                            />
                        )}
                    />
                </DialogContent> : null}
                <DialogActions>
                    <Button color="secondary" disabled={mutation.isLoading} onClick={closeDialog}>
                        Отмена
                    </Button>
                    <Button color="primary" loading={mutation.isLoading} type="submit">
                        Подтвердить
                    </Button>
                </DialogActions>
            </form>
        </>
    );
};

export default ChangeRoomBalance;
