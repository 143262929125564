import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';

import { Badge, CircularProgress, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { getStudents, getStudentNotes, getStudentHomework } from 'api/curator';
import { AppState } from 'store/configureStore';

import Notes from './components/Notes/Notes';
import Homework from './components/Homework/Homework';
import CustomStats from './components/CustomStats/CustomStats';
import EvStats from './components/EvStats/EvStats';

const categories = [
    { name: 'Записки', value: 'notes', curatorOnly: true },
    { name: 'Домашние Задания', value: 'homework' },
    { name: 'Кастомная Статистика', value: 'stats', hideMultiple: true },
    { name: 'Анализ EV', value: 'evanalysis', hideMultiple: true },
    { name: 'Статистика Sharkscope', value: 'sharkscope', disabled: true },
];

interface Props {
    isStudent?: boolean;
}

const Students: FC<Props> = ({ isStudent }) => {
    const profile = useSelector((state: AppState) => isStudent && state.auth.profile);

    const [players, setPlayers] = useState<Map<number, { id: number; username: string }>>(new Map());
    const [category, setCategory] = useState('');

    useEffect(() => {
        setPlayers(new Map());
        setCategory('');
    }, [isStudent]);

    const { data } = useQuery('students', getStudents, {
        enabled: !isStudent,
    });

    const selectButton = !players.size ? 'Выбрать всех' : 'Снять выделение';

    const togglePlayer = (e: any, player: { id: number; username: string }) => {
        e.stopPropagation();

        const items = new Map(players);

        if (items.has(player.id)) items.delete(player.id);
        else items.set(player.id, player);

        setPlayers(items);
    };

    const selectAllPlayers = () => {
        const items = new Map();

        if (!players.size) data.forEach(val => items.set(val.id, val));

        setPlayers(items);
    };

    const { data: notes, isFetching: notesLoading } = useQuery(['student-notes', Array.from(players.values())], getStudentNotes, { enabled: players.size > 0 });

    const { data: homework, isFetching: homeworkLoading } = useQuery(['student-homework', Array.from(players.values()), profile?.id], getStudentHomework, {
        enabled: players.size > 0 || !!profile,
    });

    if (category === 'notes') return <Notes players={Array.from(players.values())} back={() => setCategory('')} />;
    if (category === 'homework') return <Homework players={Array.from(players.values())} authId={profile?.id} back={() => setCategory('')} />;
    if (category === 'stats') return <CustomStats players={Array.from(players.values())} authId={profile?.id} back={() => setCategory('')} />;
    if (category === 'evanalysis') return <EvStats players={Array.from(players.values())} authId={profile?.id} back={() => setCategory('')} />;

    return (
        <>
            {isStudent ? null : (
                <>
                    <div className='w-full mb-4'>
                        <Button variant='contained' size='small' onClick={selectAllPlayers}>
                            {selectButton}
                        </Button>
                    </div>
                    <div className='w-full grid 2xl:grid-cols-4 lg:grid-cols-2 gap-4 mb-4 border-b-2 border-dashed pb-4'>
                        {data?.map(student => (
                            <div
                                key={student.id}
                                className='flex flex-row justify-between items-center w-full cursor-pointer bg-white shadow-md p-2 rounded'
                                onClick={e => togglePlayer(e, student)}
                            >
                                <div className='flex gap-1'>
                                    <CheckCircleIcon className={clsx(players.has(student.id) ? 'text-blue-500' : 'text-gray-300')} />
                                    <div className='text-base'>{student.username}</div>
                                </div>
                                <div className='flex flex-row gap-6'>
                                    <div>
                                        {student.unread_count !== 0 && <Badge badgeContent={student?.unread_count} color='info' />}
                                    </div>
                                    <div>
                                        {student.attention && <Badge badgeContent="!" color='warning' className='mr-2' />}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {(players.size > 0 && !category) || isStudent ? (
                <div className='grid 2xl:grid-cols-4 lg:grid-cols-2 gap-4 tr'>
                    {categories.map(val => {
                        if ((val.hideMultiple && players.size > 1) || (val.curatorOnly && isStudent)) return null;

                        return (
                            <div
                                key={val.value}
                                className={clsx(
                                    'rounded overflow-hidden cursor-pointer p-3 shadow-md bg-white',
                                    (val.disabled || homeworkLoading || notesLoading) && 'pointer-events-none opacity-70'
                                )}
                                onClick={() => setCategory(val.value)}
                            >
                                <p className='leading-none text-lg text-black'>{val.name}</p>

                                <div className='flex mt-4 leading-none text-sm items-center space-x-2'>
                                    {val.value === 'notes' ? (
                                        !notesLoading ? (
                                            <p className='px-3 py-2 bg-gray-200 rounded-full'>Всего – {notes.count}</p>
                                        ) : (
                                            <CircularProgress size={20} />
                                        )
                                    ) : val.value === 'homework' ? (
                                        !homeworkLoading ? (
                                            <div className='flex flex-wrap gap-2'>
                                                <p className='px-3 py-2 bg-gray-200 rounded-full'>Всего – {homework.count}</p>
                                                <p className='px-3 py-2 bg-green-500 text-white rounded-full'>Выполнено – {homework.completed}</p>
                                                {homework.overdue !== 0 && <p className='px-3 py-2 bg-red-100 rounded-full'>Просрочено - {homework.overdue}</p>}
                                            </div>
                                        ) : (
                                            <CircularProgress size={20} />
                                        )
                                    ) : val.value === 'stats' && false ? (
                                        <p className='px-3 py-2 bg-gray-200 rounded-full'>Последнее обновление – 12 Дек 2022</p>
                                    ) : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </>
    );
};

export default Students;
