import { FC, useEffect, useState, Fragment} from 'react';
import clsx from 'clsx';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgress from '@mui/material/CircularProgress';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import SendOutlined from '@mui/icons-material/SendOutlined';
import IconButton from '@mui/material/IconButton';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Card, Divider, InputBase, Paper, useMediaQuery } from '@mui/material';

import theme from 'themes/mainTheme';
import { getStudentHomework, createMessage, updateMessage, updateHomework, updateReadCount } from 'api/curator';
import { updateHomeworkPlayer } from 'api/user';

import HomeworkDialog from '../HomeworkDialog/HomeworkDialog';
import { CloseOutlined } from '@mui/icons-material';
import moment from 'moment';

interface MessagesProps {
    id: number;
    player: { username: string; id: number };
    authId?: number;

    back: () => void;
}

const Messages: FC<MessagesProps> = ({ player, authId, ...props }) => {
    const queryClient = useQueryClient();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const _ = require("lodash"); 

    const [text, setText] = useState('');
    const [edit, setEdit] = useState(null);
    const [open, setOpen] = useState(false);

    const { data: item, isLoading } = useQuery(['student-homework', [player], authId], getStudentHomework, {
        select: ({ data }) => data.find(val => val.id === props.id),
    });

    const updateReadCountMutation = useMutation(updateReadCount, {
        onSuccess: () => {
            queryClient.invalidateQueries('student-homework');
            queryClient.invalidateQueries('read-homework-curator');
            queryClient.invalidateQueries('read-homework-player');
            queryClient.invalidateQueries('students');
        },
    });

    const updateHomeworkMutation = useMutation(updateHomework, {
        onSuccess: () => {
            queryClient.invalidateQueries('student-homework');
        },
    });

    const updateHomeworkPlayerMutation = useMutation(updateHomeworkPlayer, {
        onSuccess: () => {
            queryClient.invalidateQueries('student-homework');
        },
    });

    const newMessageMutation = useMutation(createMessage, {
        onSettled: () => {
            queryClient.invalidateQueries('student-homework');

            setText('');
        },
    });

    const updateMessageMutation = useMutation(updateMessage, {
        onSettled: () => {
            queryClient.invalidateQueries('student-homework');

            setText('');
            setEdit(null);
        },
    });

    useEffect(() => {
        if (!isLoading) {
            if (item.unread_count !== 0) {
                updateReadCountMutation.mutate({ id: item.id });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, item?.unread_count]);

    const newMessage = () => {
        if (!edit) newMessageMutation.mutate({ id: item.id, text });
        else updateMessageMutation.mutate({ id: item.id, msgId: edit.id, text });
    };

    const editHomework = (body: any) => {
        !authId && updateHomeworkMutation.mutate({ id: item.id, ...body });
        authId && updateHomeworkPlayerMutation.mutate({ id: item.id, ...body });
    };

    const startEdit = msg => {
        setText(msg.text);
        setEdit(msg);
    };

    const stopEditng = () => {
        setText('');
        setEdit(null);
    };

    const getMessageClass = (msg, date?: boolean) => {
        if (date) {
            return (!authId && msg.author.id === item.curator.id) || msg.author.id === authId ? 'self-end' : 'self-start';
        }
        if (edit?.id === msg.id) {
            return (!authId && msg.author.id === item.curator.id) || msg.author.id === authId ? 'bg-gray-100 self-end' : 'bg-gray-100 self-start';
        }
        return (!authId && msg.author.id === item.curator.id) || msg.author.id === authId ? 'bg-blue-300 self-end' : 'bg-gray-300 self-start';
    };

    if (isLoading)
        return (
            <div className='w-full flex items-center justify-center'>
                <CircularProgress />
            </div>
        );

    const sortedArray = _.sortBy(item.messages, ['created_datetime']);

    return (
        <div className='w-full flex-1 flex flex-col'>
            <div className='flex items-center cursor-pointer mb-4' onClick={props.back}>
                <ChevronLeftIcon />
                <p className='text-xl font-bold'>{player.username}: Домашнее Задание</p>
            </div>
            <Card variant='outlined' className='p-4'>
                <div className='flex flex-row items-center mb-1'>
                    {authId ? null : (
                        <IconButton onClick={() => setOpen(true)} size='small'>
                            <BorderColorIcon fontSize='small' />
                        </IconButton>
                    )}
                    <p className='text-base leading-none'>Тема: {item.topic}</p>
                </div>
                <p className='text-base mb-2 leading-none'>Сообщений: {item.messages.length}</p>
                <p className='text-base mb-2 leading-none'>Автор задания: {item.curator.username}</p>
                <div className='w-full flex md:items-center md:flex-row flex-col-reverse justify-between'>
                    <div className='flex items-center space-x-4 w-full md:mt-0 mt-2'>
                        {!item.is_closed && (
                            <FormControlLabel
                                control={<Checkbox checked={item.is_completed} onChange={e => editHomework({ is_completed: e.target.checked })} />}
                                label='Выполнено'
                            />
                        )}

                        {item.is_closed || authId ? null : (
                            <Button variant='contained' color='error' loading={updateHomeworkMutation.isLoading || updateHomeworkPlayerMutation.isLoading} onClick={() => editHomework({ is_closed: true })} size='small'>
                                Закрыть
                            </Button>
                        )}
                    </div>
                </div>
            </Card>
            <Card variant='outlined' className='mt-2 overflow-auto h-halfScreen'>
                <div className='flex-1 flex flex-col'>
                    <div className='flex-1 flex flex-col overflow-y-auto justify-end p-5 space-y-2'>
                        {sortedArray.map((msg, i) => (
                            <Fragment key={msg.id}>
                                <div className={clsx('md:text-xs mt-2 p-0 border-b-2 border-indigo-500', getMessageClass(msg, true))}>{msg.author.username}</div>
                                <div
                                    className={clsx('md:text-base p-3 rounded-xl flex flex-col space-x-4', getMessageClass(msg))}
                                    style={{ maxWidth: '90%' }}
                                >
                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='flex-1 whitespace-pre-line'>{msg.text}</div>
                                        {i === item.messages.length - 1 && ((msg.author.id === item.curator.id && !authId) || msg.author.id === authId) ? (
                                            <IconButton className='bg-white' onClick={() => startEdit(msg)} size='small'>
                                                <BorderColorIcon fontSize='small' />
                                            </IconButton>
                                        ) : null}
                                    </div>
                                    
                                </div>
                                <div className={clsx('md:text-xs mt-0 p-0', getMessageClass(msg, true))}>{moment(msg.created_datetime).format('DD.MM.YYYY HH:mm')}</div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            </Card>
            <Paper component='form' sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
                <InputBase sx={{ ml: 1, flex: 1 }} placeholder='Введите сообщение' value={text} onChange={({ target }) => setText(target.value)} multiline />
                {edit && (
                    <IconButton onClick={() => stopEditng()}>
                        <CloseOutlined fontSize='small' />
                    </IconButton>
                )}
                <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
                <IconButton color='primary' sx={{ p: '10px' }} aria-label='directions' onClick={() => newMessage()}>
                    <SendOutlined fontSize='small' />
                </IconButton>
            </Paper>

            <Dialog open={open} onClose={() => setOpen(false)} fullScreen={fullScreen} fullWidth maxWidth='sm'>
                <HomeworkDialog item={item} playerIds={[player.id]} close={() => setOpen(false)} />
            </Dialog>
        </div>
    );
};

export default Messages;
