import axios from 'axios_template';
import moment from 'moment';

export const getSchedulerRooms = async body => {
    const { data } = await axios.get('/api/tournamentsdata/scheduler/networks/', body);

    return data;
};

export const getTournmanets = async ({ queryKey }) => {
    const [, filters, startTime, startDay, endTime, endDay] = queryKey;

    const { data } = await axios.get('/api/tournamentsdata/player_tournaments_grid/', {
        params: {
            ...filters,
            tz: filters.tz.value,
            rooms: filters.rooms.join(','),
            dayEnd: endDay.value,
            dayStart: startDay.value,
            timeEnd: endTime.format('HH:mm:ss'),
            timeStart: startTime.format('HH:mm:ss'),
        },
    });

    return data.tournaments.map(val => {
        //const duration = moment.duration(val.duration, 'seconds');
        const duration = moment.duration(val.duration, 'minutes');

        return {
            ...val,
            dayNum: data.days[val.day_of_week],
            timestamp: +moment(`${val.day_of_week} ${val.start_time}`, 'dddd HH:mm:ss', 'en'),
            type: val.type.join(''),
            duration: `${duration.get('hours')}ч. ${duration.get('minutes')}м.`,
        };
    });
};

export const getTournaments = async ({ filters, startTime, startDay, endTime, endDay }) => {
    const weekDays = [
        { value: 'Monday', label: 'Понедельник' },
        { value: 'Tuesday', label: 'Вторник' },
        { value: 'Wednesday', label: 'Среда' },
        { value: 'Thursday', label: 'Четверг' },
        { value: 'Friday', label: 'Пятница' },
        { value: 'Saturday', label: 'Суббота' },
        { value: 'Sunday', label: 'Воскресенье' },
    ];

    var params = new URLSearchParams();

    if (filters.rooms) {
        filters.rooms.map((item) => params.append('networks', item))
    }

    const startDayNum = weekDays.findIndex(day => day.value === startDay.value)
    const endDayNum = weekDays.findIndex(day => day.value === endDay.value)

    params.append('day_start', String(startDayNum))
    params.append('day_end', String(endDayNum))
    params.append('time_start', startTime.format('HH:mm:ss'))
    params.append('time_end', endTime.format('HH:mm:ss'))
    params.append('timezone', filters.tz.value)

    if (filters.biStart) {
        params.append('buyin_gte', filters.biStart)
    }
    if (filters.biEnd) {
        params.append('buyin_lte', filters.biEnd)
    }
    if (filters.minEntries) {
        params.append('entries_gte', filters.minEntries)
    }
    if (filters.maxEntries) {
        params.append('entries_lte', filters.maxEntries)
    }
    if (filters.typeFilterRegular) {
        params.append('show_regular', filters.typeFilterRegular)
    }
    if (filters.typeFilterTurbo) {
        params.append('show_turbo', filters.typeFilterTurbo)
    }
    if (filters.typeFilterFreezeout) {
        params.append('show_freezeout', filters.typeFilterFreezeout)
    }
    if (filters.typeFilterBounty) {
        params.append('show_bounty', filters.typeFilterBounty)
    }
    if (filters.typeFilterRebuy) {
        params.append('show_rebuy', filters.typeFilterRebuy)
    }
    if (filters.filterTop) {
        params.append('show_top', filters.filterTop)
    }
    if (filters.filterGood) {
        params.append('show_good', filters.filterGood)
    }
    if (filters.filterAverage) {
        params.append('show_avg', filters.filterAverage)
    }
    if (filters.filterBad) {
        params.append('show_bad', filters.filterBad)
    }
    if (filters.filterForbidden) {
        params.append('show_forbidden', filters.filterForbidden)
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/tournamentsdata/scheduler/', request);

    /*
    * Creates an array of days ordered from first to last
    */
    function getDaysArray(start, end) {
        let days = [];
        let day = start;

        while (true) {
            days.push(day);
            if (day === end) break;
            day = (day + 1) % 7;
        }

        return days;
    }
    const daysArray = getDaysArray(startDayNum, endDayNum)

    let tournaments = {
        show_wmark: data?.show_wmark,
        items: data.entries.map(val => {
            const duration = moment.duration(val.duration, 'seconds');

            return {
                ...val,
                dayNum: daysArray.indexOf(val.day_number),
                timestamp: +moment(`${val.day_number} ${val.start_time}`, 'dddd HH:mm:ss', 'en'),
                type: val.tags,
                start_time: moment(`${val.start_time}`, 'HH:mm:ss').format('HH:mm'),
                duration: `${duration.get('hours')}ч. ${duration.get('minutes')}м.`,
            };
        })
    }

    return tournaments;
};

export const getTournamentsDataExpected = async () => {
    const { data } = await axios.get('/api/tournamentsdata/hhs/expected/');

    return data;
};

export const uploadHands = async (body: any) => {
    return axios.post(`api/tournamentsdata/hhs/`, body);
};

export const maxBuyin = async () => {
    const { data } = await axios.get('/api/tournamentsdata/maxbuyin/ggnetwork/');

    return data;
}
