/**
 * Максимальное количество элементов списка на одной странице.
 */
 enum PagingSize {
    /**
     * 10 элементов.
     */
    N10 = 10,
  
    /**
     * 20 элементов.
     */
    N20 = 20,
  
    /**
     * 50 элементов.
     */
    N50 = 50,
  
    /**
     * 100 элементов.
     */
    N100 = 100,
  
    /**
     * Все элементы.
     */
    ALL = Infinity,
  }
  
  export default PagingSize;
  