import axios from 'axios_template';
import moment from 'moment';
import { groupBy } from 'lodash';

const mapHomework = (data: any[], count?: number) => {
    return {
        data: data,
        completed: data ? data.filter(val => val.is_completed).length : 0,
        overdue: data ? data.filter(val => val.is_overdue && (!val.is_completed || !val.is_closed)).length : 0,
        count: count !== 0 ? count : undefined,
    };
};

const mapNotes = (data: any[], count?: number) => {
    return {
        data: data,
        count: count !== 0 ? count : undefined,
    };
};

const buildCustomStats = (items: any, username?: string) => {
    const data = items.map(val => ({
        ...val,
        revision_date: moment(val.revision_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        revision_id: val.revision,
    }));

    const columns = data.map(val => ({ id: val.revision_id, revision_date: val.revision_date }));

    return {
        columns,
        username,
        data: Object.values(groupBy(
            Object.values(groupBy(
                data.map(revision => {
                    return revision.custom_stats.map(stat => ({
                        ...stat,
                        revision_date: revision.revision_date
                    }));
                }).flat(),
                (item) => [item.category.order, item.category_order]
            )).map(group => {
                return {
                    category: group[0].category,
                    columns: [group[0].name, ...group]
                };
            }),
            'category.order'
        )).map(val => ({
            category: val[0].category,
            rows: val.map(row => row.columns)
        }))
    } as any;
};

const buildEvStats = (items: any, username?: string) => {
    const data = items.map(val => ({
        ...val,
        revision_date: moment(val.revision_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        revision_id: val.revision,
    }));

    const columns = data.map(val => ({ id: val.revision_id, revision_date: val.revision_date }));

    return {
        columns,
        username,
        data: Object.values(
            groupBy(
                Object.values(
                    groupBy(
                        data
                            .map(revision => {
                                return revision.ev_stats.map(stat => ({
                                    ...stat,
                                    revision_date: revision.revision_date,
                                }));
                            })
                            .flat(),
                        item => [item.order]
                    )
                ).map(group => {
                    return {
                        columns: [group[0].name, ...group],
                    };
                }),
                'order'
            )
        ).map(val => ({
            rows: val.map(row => row.columns),
        })),
    } as any;
};

// const buildEvStats = (items: any, username?: string) => {
//     const data = items.map(val => ({
//         ...val,
//         revision_date: moment(val.revision_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
//         revision_id: val.revision,
//     }));
//
//     const columns = data.map(val => ({ id: val.revision_id, revision_date: val.revision_date }));
//
//     return {
//         columns,
//         username,
//         data: Object.values(groupBy(
//             Object.values(groupBy(
//                 data.map(revision => {
//                     return revision.custom_stats.map(stat => ({
//                         ...stat,
//                         revision_date: revision.revision_date
//                     }));
//                 }).flat(),
//                 (item) => [item.order]
//             )).map(group => {
//                 return {
//                     columns: [group[0].name, ...group]
//                 };
//             }),
//             'category.order'
//         )).map(val => ({
//             rows: val.map(row => row.columns)
//         }))
//     } as any;
// };

export const getStudents = async () => {
    const { data } = await axios.get('/api/curators/students/');

    return data;
};

export const getStudentNotes = async ({ queryKey }) => {
    const [, players, perPage, page] = queryKey;

    const limit = perPage !== Infinity ? perPage : null;
    const offset = page ? Math.ceil(Number(page - 1) * Number(limit)) : null;

    var params = new URLSearchParams();

    if (limit) {
        params.append("limit", limit);
    }
    if (offset) {
        params.append("offset", String(offset));
    }
    if (players.length !== 0) {
        players.map((value) => params.append("pid", String(value.id)))
    }
    const request = {
        params: params
    };

    return axios.get(`/api/curators/notes/`, request).then(({ data }) => {
        return mapNotes(data.data, data.count);
    });
};

export const getStudentHomework = async ({ queryKey }) => {
    const [, players, authId, perPage, page, filter] = queryKey;

    const limit = perPage !== Infinity ? perPage : null;
    const offset = page ? Math.ceil(Number(page - 1) * Number(limit)) : null;

    var params = new URLSearchParams();

    if (limit) {
        params.append("limit", limit);
    }
    if (offset) {
        params.append("offset", String(offset));
    }
    if (players.length !== 0 && !authId) {
        players.map((value) => params.append("pid", String(value.id)))
    }

    if (filter && filter.length > 0) {
        filter.map((val) => params.append(val.tag, val.value));
    }

    const request = {
        params: params
    };

    if (authId) {
        return axios.get(`/api/curators/players/homework/`, request).then(({ data }) => {
            return mapHomework(data.data, data.count);
        });
    }

    return axios.get(`/api/curators/homework/`, request).then(({ data }) => {
        return mapHomework(data.data, data.count);
    });
};

export const getNoteTypes = async () => {
    const { data } = await axios.get('/api/curators/notes/types/');

    return data;
};

export const createNote = async ({ playerIds, ...body }) => {
    return Promise.all(playerIds.map(id => {
        return axios.post(`/api/curators/students/${id}/notes/`, body);
    }));
};

export const updateNote = async ({ noteId, ...body }) => {
    const { data } = await axios.put(`/api/curators/notes/${noteId}/`, body);

    return data;
};

export const deleteNote = async (id: number) => {
    const { data } = await axios.delete(`/api/curators/notes/${id}/`);

    return data;
};

export const createHomework = async ({ playerIds, onProgress, ...body }) => {
    return Promise.all(playerIds.map(id => {
        return axios.post(`/api/curators/students/${id}/homework/`, body).then(res => {
            if (onProgress) onProgress(res.data);

            return res;
        });
    }));
};

export const updateHomework = async ({ id, ...body }) => {
    const { data } = await axios.patch(`/api/curators/homework/${id}/`, body);

    return data;
};

export const createMessage = async ({ id, ...body }) => {
    const { data } = await axios.post(`/api/curators/homework/${id}/messages/`, body);

    return data;
};

export const updateMessage = async ({ id, msgId, ...body }) => {
    const { data } = await axios.put(`/api/curators/homework/${id}/messages/${msgId}/`, body);

    return data;
};

export const updateReadCount = async ({ id }) => {
    const { data } = await axios.post(`/api/curators/homework/${id}/read/`);

    return data;
};

export const getReadCuratorCount = async () => {
    const { data } = await axios.get(`/api/curators/notifications/?role=curator`);

    return data;
};

export const getCustomStats = async ({ queryKey }) => {
    const [, players, revisions = 1, id] = queryKey;

    if (id) {
        return axios.get('/api/curators/players/customstats/', {
            params: { revisions }
        }).then(({ data }) => ([buildCustomStats(data)]));
    }

    return Promise.all(
        players.map(async ({ id, username }) => {
            const { data: items } = await axios.get<any>(`/api/curators/students/${id}/customstats/`, {
                params: { revisions }
            });

            return buildCustomStats(items, username);
        })
    );
};

export const getEvStats = async ({ queryKey }) => {
    const [, players, revisions = 1, id] = queryKey;

    if (id) {
        return axios.get('/api/curators/players/evanalysis/', {
            params: { revisions }
        }).then(({ data }) => ([buildEvStats(data)]));
    }

    return Promise.all(
        players.map(async ({ id, username }) => {
            const { data: items } = await axios.get<any>(`/api/curators/students/${id}/evanalysis/`, {
                params: { revisions }
            });

            return buildEvStats(items, username);
        })
    );
};

export const getCustomStatsFields = async () => {
    const { data } = await axios.get('/api/curators/customstats/');

    return Object.values(groupBy(data, 'category.order'));
};

export const createCustomStats = async ({ studentIds, ...body }) => {
    return Promise.all(studentIds.map(id => {
        return axios.post(`/api/curators/students/${id}/customstats/`, body);
    }));
};

export const deleteRevisionCustomStats = async (id: number) => {
    const { data } = await axios.delete(`api/curators/customstats/revisions/${id}/`);

    return data;
};

export const deleteRevisionEvStats = async (id: number) => {
    const { data } = await axios.delete(`api/curators/evanalysis/revisions/${id}/`);

    return data;
};
