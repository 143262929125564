import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

export interface InfoCardProps {
    color: string;
    loading?: boolean;
    width?: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ color, loading, width, children }) => {
    return (
        <div className={`${width ? width : "w-full"} rounded shadow-md p-5 text-white leading-none ${color}`}>
            {
               loading ? (
                    <div className="flex items-center justify-center text-white">
                        <CircularProgress color="inherit" />
                    </div>
                ) : children
            }
        </div>  
    );
}

export default InfoCard;