import { FC, useState, useEffect } from 'react';
import moment from 'moment';

import { createHomework, updateHomework } from 'api/curator';
import { IconButton, DialogTitle, DialogContent, TextField, FormControlLabel, Checkbox } from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';

import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/lab';

interface HomeworkDialogProps {
    item: any;
    playerIds: number[];
    players?: { id: number; username: string }[];

    close: () => void;
}

const HomeworkDialog: FC<HomeworkDialogProps> = ({ playerIds, item, ...props }) => {
    const queryClient = useQueryClient();

    const [loadingState, setLoadingState] = useState({});

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            topic: '',
            message: '',
            deadline: moment().format('YYYY-MM-DDT00:00') || null,
            is_completed: false,
            is_closed: false,
        },
    });

    const newHomeworkMutation = useMutation(createHomework, {
        onSuccess: () => {
            queryClient.invalidateQueries('student-homework');

            props.close();
            reset();
        },
    });

    const updateHomeworkMutation = useMutation(updateHomework, {
        onSuccess: () => {
            queryClient.invalidateQueries('student-homework');

            props.close();
            reset();
        },
    });

    useEffect(() => {
        if (item) {
            setValue('topic', item.topic);
            setValue('is_completed', item.is_completed);
            setValue('is_closed', item.is_closed);
            item.deadline ? setValue('deadline', moment(item.deadline).format('YYYY-MM-DDTHH:mm')) : setValue('deadline', null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const onProgress = data => {
        setLoadingState(prevLoadingState => ({
            ...prevLoadingState,
            [data.player.id]: { username: data.player.username, loading: false },
        }));
    };

    const onSubmit = handleSubmit(data => {
        if (!item) {
            if (props.players?.length > 1) {
                setLoadingState(
                    props.players.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.id]: { username: item.username, loading: true },
                        }),
                        {}
                    )
                );
            }

            newHomeworkMutation.mutate({ playerIds, onProgress, deadline: new Date(data.deadline).toISOString(), ...data });
        } else {
            updateHomeworkMutation.mutate({ id: item.id, ...data });
        }
    });

    return (
        <>
            <DialogTitle>
                Домашнее Задание
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                    <Controller control={control} name='topic' render={({ field }) => <TextField {...field} label='Тема' fullWidth />} />
                    <Controller
                        name='deadline'
                        control={control}
                        defaultValue={null}
                        render={({ field: { ref: _, ...rest } }) => (
                            <DateTimePicker
                                inputFormat='DD/MM/yyyy HH:mm'
                                mask={'__/__/____ __:__'}
                                label='Выполнить до:'
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    {item ? (
                        <div className='w-full'>
                            <div className='w-full flex items-center space-x-1 -mt-4'>
                                <Controller
                                    control={control}
                                    name='is_completed'
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Checkbox {...field} checked={field.value} onChange={e => field.onChange(e.target.checked)} />}
                                            label='Выполнено'
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='is_closed'
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Checkbox {...field} checked={field.value} onChange={e => field.onChange(e.target.checked)} />}
                                            label='Закрыто'
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    ) : (
                        <Controller
                            control={control}
                            name='message'
                            render={({ field }) => <TextField {...field} label='Текст' rows={3} multiline fullWidth />}
                        />
                    )}
                    {playerIds.length > 1 ? (
                        <div className='w-full'>
                            {Object.values(loadingState).map((val: any) => (
                                <div key={val.username} className='flex space-x-2 text-sm'>
                                    <p>{val.username}</p>
                                    <p>....</p>
                                    <p>{val.loading ? 'Загрузка' : 'Cоздано'}</p>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div className='pt-4'>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={newHomeworkMutation.isLoading || updateHomeworkMutation.isLoading}
                        >
                            Сохранить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default HomeworkDialog;
