import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toggleVideoWatched } from 'api/videos';
import { Card, Checkbox, FormControlLabel } from '@mui/material';
import Header from 'components/Header/Header';

type Props = {
    /**
     * Идентификатор видео.
     */
    id: string;

    /**
     * Тема видео.
     */
    title: string;

    /**
     * Флаг просмотра видео.
     */
    watched: boolean;

    /**
     * Автор видео.
     */
    authors: [string];

    /**
     * Рейтинг видео текущего пользователя.
     */
    user_rating: number;

    /**
     * Общий рейтинг видео.
     */
    avg_rating: number;

    /**
     * Дата видео.
     */
    recorded_date: string;

    /**
     * Функция для возврата назад.
     */
    resetVideo: () => void;
};

const PlaybackHeader: FC<Props> = ({ id, title, watched, authors, user_rating, avg_rating, recorded_date, resetVideo }) => {
    const queryClient = useQueryClient();

    const videoWatchedMutation = useMutation(toggleVideoWatched, {
        onSuccess: () => {
            queryClient.invalidateQueries('videos');
            queryClient.invalidateQueries('videos-watch');
        },
    });

    // const rateVideoMutation = useMutation(rateVideo, {
    //     onSuccess: () => {
    //         queryClient.invalidateQueries('videos');
    //         queryClient.invalidateQueries('videos-watch');
    //     },
    // });

    // const onRateVideo = (val: number) => {
    //     rateVideoMutation.mutate({ id: id, rating: val / 20 });
    // };

    const toggleWatched = async () => {
        videoWatchedMutation.mutate(id);
    };

    return (
        <>
            <Header title={title} back={resetVideo} />
            <Card variant="outlined" className="flex flex-col justify-between p-4 sm:flex-row">
                <FormControlLabel control={<Checkbox checked={watched} onChange={e => toggleWatched()} />} label="Просмотрено" />
                <div>
                    <p className="text-gray-500 mb-2">Автор(ы): {authors.join(', ')}</p>
                    {/*<div className='flex items-center space-x-1 mb-2' onClick={e => e.stopPropagation()}>
                        <Rating size={20} onClick={onRateVideo} initialValue={user_rating} ratingValue={0} />
                        {avg_rating ? <p className='leading-none'>{avg_rating.toFixed(1)}</p> : null}
                    </div>*/}
                    <p className="text-gray-500 text-right">{recorded_date}</p>
                </div>
            </Card>
        </>
    );
};

export default PlaybackHeader;
