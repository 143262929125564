import React from 'react';
import { get } from 'lodash';

import UITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import LinearProgress from '@mui/material/LinearProgress';

export interface TableProps {
	rows: any[];
	struct: {
		field: string,
		name: string,
		renderHeader?: (val: any) => any;
		render?: (value: any, row: any, struct: any) => any;
	}[],
	loading?: boolean;
	showHeader?: boolean;
    showPagination?: boolean;
    size?: 'medium'|'small';
	onRowClick?: (row: any) => void;
};

const PAGE_SIZE = 10;

const Table: React.FC<TableProps> = ({
    rows = [],
	struct,
	showHeader = true,
    showPagination = false,
	onRowClick,
	loading,
    size = 'medium'
}) => {
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(0);

    React.useEffect(() => {
        if (!showPagination) {setData(rows);}
        else {setData(rows.slice(0, PAGE_SIZE));}

        setPage(0);
    }, [rows, showPagination]);

    React.useEffect(() => {
        if (showPagination) setData(rows.slice(page, PAGE_SIZE));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <div className="w-full relative overflow-x-auto">
            <UITable className="w-full" size={size}>
                {showHeader ? (
                    <TableHead>
                        <TableRow>
                            {struct.map((val) => (
                                <TableCell
                                    className="text-gray-700 font-bold text-sm py-4"
                                    key={val.field}
                                >
                                    {val.renderHeader ? val.renderHeader(val) : val.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {
                        data.map((row, i) => (
                            <TableRow
                                key={i}
                                className={onRowClick ? `cursor-pointer ${row.class || ''}` : `${row.class || ''}`}
                                onClick={() => onRowClick && onRowClick(row)}
                            >
                                {struct.map((val) => {
                                    return (
                                        <TableCell
                                            key={val.field + "cell"}
                                            style={{ flexBasis: 0 }}
                                            className="text-gray-700 text-sm"
                                        >
                                            {val.render ? val.render(get(row, val.field, ''), row, val) : get(row, val.field, '')}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))
                    }
                    {
                        loading && !rows?.length ? (
                            <TableRow>
                                <TableCell className="p-0" colSpan={struct.length || 1}>
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        ) : null
                    }
                    {
						!loading && !rows?.length ? (
							<TableRow>
								<TableCell className="text-gray-700 text-sm text-center" colSpan={struct.length || 1}>
									Нет данных
								</TableCell>
							</TableRow>
						) : null
					}
                </TableBody>
            </UITable>
            {
                showPagination ? (
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[PAGE_SIZE]}
                        count={rows.length}
                        rowsPerPage={PAGE_SIZE}
                        page={page}
                        onPageChange={(_, p) => setPage(p)}
                        className="text-gray-700"
                    />
                ) : null
            }
			{
				loading && rows?.length ? (
					<div className="absolute inset-0 flex items-center justify-center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
						<CircularProgress size={20} />
					</div>
				) : null
			}
        </div>
    );
}

export default Table;