import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/lab/LoadingButton';

import { useQueryClient, useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import { requestDeposit } from 'api/rooms';
import { RoomCard } from 'types/backend/RoomCard';

export interface DepositRequestProps {
    room: RoomCard;
    onClose: () => void;
};

const schema = yup.object().shape({
    amount: yup.number().required().min(0, 'Должно быть положительное число'),
    comment: yup.string()
});

const DepositRequest: React.FC<DepositRequestProps> = (props) => {
    const queryClient = useQueryClient();

    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const mutation = useMutation(requestDeposit, {
        onSuccess: () => {
            queryClient.invalidateQueries('dashboardrooms');
            queryClient.invalidateQueries('contract');
            queryClient.invalidateQueries('lastcontract');
            queryClient.invalidateQueries('contracttournaments');
            queryClient.invalidateQueries('bundle');
            queryClient.invalidateQueries('bundletournaments');
            queryClient.invalidateQueries('depositrequests');

            closeDialog();
        }
    });

    const changeBalance = handleSubmit(data => mutation.mutate({ account_record: props.room.account_record.id, ...data } as any));

    const closeDialog = () => {
        reset({ amount: '', comment: '' });

        props.onClose();
    };

    return (
        <>
            <DialogTitle>Запрос депозита {props.room?.room.name}</DialogTitle>
            <form onSubmit={changeBalance} noValidate autoComplete="off">
                <DialogContent>
                    <DialogContentText className="mb-8 text-sm">
                        Никнейм: {props.room?.account_record.name}<br />
                        Кошелек: {props.room?.ewallet?.address || '–'}<br />
                    </DialogContentText>
                    <Controller
                        name="amount"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                autoFocus
                                error={!!errors.amount?.message}
                                helperText={errors.amount?.message}
                                label="Сумма"
                                type="number"
                                variant="outlined"
                                className="w-full mb-4"
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="comment"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                error={!!errors.comment?.message}
                                helperText={errors.comment?.message}
                                label="Комментарий"
                                type="text"
                                variant="outlined"
                                className="w-full mb-8"
                                {...field}
                            />
                        )}
                    />
                    <DialogContentText className="text-yellow-600 text-sm">
                        Время обработки запросов до 24 часов
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" disabled={mutation.isLoading} onClick={closeDialog}>
                        Отмена
                    </Button>
                    <Button color="primary" loading={mutation.isLoading} type="submit">
                        Подтвердить
                    </Button>
                </DialogActions>
            </form>
        </>
    );
};

export default DepositRequest;
