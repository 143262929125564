export const SET_FILTER = 'TOURNAMENTS/SET_FILTER';

interface SetFilter { type: typeof SET_FILTER; payload: any; }

interface authState {
    biStart: number;
    biEnd: number | null;
    minEntries: number;
    maxEntries: number | null;
    rooms: string[];
    filterAverage: boolean;
    filterBad: boolean;
    filterTop: boolean;
    filterGood: boolean;
    filterForbidden: boolean;
    typeFilterBounty: boolean;
    typeFilterFreezeout: boolean;
    typeFilterRebuy: boolean;
    typeFilterRegular: boolean;
    typeFilterTurbo: boolean;
    tz: { value: string; label: string; };
}

export const setFilter = (payload: any) => ({ type: SET_FILTER, payload });

const initialState: authState = {
    biStart: 0,
    biEnd: null,
    minEntries: 0,
    maxEntries: null,
    rooms: [],
    filterAverage: false,
    filterBad: false,
    filterGood: true,
    filterTop: true,
    filterForbidden: false,
    typeFilterBounty: true,
    typeFilterFreezeout: true,
    typeFilterRebuy: true,
    typeFilterRegular: true,
    typeFilterTurbo: true,
    tz: { value: 'Europe/Moscow', label: 'Europe/Moscow' }
};

const tournaments = (state: authState = initialState, action: SetFilter): authState => {
    switch (action.type) {
        case SET_FILTER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default tournaments;
