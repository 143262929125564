import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/configureStore';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useScreenshot } from 'use-react-screenshot';

import { useQueryClient, useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { round } from 'lodash';

import * as yup from 'yup';

import { confirmTransfer, sendTransferImage } from 'api/transfers';

export interface ConfirmTransferProps {
    transfer: any;
    onClose: () => void;
}

// balance: yup.number().when(['room_account_name'], {
//     is: (room_account_name: string) => !!room_account_name,
//     then: yup.number().required().min(0),
//     otherwise: yup.number()
// }),

const confirmSchema = yup.object().shape({
    screenshot_required: yup.boolean(),
    player_comment: yup.string().url().required('Скриншот обязательное поле'),
    player_comment2: yup.string().url(),
    amount_before_transfer: yup.number().typeError('Дробь через точку').min(0, 'Должно быть положительное число'),
    fee: yup.number().min(0, 'Должно быть положительное число'),
    change_balance: yup.boolean(),
    fee_percentage: yup
        .number()
        .min(0, 'Комиссия не может быть меньнше 0%')
        .max(5.99, 'Комиссия не может быть выше 5.99%')
        .typeError('Дробь через точку')
        .test('is-decimal', 'Формат 0.00', value => /^[0-5]\.?\d{0,2}$/.test(`${value}`)),
});

const ConfirmTransfer: React.FC<ConfirmTransferProps> = props => {
    const single_screenshot_rooms = useSelector((state: AppState) => state.app.settings.single_screenshot_rooms);

    const queryClient = useQueryClient();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [commissionText, setCommissionText] = React.useState('');
    const [transferAmount, setTransferAmount] = React.useState(0.0);
    //const [currency, setCurrency] = React.useState('');
    const [currSign, setCurrSign] = React.useState<string>('');
    // const [screenshot, setScreenshot] = React.useState<string>('');
    const [senderRequiresSecondScreenshot, setSenderRequiresSecondScreenshot] = React.useState<boolean>(false);
    const [receiverRequiresSecondScreenshot, setReceiverRequiresSecondScreenshot] = React.useState<boolean>(false);
    const [isP2pTransfer, setIsP2pTransfer] = React.useState<boolean>(false);

    const ref = React.useRef<HTMLDivElement>(null);
    const [image, takeScreenshot] = useScreenshot();
    const getImage = () => takeScreenshot(ref.current);

    const {
        handleSubmit,
        reset,
        setValue,
        trigger,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(confirmSchema),
        defaultValues: {
            screenshot_required: true,
            player_comment: '',
            player_comment2: '',
            amount_before_transfer: 0, // transfer amount minus room cashout comission if there is any
            fee: 0,
            fee_percentage: 1.45,
            change_balance: true,
        },
    });

    const dataURLtoBlob = dataurl => {
        const arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };

    const sendImageMutation = useMutation(sendTransferImage);

    const mutation = useMutation(confirmTransfer, {
        onSuccess: () => {
            if (props.transfer?.type === 'Отправить') {
                getImage().then(res => {
                    sendImageMutation.mutate({ transferId: props.transfer.id, image: dataURLtoBlob(res) });
                    // console.log(res)
                    // const link = document.createElement('a');
                    // link.href = res;
                    // link.download = 'screenshot.png';
                    // document.body.appendChild(link);
                    // link.click();
                    // document.body.removeChild(link);
                });
            }
            queryClient.invalidateQueries('transfers');
            queryClient.invalidateQueries('dashboardrooms');
            queryClient.invalidateQueries('contract');
            queryClient.invalidateQueries('lastcontract');
            queryClient.invalidateQueries('contracttournaments');
            queryClient.invalidateQueries('bundle');
            queryClient.invalidateQueries('bundletournaments');

            closeDialog();
        },
    });

    React.useEffect(() => {
        if (props.transfer) {
            setValue('change_balance', !(props.transfer.type === 'Отправить' && props.transfer.is_balance_hold));
            setValue('amount_before_transfer', props.transfer.amount);
            //setCurrency(props.transfer.room.currency.name);
            setTransferAmount(props.transfer.amount);
            const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: props.transfer.currency_name }).formatToParts(0);
            setCurrSign(formatter[0].value);

            // show second screenshot field if required (only when transferring to admins)
            // TODO: move this logic to backend
            if (props.transfer.receiver_is_admin && props.transfer.room_id.toString() in single_screenshot_rooms) {
                if ( // some specific fields are set
                    single_screenshot_rooms[props.transfer.room_id.toString()].recipients.includes(props.transfer.receiver_id)
                    || single_screenshot_rooms[props.transfer.room_id.toString()].recipient_room_accounts.includes(props.transfer.receive_room_account_id)
                    || single_screenshot_rooms[props.transfer.room_id.toString()].ewallet_types.includes(props.transfer.receive_ewallet?.type.id)
                    || single_screenshot_rooms[props.transfer.room_id.toString()].ewallets.includes(props.transfer.receive_ewallet?.id)
                ) {
                    setSenderRequiresSecondScreenshot(false);
                } else if ( // all fields are empty, only room is set
                    single_screenshot_rooms[props.transfer.room_id.toString()].recipients.length === 0
                    && single_screenshot_rooms[props.transfer.room_id.toString()].recipient_room_accounts.length === 0
                    && single_screenshot_rooms[props.transfer.room_id.toString()].ewallet_types.length === 0
                    && single_screenshot_rooms[props.transfer.room_id.toString()].ewallets.length === 0
                ) {
                    setSenderRequiresSecondScreenshot(false);
                } else {
                    setSenderRequiresSecondScreenshot(true);
                }
            } else {
                setSenderRequiresSecondScreenshot(true);
            }

            // for PokerOK transfers to luxon set default fee 1%
            if (props.transfer.receiver_is_admin && props.transfer.room_id.toString() === "23") {
                console.log(props.transfer.send_ewallet?.type.id);
                if (props.transfer.send_ewallet?.type.id === 14) {
                    setValue("fee_percentage", 1);

                    const percentage = 1
                    const amount_before_transfer = getValues('amount_before_transfer');
                    const toTransfer = round((amount_before_transfer / (100 + +percentage)) * 100, 2);
                    const cashoutFee = round(props.transfer.amount - amount_before_transfer, 2);

                    //setValue('fee', round(props.transfer.amount - toTransfer, 2));
                    setValue('fee', round(round(amount_before_transfer - toTransfer, 2) + cashoutFee, 2));
                    setCommissionText(`К переводу: ${props.transfer.currency_name}${toTransfer.toFixed(2)}`);
                    setTransferAmount(toTransfer);
                }
            }

            if (isP2pTransfer) {
                setValue('fee', 0);
                const amount_before_transfer = getValues('amount_before_transfer');
                setCommissionText(`К переводу: ${props.transfer.currency_name}${amount_before_transfer.toFixed(2)}`);
                setTransferAmount(amount_before_transfer);
            }

            // /**
            //  * Transfers from some ewallets require second screenshot
            //  * 1 - Skrill
            //  * 9 - USDT(TRC20)
            //  * 11 - Binance
            //  * 12 - Huobi
            //  * 14 - Luxon
            //  * TODO: this logic should be implemented on backend
            //  **/
            // const screenshotRooms = [1, 9, 11, 12, 14];
            // if (props.transfer.send_ewallet !== null) {
            //     if (screenshotRooms.includes(props.transfer.send_ewallet.type.id) && props.transfer.receiver_is_admin) {
            //         setSenderRequiresSecondScreenshot(true);
            //     }
            // }
            // if (props.transfer.receive_ewallet !== null) {
            //     if (screenshotRooms.includes(props.transfer.receive_ewallet.type.id) && props.transfer.sender_is_admin) {
            //         setReceiverRequiresSecondScreenshot(true);
            //     }
            // }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.transfer, isP2pTransfer]);

    const confirmBalance = handleSubmit(data => mutation.mutate({ id: props.transfer.id, ...data } as any));

    const closeDialog = () => {
        reset();

        props.onClose();
        //setCurrency('');
        setCurrSign('');
        setTransferAmount(0.0);
    };

    const calculateCommission = async () => {
        const valid = await trigger(['fee_percentage', 'amount_before_transfer']);

        if (!valid) return;

        const percentage = getValues('fee_percentage');
        const amount_before_transfer = getValues('amount_before_transfer');
        const toTransfer = round((amount_before_transfer / (100 + +percentage)) * 100, 2);
        const cashoutFee = round(props.transfer.amount - amount_before_transfer, 2);

        //setValue('fee', round(props.transfer.amount - toTransfer, 2));
        setValue('fee', round(round(amount_before_transfer - toTransfer, 2) + cashoutFee, 2));
        setCommissionText(`К переводу: ${props.transfer.currency_name}${toTransfer.toFixed(2)}`);
        setTransferAmount(toTransfer);
    };

    return (
        <div ref={ref}>
            <DialogTitle>{`Подтвердить ${props.transfer?.type === 'Получить' ? `получение` : `отправку`} средств?`}</DialogTitle>
            <form onSubmit={confirmBalance} noValidate autoComplete="off">
                <DialogContent>
                    <Controller
                        name="player_comment"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                error={!!errors.player_comment?.message}
                                helperText={errors.player_comment?.message}
                                label={
                                    (!senderRequiresSecondScreenshot && !receiverRequiresSecondScreenshot) || isP2pTransfer ? 'Скриншот перевода' : 'Скриншот перевода на кошелёк'
                                }
                                type="url"
                                variant="outlined"
                                className="mb-2 w-full"
                                required
                                {...field}
                            />
                        )}
                    />
                    {(senderRequiresSecondScreenshot || receiverRequiresSecondScreenshot) && (
                        <div>
                            <Controller
                                name="player_comment2"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={!!errors.player_comment2?.message}
                                        helperText={errors.player_comment2?.message}
                                        label="Скриншот перевода с кошелька"
                                        type="url"
                                        variant="outlined"
                                        className="mb-2 w-full"
                                        required
                                        disabled={isP2pTransfer}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="change_balance"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        label="P2P перевод внутри рума"
                                        control={<Checkbox {...field} checked={isP2pTransfer} onChange={({ target }) => setIsP2pTransfer(target.checked)} />}
                                    />
                                )}
                            />
                        </div>
                    )}
                    <DialogContentText className="mb-8 text-sm">
                        Скриншот должен содержать данные об остатках и переводе/депозите (
                        <a
                            href="https://gyazo.com/59eabef9d520490da9d6ac12dcac066d"
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-600 hover:text-blue-500"
                        >
                            пример
                        </a>
                        )
                    </DialogContentText>
                    <DialogContentText className="mb-8 text-sm">
                        <Typography style={{ fontSize: 20 }}>
                            К переводу: {currSign} {transferAmount}
                        </Typography>
                        <Typography style={{ fontSize: 20 }}>
                            Комиссия: {currSign} {getValues('fee')}
                        </Typography>
                    </DialogContentText>
                    <div hidden={props.transfer?.type !== 'Отправить'}>
                        {/*<Controller
                            name="fee"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    error={!!errors.fee?.message}
                                    helperText={errors.fee?.message}
                                    label="Комиссия"
                                    type="number"
                                    variant="outlined"
                                    className="mb-2 w-full"
                                    {...field}
                                />
                            )}
                        />*/}
                        <DialogContentText className="mb-4 text-sm">
                            <Typography style={{ fontSize: 14 }}>Для переводов с комиссией:</Typography>
                        </DialogContentText>
                        <div className="flex space-x-2 mb-2 items-start">
                            <Controller
                                name="amount_before_transfer"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={!!errors.amount_before_transfer?.message}
                                        helperText={errors.amount_before_transfer?.message}
                                        label="Получено из рума"
                                        size="small"
                                        type="text"
                                        variant="outlined"
                                        inputProps={{
                                            pattern: '^\\d*(\\.\\d{0,2})?$',
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{currSign}</InputAdornment>,
                                        }}
                                        style={{ width: 170 }}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className="flex space-x-2 mb-2 items-start">
                            <Controller
                                name="fee_percentage"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        error={!!errors.fee_percentage?.message}
                                        helperText={errors.fee_percentage?.message}
                                        label="Процент"
                                        size="small"
                                        type="number"
                                        variant="outlined"
                                        inputProps={{
                                            pattern: '^\\d*(\\.\\d{0,2})?$',
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        style={{ width: 170 }}
                                        {...field}
                                    />
                                )}
                            />
                            <Button onClick={calculateCommission} type="button">
                                Добавить комиссию
                            </Button>
                        </div>
                        <DialogContentText className="mb-4 text-sm">
                            {/*<Typography style={{ fontSize: 14 }}>Комиссия входит в сумму запрошенного перевода.</Typography>
                            <Typography style={{ fontSize: 14 }}>Для получения точной суммы к переводу нажмите на кнопку "Рассчитать".</Typography>
                            <Typography style={{ fontSize: 14 }}>Пример: Запрошен перевод на $100, комиссия за перевод составит $5. В таком случае игрок должен перевести $95, $5 начислится комиссия за перевод — итого общая сумма перевода $100.</Typography>
                            <Typography style={{ color: "red", fontSize: 14 }}>Формула для расчёта суммы к отправке с учётом комиссии: сумма перевода / (100 + комиссия) * 100.</Typography>
                            <Typography style={{ fontSize: 14 }}>Пример для перевода $100 с комиссией 1.45%: 100 / (100+1.45) * 100 = 98.57</Typography>
                            <br />*/}
                        </DialogContentText>
                    </div>
                    <Controller
                        name="change_balance"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Изменить баланс"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                    <Typography style={{ color: 'red', fontSize: 14 }}>
                        Если перевод с комиссией, перед подтверждением необходимо нажать на кнопку "Добавить комиссию".
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" disabled={mutation.isLoading} onClick={closeDialog}>
                        Отмена
                    </Button>
                    <Button color="primary" loading={mutation.isLoading} type="submit">
                        Подтвердить
                    </Button>
                </DialogActions>
            </form>
        </div>
    );
};

export default ConfirmTransfer;
