import { all, call, put, takeLatest } from 'redux-saga/effects';

import { authenticateRequest } from './requests';
import { authenticate, Login, LOGIN, setLoading } from './auth';

export function* authenticateSaga(action: Login) {
    try {
        const { data: authData} = yield call(authenticateRequest, action.payload.username, action.payload.password);
        
        yield put(authenticate(authData));
    } catch (err) {
        yield put(setLoading(false));

        console.error(err);
    }
}

export function* watchAuth() {
    yield all([takeLatest(LOGIN, authenticateSaga)]);
}
