import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/lab/LoadingButton';

import { useQueryClient, useMutation } from 'react-query';

import { reserveTransfer } from 'api/transfers';

export interface ReserveBalanceProps {
    transfer: any;
    onClose: () => void;
};

const ReserveBalance: React.FC<ReserveBalanceProps> = (props) => {
    const queryClient = useQueryClient();

    const [changeBalance, setChangeBalance] = React.useState(true);

    const mutation = useMutation(reserveTransfer, {
        onSuccess: () => {
            queryClient.invalidateQueries('transfers');
            queryClient.invalidateQueries('dashboardrooms');
            queryClient.invalidateQueries('contract');
            queryClient.invalidateQueries('lastcontract');
            queryClient.invalidateQueries('contracttournaments');
            queryClient.invalidateQueries('bundle');
            queryClient.invalidateQueries('bundletournaments');

            closeDialog();
        }
    });

    const reserveBalance = () => {
        mutation.mutate({ id: props.transfer.id, change_balance: changeBalance });
    };

    const closeDialog = () => {
        setChangeBalance(true);

        props.onClose();
    };

    return (
        <>
            <DialogTitle>Зарезервировать баланс?</DialogTitle>
            <DialogContent>
                <FormControlLabel
                    label="Изменить баланс"
                    control={<Checkbox checked={changeBalance} onChange={({ target }) => setChangeBalance(target.checked)} />}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={mutation.isLoading} onClick={closeDialog}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    loading={mutation.isLoading}
                    onClick={reserveBalance}
                >
                    Подтвердить
                </Button>
            </DialogActions>
        </>
    );
};

export default ReserveBalance;