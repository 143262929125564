import React, { FC } from 'react';

import { IconButton, DialogTitle, DialogContent, FormControlLabel, Typography, Switch } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useMutation, useQueryClient } from 'react-query';

import CloseIcon from '@mui/icons-material/Close';
import BackupIcon from '@mui/icons-material/Backup';
import {
    filterReducer,
    initialState,
    SET_UPLOAD_PROGRESS,
    SET_H2N_HANDS,
    SET_IGNORE_H2N_HANDS,
    SET_GG_HANDS,
    SET_IGNORE_GG_HANDS,
    SET_GG_SUMMARIES,
    SET_IGNORE_GG_SUMMARIES,
    SET_GG_SCREENS,
    SET_IGNORE_GG_SCREENS,
    RESET_STATE
} from './state';

import { uploadHands } from 'api/tournaments';

const FileInput = ({ onFilesChange }) => {
    const handleFilesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilesChange(event.target.files);
    };

    return <input type="file" accept=".zip" onChange={handleFilesChange} />;
};

type Props = {
    close: () => void;
};

const HandsModal: FC<Props> = ({ close }) => {
    const [state, dispatch] = React.useReducer(filterReducer, initialState);

    const queryClient = useQueryClient();

    const isSendButtonDisabled = !(
        (state.h2nHands || state.ignoreH2NHands)
        && (state.ggHands || state.ignoreGGHands)
        && (state.ggSummaries || state.ignoreGGSummaries)
        && (state.ggScreens || state.ignoreGGScreens)
    );

    const uploadHandsMutation = useMutation(uploadHands, {
        onSuccess: () => {
            queryClient.invalidateQueries('tournaments-data-expected');
        },
    });

    async function uploadFiles(files: any, type: string) {
        let formData = new FormData();
        formData.append('type', type);

        if (files !== null) {
            // formData.append('file', files[0], `${type}.${extension}`);
            const fileName = files[0].name;
            formData.append('file', files[0], fileName);
        }

        uploadHandsMutation.mutate(formData);
    }

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!state.ignoreH2NHands && state.h2nHands) {
            await uploadFiles(state.h2nHands, 'h2n');
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: 20 });
        } else {
            await uploadFiles(null, 'h2n');
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: 20 });
        }

        if (!state.ignoreGGHands && state.ggHands) {
            await uploadFiles(state.ggHands, 'ggnetwork');
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: 40 });
        } else {
            await uploadFiles(null, 'ggnetwork');
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: 40 });
        }

        if (!state.ignoreGGSummaries && state.ggSummaries) {
            await uploadFiles(state.ggSummaries, 'ggsummary');
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: 60 });
        } else {
            await uploadFiles(null, 'ggsummary');
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: 60 });
        }

        if (!state.ignoreGGScreens && state.ggScreens) {
            await uploadFiles(state.ggScreens, 'ggscreen');
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: 80 });
        } else {
            await uploadFiles(null, 'ggscreen');
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: 80 });
        }


        // Reset after files are uploaded
        dispatch({ type: RESET_STATE });
    }

    return (
        <>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <BackupIcon color="success" fontSize="medium" />

                    <Box ml={1}>Загрузка рук</Box>
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    <b>Ежемесячная загрузка данных — ваша обязанность по договору.</b>
                    <br />
                    Загрузите файлы в формате <span className="text-red-600">ZIP</span> до 5 числа.
                    <br />
                    Нет рук? Нажмите "рук нет".
                </Typography>
                <br />
                <Typography color="error">
                    <b>
                        GGNetwork - обязательно оригинальные файлы с PokerCraft.
                        <br />
                        Chico - необходимо добавить HH в архив H2N.
                    </b>
                </Typography>
                <br />
                <Typography>
                    Если загружаешь файлы первый раз, обязательно посмотри{' '}
                    <Link href="https://dashboard.kingsoftournaments.com/video?id=527" target="_blank" underline="hover">
                        <b>видео</b>
                    </Link>.
                </Typography>
                <br />
                <form className="flex flex-col gap-2 pt-2" onSubmit={onSubmit}>
                    <div className="flex items-center">
                        <Typography variant="caption" fontSize="medium" className="pr-3" style={{ width: '130px' }}>
                            {' '}
                            H2N{' '}
                        </Typography>
                        <FormControlLabel control={
                            <Switch checked={state.ignoreH2NHands} onChange={e => dispatch({ type: SET_IGNORE_H2N_HANDS, payload: e.target.checked })} />
                        } style={{ width: '150px' }} label="Рук нет" />
                        {!state.ignoreH2NHands && <FileInput onFilesChange={(files: any) => dispatch({ type: SET_H2N_HANDS, payload: files })} />}
                    </div>
                    <div className="flex items-center">
                        <Typography variant="caption" fontSize="medium" className="pr-3" style={{ width: '140px' }}>
                            {' '}
                            GG/PP hhs{' '}
                        </Typography>
                        <FormControlLabel control={
                            <Switch checked={state.ignoreGGHands} onChange={e => dispatch({ type: SET_IGNORE_GG_HANDS, payload: e.target.checked })} />
                        } style={{ width: '150px' }} label="Рук нет" />
                        {!state.ignoreGGHands && <FileInput onFilesChange={(files: any) => dispatch({ type: SET_GG_HANDS, payload: files })} />}
                    </div>
                    <div className="flex items-center">
                        <Typography variant="caption" fontSize="medium" className="pr-3" style={{ width: '140px' }}>
                            {' '}
                            GG summary{' '}
                        </Typography>
                        <FormControlLabel control={
                            <Switch checked={state.ignoreGGSummaries} onChange={e => dispatch({ type: SET_IGNORE_GG_SUMMARIES, payload: e.target.checked })} />
                        } style={{ width: '150px' }} label="Рук нет" />
                        {!state.ignoreGGSummaries && <FileInput onFilesChange={(files: any) => dispatch({ type: SET_GG_SUMMARIES, payload: files })} />}
                    </div>
                    <div className="flex items-center">
                        <Typography variant="caption" fontSize="medium" className="pr-3" style={{ width: '140px' }}>
                            {' '}
                            GG скрины{' '}
                        </Typography>
                        <FormControlLabel control={
                            <Switch checked={state.ignoreGGScreens} onChange={e => dispatch({ type: SET_IGNORE_GG_SCREENS, payload: e.target.checked })} />
                        } style={{ width: '150px' }} label="Скринов нет" />
                        {!state.ignoreGGScreens && <FileInput onFilesChange={(files: any) => dispatch({ type: SET_GG_SCREENS, payload: files })} />}
                    </div>
                    <div className="pt-4">
                        <Button
                            disabled={isSendButtonDisabled}
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                            loading={uploadHandsMutation.isLoading}
                        >
                            Отправить
                        </Button>
                    </div>
                </form>
                <LinearProgress className="mb-5" variant="determinate" value={state.uploadProgress} />
                <Typography className="pt-8" variant="caption" color="text.secondary">
                    * история рук используется для анализа успеваемости игроков внутри команды, среди игроков руки не распространяются
                </Typography>
            </DialogContent>
        </>
    );
};

export default HandsModal;
