import axios from 'axios_template';

export const getTeam = async () => {
    const { data } = await axios.get('/api/users/team/');

    return {
        headers: data.headers.map((val, i) => ({ key: i, name: val })),
        table_data: data.table_data.map(val => {
            if (Array.isArray(val)) return { ...val };

            return { ...val.data, class: val.color };
        })
    };
};