import moment from 'moment';
import axios from 'axios_template';

export const toggleVideoWatched = async (id: string) => {
    const { data } = await axios.post(`/api/videogallery/videos/${id}/watched/`);

    return data;
};

export const rateVideo = async ({ id, rating }) => {
    const { data } = await axios.post(`/api/videogallery/videos/${id}/rating/`, { rating });

    return data;
};

export const getAuthors = async () => {
    const { data } = await axios.get(`/api/videogallery/authors/`);
    
    return data;
};

export const getTags = async () => {
    const { data } = await axios.get(`/api/videogallery/tags/`);
    
    return data;
};

export const getLevels = async () => {
    const { data } = await axios.get(`/api/videogallery/levels/`);
    
    return data;
};

export const getVideosNew = async ({ queryKey }) => {
    const [, authors, search, tags, accessLevels, pageSize, page, sort, watched] = queryKey;

    const params = new URLSearchParams();
    
    if (authors.length > 0) {
        authors.map((value)=> params.append("authorId", String(value.id)))
    }

    if (tags.length > 0) {
        tags.map((value)=> params.append("tagId", String(value.id)))
    }

    if (accessLevels.length > 0) {
        accessLevels.map((value)=> params.append("levelId", String(value.id)))
    }

    if (search){
        params.append("title", search);
    }

    
    if (page > 1){
        const offset = (page - 1) * pageSize;
        params.append("offset", String(offset));
    }

    params.append("limit", pageSize);
    params.append("sort", sort.value);
    params.append("show", watched);


    const request = {
        params: params
    };

    const { data } = await axios.get('/api/videogallery/videos/', request);

    const videos = data?.data?.map(val => {
        const mDate = moment(val.recorded_datetime, 'YYYY-MM-DD');

        const authors = val.authors.map(val => val.name);
        const tags = val.tags.map(val => val.name);
        const accessLevels = [val.min_level.name];

        return {
            ...val,
            authors,
            tags,
            accessLevels,
            recorded_date: mDate.format('D MMM YYYY'),
            timestamp: +mDate,
        };
    })
    return { videos, count: data.count};
};

export const getVideosInfo = async ({ queryKey }) => {
    const [, id] = queryKey;
   
    const { data } = await axios.get(`/api/videogallery/videos/${id}/info/`);
    
    return data;
};


