import axios from 'axios_template';

export const getForm = async () => {
    // const { data } = await axios.get('/api/users/newplayerform/');
    const { data } = await axios.get('/api/users/newplayerformv2/');

    return data;
};

export const getCountries = async () => {
    const { data } = await axios.get('/api/geodata/countries/');

    return data;
};


export const getResidencies = async () => {
    const { data } = await axios.get('/api/users/residenceregions/');

    return data;
};

export const getCities = async ({ queryKey }) => {
    const [, country_id, filter] = queryKey;

    const { data } = await axios.get(`/api/geodata/countries/${country_id}/cities/`, {
        params: { filter }
    });

    return data;
};

export const saveForm = (body: any) => {
    // return axios.post('/api/users/newplayerform/', body);
    return axios.post('/api/users/newplayerformv2/', body);
};
