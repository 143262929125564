import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        lightBlue: Palette['primary'];
    }
    interface PaletteOptions {
        lightBlue: PaletteOptions['primary'];
    }
}

const theme = createTheme({
    palette: {
        background: {
            default: '#f4f5fd',
        },
        lightBlue: {
            main: '#6573c3'
        }
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    borderRadius: '2px',
                }
            }
        }
    },
});

export default theme;
