import axios from 'axios_template';
import { RoomCard } from 'types/backend/RoomCard';

export const getRooms = async () => {
    // const { data } = await axios.get<RoomCard[]>('/api/accounting/roomsinfo/');
    const { data } = await axios.get<RoomCard[]>('/api/accounting/accountcards/');

    return data;
};

export const getMaxRoom = async ({ queryKey }) => {
    // const [, roomId] = queryKey;
    const [, acc_record_id] = queryKey;

    // const { data } = await axios.get('/api/accounting/cashflow/maximum/', {
    //     params: { roomId }
    // });
    const { data } = await axios.get('/api/accounting/personalfunds/max/', {
        params: { acc_record_id }
    });

    return data.value;
};

export const changeRoomBalance = (body: { account_record: number, balance: number }) => {
    // return axios.post('/api/accounting/cashierbalance/', body);
    return axios.post('/api/accounting/accountrecordbalances/', body);
};

export const transferToRoom = (body: {
    // cashout_room: number;
    // deposit_room: number;
    cashout_account_record: number;
    deposit_account_record: number;
    amount: number;
    change_balance: boolean;
    comment: string;
}) => {
    // return axios.post('/api/accounting/cashierbalance/transfer/', body);
    return axios.post('/api/accounting/accountrecordbalance/transfer/', body);
};

export const requestDeposit = (body: {
    // room: number,
    account_record: number,
    amount: number,
    comment: string
}) => {
    // return axios.post('/api/accounting/deposit/', body);
    return axios.post('/api/accounting/depositrequests/', body);
};

export const createCashflow = (body: {
    type: 'deposit'|'cashout';
    account_record: number;
    amount: number;
    comment: string;
    change_balance: boolean;
}) => {
    // return axios.post('/api/accounting/cashflows/', {
    //     ...body,
    //     type: 1,
    //     amount: body.type === 'cashout' ? -body.amount : body.amount
    // });

    return axios.post('/api/accounting/playerfundflow/', {
        ...body,
        amount: body.type === 'cashout' ? -body.amount : body.amount
    });
};
