import { FC, SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const WaterMark: FC<Props> = ({ ...props }) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 610 342" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_12_10)">
                <path
                    d="M271.257 16.7102L257.354 33.391L258.383 34.8914C258.939 35.7446 260.246 37.8334 261.359 39.8339C261.909 41.0246 262.551 42.166 263.277 43.2465C263.416 43.2465 268.366 37.3626 274.399 30.302C280.433 23.2413 285.522 17.3574 285.522 17.3869C285.522 17.4163 290.582 23.2707 296.644 30.2726C302.706 37.2744 307.767 43.3642 307.962 43.6878C308.156 44.0114 308.629 44.1291 311.409 39.3337C313.022 36.5683 314.19 34.2147 314.19 34.0971C314.19 33.9794 285.717 0 285.522 0C285.327 0 279.015 7.53138 271.313 16.7102H271.257Z"
                    fill="black"
                />
                <path
                    d="M207.916 91.9949L190.732 112.588L192.483 116.295C193.429 118.354 194.652 120.826 195.264 121.797L196.237 123.532L213.755 102.704C223.376 91.2593 231.273 81.6098 231.245 81.3157C229.562 77.9163 227.619 74.6695 225.434 71.6073C225.434 71.6073 217.481 80.8448 207.999 92.2007L207.916 91.9949Z"
                    fill="black"
                />
                <path
                    d="M343.581 76.4905C342.163 78.8437 340.8 81.0795 340.522 81.4621C340.244 81.8446 340.772 83.0212 357.623 103.086L375.28 124.121C375.28 124.121 375.586 124.121 375.808 123.62C377.62 120.363 379.263 117.004 380.73 113.559C380.73 113.206 346.556 72.3715 346.278 72.3715C346 72.3715 345.027 74.2837 343.497 76.6079L343.581 76.4905Z"
                    fill="black"
                />
                <path
                    d="M149.995 160.865L129.808 184.931L157.391 217.704L184.975 250.507H199.656L198.433 249.065L170.877 216.292L144.489 184.931L145.101 184.224C162.09 164.072 173.797 150.039 173.797 149.657C173.797 149.274 170.543 136.742 170.349 136.771C170.154 136.8 161.089 147.627 149.995 160.865Z"
                    fill="black"
                />
                <path
                    d="M399.526 144.155L397.747 150.657L412.067 167.691C419.908 177.047 426.276 184.813 426.248 184.96C426.22 185.107 413.93 199.67 398.943 217.557C383.955 235.444 371.72 250.066 371.72 250.271C374.076 250.522 376.444 250.61 378.811 250.536H386.013L413.429 217.704L441.235 184.931L440.485 184.019L420.631 160.483C410.148 148.009 401.528 137.801 401.5 137.801C401.473 137.801 400.611 140.743 399.637 144.332L399.526 144.155Z"
                    fill="black"
                />
                <path
                    d="M239.78 51.0724L233.274 61.2513L239.725 71.6363C243.256 77.3435 246.287 82.0509 246.37 82.1098C246.454 82.1687 249.151 77.5497 252.627 71.8716L258.661 61.5454L252.627 51.2487C249.373 45.5414 246.593 40.9225 246.593 40.9225C246.593 40.9225 243.479 45.4825 239.92 51.0724H239.78Z"
                    fill="black"
                />
                <path
                    d="M319.473 51.2482L313.467 61.5453L319.39 71.8126C322.698 77.4318 325.507 82.0503 325.618 82.0798C325.73 82.1092 338.353 61.7806 338.353 61.2217C338.353 60.6624 325.98 40.9516 325.591 40.9221C325.201 40.8927 322.81 45.5704 319.418 51.2482H319.473Z"
                    fill="black"
                />
                <path
                    d="M165.316 73.99C165.316 73.99 164.176 77.9907 162.785 82.8159C161.395 87.6407 160.394 91.2004 160.394 91.2004L173.324 102.615C173.602 102.909 173.963 103.056 174.047 102.968C174.13 102.88 175.103 99.2023 176.216 94.8481C177.328 90.4943 178.329 86.6404 178.413 86.3462C178.496 86.0521 176.994 84.2867 172.295 79.874C166.372 74.3136 165.371 73.4017 165.371 73.99H165.316Z"
                    fill="black"
                />
                <path
                    d="M399.971 79.8739C395.217 84.375 393.604 86.081 393.771 86.4635C393.937 86.846 394.855 90.5531 395.967 94.9658C397.08 99.3785 398.053 102.968 398.136 102.968C398.22 102.968 398.581 102.968 398.859 102.615L411.789 91.2003C411.789 91.2003 410.76 87.2875 409.342 82.5801C407.924 77.8733 406.812 73.9899 406.812 73.7547C406.812 73.519 405.727 74.2251 399.971 79.6382V79.8739Z"
                    fill="black"
                />
                <path
                    d="M324.486 87.8104C323.818 91.1344 321.538 99.5778 320.037 104.344C314.642 121.378 307.301 134.352 299.155 141.148C295.379 144.616 290.583 146.591 285.585 146.737C273.323 146.737 261.616 133.528 253.107 110.14C250.782 103.626 248.814 96.9758 247.213 90.2225C246.927 88.6624 246.499 87.1351 245.933 85.6625C245.643 86.4374 245.447 87.2478 245.349 88.0751C243.507 97.5627 240.921 106.872 237.62 115.906C234.138 125.679 228.403 134.376 220.936 141.206C216.985 144.59 212.111 146.539 207.033 146.767C205.14 147.099 203.197 146.875 201.416 146.12C192.685 142.883 183.731 130.292 176.641 111.198C176.297 110.048 175.85 108.935 175.306 107.874C175.306 107.874 178.087 118.7 181.646 131.763C186.846 151.062 196.272 186.483 197.857 192.867L198.218 194.279H372.868L375.482 184.365C379.347 169.655 387.522 138.853 392.165 121.731C394.39 113.523 396.225 106.727 396.198 106.697C396.17 106.668 395.197 109.08 394.084 112.022C386.716 131.704 377.4 144.384 368.364 146.531C365.309 146.952 362.208 146.772 359.215 146.002C356.019 144.935 353.012 143.315 350.317 141.206C346.602 137.921 343.352 134.087 340.669 129.821C337.106 123.806 334.098 117.442 331.688 110.816C329.592 104.461 327.865 97.9766 326.516 91.3996C325.125 84.6622 325.125 84.6622 324.486 87.8688V87.8104Z"
                    fill="black"
                />
                <path d="M198.016 221.587V236.297H374.112V206.877H198.016V221.587Z" fill="black" />
                <path
                    d="M12.48 276.968V304.04L36 276.968H51.744L24.672 306.536L53.184 341H36.672L13.92 312.296L12.48 313.736V341H0V276.968H12.48Z"
                    fill="black"
                />
                <path
                    d="M63.432 284.936C69.832 278.664 78.056 275.528 88.104 275.528C98.152 275.528 106.344 278.664 112.68 284.936C119.08 291.208 122.28 299.24 122.28 309.032C122.28 318.824 119.08 326.856 112.68 333.128C106.344 339.336 98.152 342.44 88.104 342.44C78.056 342.44 69.832 339.336 63.432 333.128C57.096 326.856 53.928 318.824 53.928 309.032C53.928 299.24 57.096 291.208 63.432 284.936ZM72.744 324.968C76.712 329.064 81.832 331.112 88.104 331.112C94.376 331.112 99.496 329.064 103.464 324.968C107.432 320.808 109.416 315.496 109.416 309.032C109.416 302.568 107.4 297.256 103.368 293.096C99.4 288.936 94.312 286.856 88.104 286.856C81.896 286.856 76.776 288.936 72.744 293.096C68.776 297.256 66.792 302.568 66.792 309.032C66.792 315.496 68.776 320.808 72.744 324.968Z"
                    fill="black"
                />
                <path d="M165.529 287.72H151.129V341H138.649V287.72H124.249V276.968H165.529V287.72Z" fill="black" />
                <path d="M170.941 324.296V313.544H195.901V324.296H170.941Z" fill="black" />
                <path
                    d="M204.767 341L216.671 276.968H225.695L242.687 317.672L260.447 276.968H269.471L280.223 341H267.551L261.791 301.544L244.607 341H240.383L223.967 301.544L217.439 341H204.767Z"
                    fill="black"
                />
                <path d="M326.122 287.72H311.723V341H299.243V287.72H284.842V276.968H326.122V287.72Z" fill="black" />
                <path d="M372.435 287.72H358.035V341H345.555V287.72H331.155V276.968H372.435V287.72Z" fill="black" />
                <path
                    d="M377.893 340.136C376.485 338.664 375.781 336.872 375.781 334.76C375.781 332.648 376.485 330.888 377.893 329.48C379.365 328.008 381.157 327.272 383.269 327.272C385.381 327.272 387.141 328.008 388.549 329.48C390.021 330.888 390.757 332.648 390.757 334.76C390.757 336.872 390.021 338.664 388.549 340.136C387.141 341.544 385.381 342.248 383.269 342.248C381.157 342.248 379.365 341.544 377.893 340.136Z"
                    fill="black"
                />
                <path
                    d="M449.12 324.104V338.696C444.384 341.192 439.104 342.44 433.28 342.44C423.936 342.44 416.128 339.464 409.856 333.512C403.008 326.92 399.584 318.76 399.584 309.032C399.584 298.664 403.008 290.312 409.856 283.976C415.872 278.344 423.712 275.528 433.376 275.528C439.008 275.528 444.256 276.84 449.12 279.464V294.056C444.96 289.256 439.808 286.856 433.664 286.856C427.392 286.856 422.272 289.064 418.304 293.48C414.4 297.832 412.448 303.08 412.448 309.224C412.448 315.944 414.624 321.288 418.976 325.256C423.328 329.16 428.384 331.112 434.144 331.112C440.16 331.112 445.152 328.776 449.12 324.104Z"
                    fill="black"
                />
                <path
                    d="M467.026 284.936C473.426 278.664 481.65 275.528 491.698 275.528C501.746 275.528 509.938 278.664 516.274 284.936C522.674 291.208 525.874 299.24 525.874 309.032C525.874 318.824 522.674 326.856 516.274 333.128C509.938 339.336 501.746 342.44 491.698 342.44C481.65 342.44 473.426 339.336 467.026 333.128C460.69 326.856 457.522 318.824 457.522 309.032C457.522 299.24 460.69 291.208 467.026 284.936ZM476.338 324.968C480.306 329.064 485.426 331.112 491.698 331.112C497.97 331.112 503.09 329.064 507.058 324.968C511.026 320.808 513.01 315.496 513.01 309.032C513.01 302.568 510.994 297.256 506.962 293.096C502.994 288.936 497.906 286.856 491.698 286.856C485.49 286.856 480.37 288.936 476.338 293.096C472.37 297.256 470.386 302.568 470.386 309.032C470.386 315.496 472.37 320.808 476.338 324.968Z"
                    fill="black"
                />
                <path
                    d="M534.111 341L546.015 276.968H555.039L572.031 317.672L589.791 276.968H598.815L609.567 341H596.895L591.135 301.544L573.951 341H569.727L553.311 301.544L546.783 341H534.111Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_12_10">
                    <rect width="610" height="342" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default WaterMark;