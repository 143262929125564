import axios from 'axios_template';

export const getLeaderBoard = async () => {
    const { data } = await axios.get('/api/leaderboards/pointslb/');

    return {
        ...data,
        leaderboard: data.leaderboard.map(val => {
            if (Array.isArray(val)) return val;

            return { ...val.data, class: val.color };
        })
    };
};

export const getLeaderBoardCurators = async () => {
    const { data } = await axios.get('/api/leaderboards/curators/');

    return data;
};

export const getNewLeaderboard = async () => {
    const { data } = await axios.get('/api/leaderboards/leaderboard/');

    return data;
};
