import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import EditOutlined from '@mui/icons-material/EditOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import AttachMoneyOutlined from '@mui/icons-material/AttachMoneyOutlined';
import TrendingFlatOutlined from '@mui/icons-material/TrendingFlatOutlined';

import { RoomCard } from 'types/backend/RoomCard';
import { getRoomLogoUrl } from 'helpers/room-logo';

export interface CardProps {
    room: RoomCard;

    onChangeBalance: () => void;
    onCashflow: () => void;
    onDepositRequest: () => void;
    onTransferBalance: () => void;
}

const Card: React.FC<CardProps> = props => {
    const [imgError, setImgError] = React.useState(false);

    const formatter = React.useRef(
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            // currency: props.room.currency.name
            currency: props.room.currency_name,
        })
    ).current;

    const simpleFormatter = React.useRef(new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })).current;

    const cardColor = props.room.virtual ? 'bg-gray-400' : 'bg-blue-500'; // set color depending on if room is virtual or not

    return (
        <div className={'flex flex-col rounded shadow-md relative text-white ' + cardColor + ' p-5 w-full room-card'}>
            <div className="absolute inset-0 flex items-center justify-center">
                {!imgError ? (
                    <img
                        className="h-24 opacity-10"
                        alt={props.room.room.full_name}
                        src={getRoomLogoUrl(props.room.room.full_name)}
                        onError={() => setImgError(true)}
                    />
                ) : null}
            </div>
            <div className="flex flex-1 w-full mb-4 relative">
                <div className="flex-1">
                    <p className="text-xl">{simpleFormatter.format(props.room.end_balance)}</p>
                    <p className="text-sm font-light">{props.room.account_record_name}</p>
                    <p className="text-sm font-light">{props.room.club_name}</p>
                </div>
                <div className="flex flex-col justify-between items-end">
                    <div className="flex space-x-2">
                        {props.room.pending_cashflow ? (
                            <Tooltip title="Движение средств ожидает подтверждения">
                                <AddOutlined className="text-base" />
                            </Tooltip>
                        ) : null}
                        {props.room.pending_deposits ? (
                            <Tooltip title="Депозит в работе">
                                <AttachMoneyOutlined className="text-base" />
                            </Tooltip>
                        ) : null}
                    </div>
                    <Tooltip
                        title={
                            <React.Fragment>
                                Старт: {formatter.format(props.room.start_balance)}
                                <br />
                                Трансферы: {formatter.format(props.room.transfers_sum)}
                            </React.Fragment>
                        }
                    >
                        <p>Результат: {formatter.format(props.room.result)}</p>
                    </Tooltip>
                </div>
            </div>
            <div className="flex items-center relative">
                <div className="flex items-center space-x-2 flex-1">
                    <p className="xl:text-xl text-2xl leading-none font-bold" style={{ opacity: 0.3 }}>
                        {props.room.room.name} ({props.room.chip_currency.chip_rate === 1 ? '' : props.room.chip_currency.chip_rate}
                        {props.room.currency_name})
                    </p>
                </div>
                <div className="flex items-center space-x-2">
                    {!props.room.virtual && (
                        <Tooltip title="Изменить баланс">
                            <button
                                type="button"
                                onClick={() => props.onChangeBalance()}
                                className="bg-white rounded-full flex items-center justify-center h-6 w-6"
                            >
                                <EditOutlined className="text-base text-blue-600" />
                            </button>
                        </Tooltip>
                    )}
                    <Tooltip title="Добавить движение личных средств">
                        <button type="button" onClick={() => props.onCashflow()} className="bg-white rounded-full flex items-center justify-center h-6 w-6">
                            <AddOutlined className="text-base text-red-600" />
                        </button>
                    </Tooltip>
                    {!props.room.virtual && props.room.can_request_deposit && (
                        <Tooltip title="Запросить депозит">
                            <button
                                type="button"
                                onClick={() => props.onDepositRequest()}
                                className="bg-white rounded-full flex items-center justify-center h-6 w-6"
                            >
                                <AttachMoneyOutlined className="text-base text-green-600" />
                            </button>
                        </Tooltip>
                    )}
                    <Tooltip title="Перевод средства фонда в другой рум">
                        <button
                            type="button"
                            onClick={() => props.onTransferBalance()}
                            className="bg-white rounded-full flex items-center justify-center h-6 w-6"
                        >
                            <TrendingFlatOutlined className="text-base text-yellow-600" />
                        </button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default Card;
