import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import useLocalStorage from 'hooks/useLocalStorage';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';

import { useQuery } from 'react-query';

import { getAuthors, getVideosNew, getTags, getLevels } from 'api/videos';

import Select from 'components/Select/Select';

import VideoCard from './components/VideoCard/VideoCard';
import Playback from './Playback/Playback';
import { Autocomplete, Typography } from '@mui/material';

const watchedFilter = [
    {title: 'Все', tag: 'all'}, 
    {title: 'Просмотренные', tag: 'watched'},
    {title: 'Не просмотренные', tag: 'new'},
];

const Video: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [pageSize, setPageSize] = useLocalStorage("video_page_size", '10');
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState({ value: 'desc', label: 'Сначала новые' });
    const [authors, setAuthors] = useState([]);
    const [tags, setTags] = useState([]);
    const [accessLevels, setAccessLevels] = useState([]);
    const [page, setPage] = useState(1);
    const [watched, setWatched] = useState("all");
    const [selectVideo, setSelectVideo] = useState<string | undefined>(undefined);

    const { data, isLoading } = useQuery(['videos', authors, search, tags, accessLevels, pageSize, page, sort, watched], getVideosNew);

    const { data: authorsList, isLoading: authorsLoading } = useQuery('videos-authors', getAuthors);
    const { data: tagsList, isLoading: tagsLoading } = useQuery(['videos-tags'], getTags);
    const { data: levelList, isLoading: levelLoading } = useQuery(['videos-levels'], getLevels);

    useEffect(() => {
        const paramsId = new URLSearchParams(location.search).get('id');
        if (paramsId) {
            setSelectVideo(paramsId);
        }
    }, [data, location]);

    useEffect(() => {
        setPage(1);
    }, [pageSize, watched, accessLevels, tags, authors, sort, search]);

    const goToVideo = video => {
        navigate({
            pathname: '/video',
            search: `?id=${video.id}`,
        });
    };

    const backToList = () => {
        navigate({
            pathname: '/video',
            search: ``,
        });
        setSelectVideo(undefined);
    };

    if (selectVideo !== undefined) {
        return <Playback id={selectVideo} resetVideo={backToList} />;
    }
    
    return (
        <div className='w-full'>
            <div className='flex lg:flex-row flex-col lg:space-x-4 mb-2 lg:space-y-0 space-y-4 w-full'>
                <div className='w-full flex md:flex-row flex-col items-center md:space-x-4 md:space-y-0 space-y-4'>
                    <TextField label='Поиск' value={search} onChange={({ target }) => setSearch(target.value)} fullWidth />
                    <Autocomplete
                        multiple
                        limitTags={1}
                        options={authorsList ?? []}
                        getOptionLabel={(option: {name: string, id: string}) => option?.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Автор" />
                        )}
                        onChange={(_, value) => setAuthors(value)}
                        value={authors}
                        disabled={authorsLoading}
                        fullWidth
                    />
                    <Autocomplete
                        multiple
                        limitTags={1}
                        options={tagsList ?? []}
                        getOptionLabel={(option: {name: string, id: string}) => option?.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Теги" />
                        )}
                        onChange={(_, value) => setTags(value)}
                        disabled={tagsLoading}
                        value={tags}
                        fullWidth
                    />
                    <Autocomplete
                        multiple
                        limitTags={1}
                        options={levelList ?? []}
                        getOptionLabel={(option: {name: string, id: string}) => option?.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Уровень" />
                        )}
                        onChange={(_, value) => setAccessLevels(value)}
                        disabled={levelLoading}
                        value={accessLevels}
                        fullWidth
                    />
                    <Select
                        label='Сортировка'
                        options={[
                            { value: 'desc', label: 'Сначала новые' },
                            { value: 'asc', label: 'Сначала старые' },
                        ]}
                        value={sort}
                        onChange={setSort}
                        multiple={false}
                    />
                    <Select
                        label='Размер Страницы'
                        options={['10', '25', '50', '100']}
                        value={pageSize}
                        onChange={val => setPageSize(val)}
                        multiple={false}
                    />
                </div>
            </div>
            <div className='w-full mb-8 flex items-center space-x-2'>
                {watchedFilter.map((val, i) => (
                    <p
                        key={i}
                        className={clsx(watched === val.tag ? 'bg-blue-500' : 'bg-gray-400', 'px-3 text-white cursor-pointer font-bold py-1 rounded-full')}
                        onClick={() => setWatched(val.tag)}
                    >
                        {val.title}
                    </p>
                ))}
            </div>
            {isLoading &&
                <div className='flex flex-1 items-center justify-center'>
                    <CircularProgress />
                </div>
            }
            {!data?.videos && !isLoading &&
                <div className='flex flex-1 items-center justify-center'>
                    <Typography variant="h4">
                        Список видеозаписей пуст
                    </Typography>
                </div>
            }
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5'>
                {!isLoading && data?.videos?.map(video => (
                    <VideoCard key={video.id} video={video} selectVideo={goToVideo} />
                ))}
            </div>
            {data?.count && 
                <div className='w-full mt-10 flex justify-center'>
                    <Pagination count={Math.ceil(data.count / +pageSize)} size='small' page={page} onChange={(_, newPage) => setPage(newPage)} />
                </div>
            }
        </div>
    );
};

export default Video;
