import { FC, useEffect, useState } from 'react';

import { Dialog, useMediaQuery, Button } from '@mui/material';

import theme from 'themes/mainTheme';

import NoteModal from '../NoteModal/NoteModal';
import Header from 'components/Header/Header';
import Pagination from 'components/Pagination/Pagination';
import NotesBody from './NotesBody';

interface NotesProps {
    /**
     * массив выбранных игроков.
     */
    players: { id: number; username: string }[];

    /**
     * Функция возврата.
     */
    back: () => void;

    /**
     * Флаг запрета действий, при множественном выборе игроков.
     */
    hideWhenMutliple?: boolean;

    /**
     * ИД авторизованного пользователя.
     */
    authId?: number;
}

const Notes: FC<NotesProps> = ({ players, back, hideWhenMutliple, authId }) => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [item, setItem] = useState(null);
    const [open, setOpen] = useState(false);

    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (item) setOpen(true);
    }, [item]);

    useEffect(() => {
        if (!open) setTimeout(() => setItem(null), 100);
    }, [open]);

    return (
        <div className='w-full'>
            <Header back={back} title='Записки'>
                {players.length ? `${players.map(val => val.username).join(', ')}: ` : ''}
            </Header>
            <div className='w-full flex items-center mb-3 md:flex-row s:flex-col-reverse items-end'>
                {count !== undefined && (
                    <Pagination
                        current={page}
                        range={perPage}
                        onChangePage={newPage => setPage(newPage)}
                        onChangeRange={newRange => setPerPage(newRange)}
                        total={count}
                    />
                )}

                {(hideWhenMutliple && players.length > 1) || authId ? null : (
                    <div className='flex w-full md:justify-end s:justify-start mt-4'>
                        <Button variant='contained' size='large' onClick={() => setOpen(true)}>
                            Добавить
                        </Button>
                    </div>
                )}
            </div>
            <NotesBody players={players} perPage={perPage} page={page} setItem={setItem} setCount={setCount} />

            <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
                <NoteModal item={item} playerIds={players.map(val => val.id)} close={() => setOpen(false)} />
            </Dialog>
        </div>
    );
};

export default Notes;
