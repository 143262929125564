import { FC, useState, Fragment } from 'react';
import { Tooltip, Table, TableBody, TableCell, TableHead, TableRow, TextField, MenuItem, Button, IconButton } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteRevisionCustomStats, getCustomStats } from 'api/curator';
import CustomStatsDialog from '../CustomStatsDialog/CustomStatsDialog';
import Header from 'components/Header/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from 'components/DeleteDialog';
interface CustomStatsProps {
    authId?: number;
    players: { id: number; username: string }[];

    back: () => void;
}

const CustomStats: FC<CustomStatsProps> = ({ players, authId, ...props }) => {
    const [revisions, setRevision] = useState(1);
    const [open, setOpen] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState<number>(null);
    const queryClient = useQueryClient();

    const { isLoading, data: playerData } = useQuery(['student-stats', players, revisions, authId], getCustomStats);

    const { mutate } = useMutation(deleteRevisionCustomStats, {
        onSuccess: () => {
            queryClient.invalidateQueries('student-stats');
        },
    });

    function handleRevision(rev: number) {
        queryClient.invalidateQueries('student-stats');
        setRevision(rev);
    }

    function handleDeleteRevision() {
        mutate(selectedId);
        setDeleteDialog(false);
    }

    function handleOpenDeleteDialog(id: number) {
        setSelectedId(id);
        setDeleteDialog(true);
    }

    if (isLoading) return null;

    return (
        <div className='w-full'>
            <Header title='Кастомная Статистика' back={props.back}>
                {players.length ? `${players.map(val => val.username).join(', ')}: ` : ''}
            </Header>
            <div className='w-full flex items-center justify-between space-x-8 mb-10'>
                <div className='w-full' style={{ maxWidth: 400 }}>
                    <TextField label='Ревизий' value={revisions} onChange={({ target }) => handleRevision(+target.value)} fullWidth select>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                    </TextField>
                </div>
                {authId ? null : (
                    <Button variant='contained' size='large' onClick={() => setOpen(true)} disabled>
                        Добавить
                    </Button>
                )}
            </div>
            <div className='w-full space-y-10'>
                {playerData.map((data: any, i: number) => {
                    if (!data.columns.length) return null;

                    return (
                        <div key={i} className='w-full'>
                            {data.username ? <p className='mb-2 font-semibold text-base'>Игрок: {data.username}</p> : null}
                            <div className='bg-white overflow-auto'>
                                <Table className='w-full' size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='text-gray-700 font-bold text-sm py-4'>Статистика</TableCell>
                                            {data.columns.map((val) => (
                                                <TableCell className='text-gray-700 font-bold text-sm py-4' key={val.id}>
                                                    <div className='flex flex-row gap-2 items-center '>
                                                        <div>
                                                            {val.revision_date}
                                                        </div>
                                                        {authId ? null : (
                                                            <div>
                                                                <IconButton className='bg-white' onClick={() => handleOpenDeleteDialog(val.id)} disabled>
                                                                    <DeleteIcon fontSize='small' />
                                                                </IconButton>
                                                            </div>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.data.map(({ category, rows }, i) => (
                                            <Fragment key={i}>
                                                <TableRow>
                                                    <TableCell className='text-center font-semibold bg-gray-200' colSpan={data.columns.length + 1}>
                                                        {category.name}
                                                    </TableCell>
                                                </TableRow>
                                                {rows.map((row, rowI) => (
                                                    <TableRow key={`${rowI} + ${category.name}`}>
                                                        {row.map((col, i) => {
                                                            if (typeof col === 'string') return <TableCell key={i}>{col}</TableCell>;

                                                            if (col.note)
                                                                return (
                                                                    <Tooltip title={col.note} key={i}>
                                                                        <TableCell className='relative'>
                                                                            <div
                                                                                className='inset-0 absolute opacity-50'
                                                                                style={{ backgroundColor: col.color || 'white' }}
                                                                            />
                                                                            <p className='relative'>
                                                                                <span>{col.value}</span>
                                                                            </p>
                                                                        </TableCell>
                                                                    </Tooltip>
                                                                );

                                                            return (
                                                                <TableCell className='relative' key={i}>
                                                                    <div
                                                                        className='inset-0 absolute opacity-50'
                                                                        style={{ backgroundColor: col.color || 'white' }}
                                                                    />
                                                                    <p className='flex-grow flex-shrink-0 relative'>
                                                                        <span>{col.value}</span>
                                                                    </p>
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    );
                })}
            </div>
            <DeleteDialog close={() => setDeleteDialog(false)} open={deleteDialog} handleAgreeAction={handleDeleteRevision} />
            {authId ? null : <CustomStatsDialog studentIds={players.map(val => val.id)} open={open} onClose={() => setOpen(false)} />}
        </div>
    );
};

export default CustomStats;
