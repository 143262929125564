import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
// import DialogContentText from '@mui/material/DialogContentText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';

import { useQueryClient, useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import { transferToRoom } from 'api/rooms';
import { RoomCard } from 'types/backend/RoomCard';

export interface ConfirmTransferProps {
    room: RoomCard;
    rooms: RoomCard[];
    onClose: () => void;
}

const schema = yup.object().shape({
    deposit_account_record: yup.number().required(),
    comment: yup.string().url().required('Скриншот обязательное поле'),
    deposit_scr: yup.string().url().required('Скриншот обязательное поле'),
    amount: yup.number().required().min(0, 'Должно быть положительное число'),
    change_balance: yup.boolean(),
});

const TransferToRoom: React.FC<ConfirmTransferProps> = props => {
    const queryClient = useQueryClient();

    const [rooms, setRooms] = React.useState<RoomCard[]>([]);

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const mutation = useMutation(transferToRoom, {
        onSuccess: () => {
            queryClient.invalidateQueries('dashboardrooms');
            queryClient.invalidateQueries('contract');
            queryClient.invalidateQueries('lastcontract');
            queryClient.invalidateQueries('contracttournaments');
            queryClient.invalidateQueries('bundle');
            queryClient.invalidateQueries('bundletournaments');

            closeDialog();
        },
    });

    React.useEffect(() => {
        if (props.room && props.rooms) {
            setRooms(
                props.rooms.filter(val => {
                    return val.chip_currency.id === props.room.chip_currency.id && val.account_record.id !== props.room.account_record.id;
                })
            );
        }
    }, [props.room, props.rooms]);

    const onSubmit = handleSubmit(data => mutation.mutate({ cashout_account_record: props.room.account_record.id, ...data } as any));

    const closeDialog = () => {
        reset({
            deposit_account_record: '',
            amount: 0,
            comment: '',
            deposit_scr: '',
            change_balance: true,
        });

        props.onClose();
    };

    return (
        <>
            <DialogTitle>
                Подтвердить перевод средств {props.room?.account_record_name}, {props.room?.room.name}?
            </DialogTitle>
            <form onSubmit={onSubmit} noValidate autoComplete="off">
                <DialogContent>
                    <Controller
                        name="deposit_account_record"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <FormControl variant="outlined" className="mb-4 w-full">
                                <InputLabel id="room-select-label">Перевод в аккаунт</InputLabel>
                                <Select labelId="room-select-label" label="Перевод в аккаунт" required {...field}>
                                    {rooms.map(val => (
                                        <MenuItem key={val.account_record.id} value={val.account_record.id}>
                                            {val.account_record_name}, {val.room.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="amount"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                            <TextField
                                error={!!errors.amount?.message}
                                helperText={errors.amount?.message}
                                label="Сумма перевода"
                                type="number"
                                variant="outlined"
                                className="mb-4 w-full"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="comment"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                error={!!errors.comment?.message}
                                helperText={errors.comment?.message}
                                label="Скриншот кэшаута"
                                type="url"
                                variant="outlined"
                                className="mb-2 w-full"
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="deposit_scr"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                error={!!errors.deposit_scr?.message}
                                helperText={errors.deposit_scr?.message}
                                label="Скриншот депозита"
                                type="url"
                                variant="outlined"
                                className="mb-2 w-full"
                                required
                                {...field}
                            />
                        )}
                    />
                    {/*<DialogContentText className="mb-8 text-sm">
                        Скриншот должен содержать данные об остатках и о переводе (<a href="https://prnt.sc/12ua810" target="_blank" rel="noreferrer" className="text-blue-600 hover:text-blue-500">пример</a>)
                    </DialogContentText>*/}
                    <Controller
                        name="change_balance"
                        control={control}
                        defaultValue={true}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Изменить баланс"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" disabled={mutation.isLoading} onClick={closeDialog}>
                        Отмена
                    </Button>
                    <Button color="primary" loading={mutation.isLoading} type="submit">
                        Подтвердить
                    </Button>
                </DialogActions>
            </form>
        </>
    );
};

export default TransferToRoom;
