import { FrontendSettings } from 'types/backend/FrontendSettings';

export const APP_SET_LOADING = 'APP/SET_LOADING';
export const APP_GET_DATA = 'APP/GET_DATA';
export const APP_SET_VIDEO_PAGE = 'APP/SET_VIDEO_PAGE';
export const APP_SET_SETTINGS = 'APP/SET_SETTINGS';

export interface AppSetLoading { type: typeof APP_SET_LOADING; payload: boolean; }
export interface AppGetData { type: typeof APP_GET_DATA; }
export interface AppSetVideoPage { type: typeof APP_SET_VIDEO_PAGE; payload: string; }
export interface AppSetSettings { type: typeof APP_SET_SETTINGS; payload: FrontendSettings; }

interface AppState {
    loading: boolean;
    videoPage: string;
    settings: FrontendSettings;
}

type appActionTypes = AppSetLoading | AppSetVideoPage | AppSetSettings;

export const appSetLoading = (payload: boolean) => ({ type: APP_SET_LOADING, payload });
export const appGetData = () => ({ type: APP_GET_DATA });
export const appSetVideoPage = (payload: string) => ({ type: APP_SET_VIDEO_PAGE, payload });
export const appSetSettings = (payload: FrontendSettings) => ({ type: APP_SET_SETTINGS, payload });

const initialState: AppState = {
    loading: true,
    videoPage: '10',
    settings: {
        allow_untracked_input: [],
        single_screenshot_rooms: {},
    }
};

const app = (state: AppState = initialState, action: appActionTypes): AppState => {
    switch (action.type) {
        case APP_SET_LOADING:
            return { ...state, loading: action.payload };
        case APP_SET_VIDEO_PAGE:
            return { ...state, videoPage: action.payload };
        case APP_SET_SETTINGS:
            return { ...state, settings: action.payload };
        default:
            return state;
    }
};

export default app;
