import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { setPermissions, setProfile } from '../auth/auth';

import { getAlerts, getUserPermissions, getProfile, getSettings } from './requests';
import { APP_GET_DATA, appSetLoading, appSetSettings } from './app';

const showAlerts = async (
    alertsData: {
        id: number;
        text: string;
        type: 'success' | 'warning' | 'error';
    }[]
) => {
    const alerts = (localStorage.getItem('alerts') || '').split(',').map(Number);

    alertsData
        ?.filter(val => !alerts.includes(val.id))
        .forEach(val => {
            toast[val.type](val.text, { autoClose: false });
        });

    if (alertsData?.length) localStorage.setItem('alerts', alertsData.map(val => val.id).join(','));
};

export function* getDataSaga() {
    try {
        yield put(appSetLoading(true));

        const { data: alertsData } = yield call(getAlerts);

        const permissions = yield call(getUserPermissions);
        const profile = yield call(getProfile);
        const settings = yield call(getSettings);

        showAlerts(alertsData.data);

        yield put(appSetSettings(settings));
        yield put(setPermissions(permissions));
        yield put(setProfile(profile));
    } finally {
        yield put(appSetLoading(false));
    }
}

export function* watchApp() {
    yield all([takeLatest(APP_GET_DATA, getDataSaga)]);
}
