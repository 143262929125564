import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useQuery } from 'react-query';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { getTeam } from 'api/team';

const Team: React.FC = () => {
    const rootEl = React.useRef(null);
    const gridApi = React.useRef(null);

    const { data, isLoading } = useQuery('team', getTeam);

    const onSearchChange = ({ target }) => {
        gridApi.current.setQuickFilter(target.value);
    };

    return (
        <div className="w-full flex-1 flex flex-col">
            <div className="w-2/5 mb-8">
                <TextField
                    label="Поиск"
                    variant="outlined"
                    onChange={onSearchChange}
                />
            </div>
            <div className="w-full flex-1 relative" ref={rootEl}>
                <div className="w-full shadow-md rounded overflow-hidden" style={{ height: rootEl.current?.offsetHeight || 0 }}>
                    <AgGridReact
                        onGridReady={({ api }) => gridApi.current = api}
                        rowData={data?.table_data || []}
                        defaultColDef={{ sortable: true, resizable: true }}
                        onCellClicked={(e) => navigator.clipboard.writeText(e.value)}
                    >
                        {
                            (data?.headers || []).map(val => (
                                <AgGridColumn key={val.key} headerName={val.name} field={val.key.toString()} />
                            ))
                        }
                    </AgGridReact>
                </div>
                {
                    isLoading ? (
                        <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                            <CircularProgress />
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default Team;
