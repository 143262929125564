import { FC } from 'react';

import { IconButton, Box, Typography, Button, MenuList, MenuItem } from '@mui/material';
import { ChevronLeftRounded, ChevronRightRounded, KeyboardArrowDownRounded } from '@mui/icons-material';

import Dropdown from './Dropdown';

import PagingSize from '../../constants/PagingSize';

import PaginationRange from './PaginationRange';

/**
 * Свойства компонента.
 */
type Props = {
    /**
     * Номер текущей страницы (отсчёт начинается с единицы).
     */
    current: number;

    /**
     * Всего элеентов в списке.
     */
    total: number;

    /**
     * Максимальное количество элементов на одной странице.
     * @default {PagingSize.N10}
     */
    range?: PagingSize;

    /**
     * Обработчик события смены диапазона.
     */
    onChangeRange?: (newRange: PagingSize) => void;

    /**
     * Обработчик события смены страницы.
     */
    onChangePage?: (value: React.SetStateAction<number>) => void;
};

/**
 * Отображает пагинацию
 */
const Pagination: FC<Props> = ({ current, range = PagingSize.N10, total, onChangeRange, onChangePage }: Props) => {
    const count = Math.ceil((total || 0) / range);
    const totalPages = Math.ceil(total / Number(range));

    return (
        <Box className='flex w-full items-center py-2 px-3'>
            <Typography variant='body2'>Размер страницы:</Typography>

            <Dropdown
                placement='bottom-start'
                renderContent={({ close }) => (
                    <MenuList>
                        {Object.values(PagingSize)
                            .filter(item => typeof item === 'number')
                            .map(rangeItem => (
                                <MenuItem
                                    key={rangeItem}
                                    onClick={() => {
                                        onChangeRange?.(rangeItem as PagingSize);
                                        onChangePage(1);
                                        close();
                                    }}
                                >
                                    {rangeItem === Infinity ? 'Все' : rangeItem}
                                </MenuItem>
                            ))}
                    </MenuList>
                )}
            >
                <Button className='min-w-0' color='inherit' size='medium' endIcon={<KeyboardArrowDownRounded />}>
                    {range === Infinity ? 'Все' : range}
                </Button>
            </Dropdown>
            {range !== Infinity && (
                <>
                    <PaginationRange count={count} current={current} />
                    <IconButton size='small' color='primary' className='ml-1' onClick={() => onChangePage?.(current - 1)} disabled={current <= 1}>
                        <ChevronLeftRounded />
                    </IconButton>
                    <IconButton size='small' color='primary' onClick={() => onChangePage?.(current + 1)} disabled={current >= totalPages}>
                        <ChevronRightRounded />
                    </IconButton>
                </>
            )}
        </Box>
    );
};

export default Pagination;
