import { FC, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios_template';
import { AppState } from 'store/configureStore';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { getVideosInfo } from 'api/videos';
import PlaybackHeader from './PlaybackHeader';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

type Props = {
    /**
     * Идентификатор видео.
     */
    id: string;

    /**
     * Функция для возврата назад.
     */
    resetVideo: () => void;
};

const Playback: FC<Props> = ({ id, resetVideo }) => {
    const token = useSelector((state: AppState) => state.auth.access);

    const { data: video, isLoading, isError } = useQuery(['videos-watch', id], getVideosInfo);

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const mDate = moment(video?.recorded_datetime, 'YYYY-MM-DD');
    const authors = video?.authors.map(val => val.name);

    useEffect(() => {
        if (isError) {
            resetVideo();
        }
    }, [isError, resetVideo]);

    useEffect(() => {
        const onResize = () => {
            let newWidth = 1920;
            let newHeight = 1080;

            const container = document.querySelector('main') as HTMLDivElement;
            const padding = parseFloat(getComputedStyle(document.querySelector('main > div')).padding);

            const offsetHeight = container.offsetHeight - padding * 2;
            const offsetWidth = container.offsetWidth - padding * 2;

            if (offsetHeight < newHeight) {
                newHeight = offsetHeight;
                newWidth = (newHeight / 9) * 16;
            }

            if (offsetWidth < newWidth) {
                newWidth = offsetWidth;
                newHeight = (newWidth / 16) * 9;
            }

            setWidth(newWidth);
            setHeight(newHeight);
        };

        window.addEventListener('resize', onResize);

        onResize();

        return () => window.removeEventListener('resize', onResize);
    }, []);

    return (
        <div className="w-full flex-1 flex justify-center">
            <div style={{ height, width }}>
                {isLoading && (
                    <div className="flex flex-1 items-center justify-center">
                        <CircularProgress />
                    </div>
                )}
                {!isLoading && video && (
                    <>
                        <PlaybackHeader
                            title={video.title}
                            resetVideo={resetVideo}
                            id={video.id}
                            watched={video.watched}
                            authors={authors}
                            user_rating={video.user_rating}
                            avg_rating={video.avg_rating}
                            recorded_date={mDate.format('D MMM YYYY')}
                        />
                        <ReactPlayer
                            controls={true}
                            light={`${process.env.PUBLIC_URL}/assets/images/video/videoposter.avif`}
                            width="100%"
                            height="100%"
                            config={{
                                file: {
                                    forceVideo: true,
                                    attributes: {
                                        type: 'video/mp4',
                                        onContextMenu: e => e.preventDefault(),
                                        controlsList: 'nodownload',
                                        playsInline: true,
                                        preload: 'metadata',
                                    },
                                },
                            }}
                            url={`${axios.defaults.baseURL}api/videogallery/videos_auth/${video.id}/?Authorization=${token}`}
                            playing={true}
                            className="bg-gray-300"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Playback;
