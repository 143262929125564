import { Dispatch, FC, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import clsx from 'clsx';

import { getStudentHomework } from 'api/curator';

import { IconButton, Badge, CircularProgress } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EmailIcon from '@mui/icons-material/Email';

type Props = {
    /**
     * Массив выбранных игроков.
     */
    players: { id: number; username: string }[];

    /**
     * ИД авторизованного пользователя.
     */
    authId?: number;

    /**
     * Кол-во записей на странице.
     */
    perPage: number;

    /**
     * Номер текущей страницы.
     */
    page: number;

    /**
     * Примененный фильтр.
     */
    filter: any[];

    /**
     * Функция выбора элемента для отображения переписки.
     */
    setMessageItem: Dispatch<any>;

    /**
     * Функция выбора элемента для редактирования.
     */
    setItem: Dispatch<any>;

    /**
     * Функция записывающее общее число записей.
     */
    setCount: Dispatch<SetStateAction<number>>;
};

const HomeworkBody: FC<Props> = ({ players, authId, perPage, page, filter, setMessageItem, setItem, setCount }) => {
    const { data: item, isLoading } = useQuery(['student-homework', players, authId, perPage, page, filter], getStudentHomework, {
        onSuccess: data => setCount(data.count),
    });

    function borderClass (completed: boolean, overdue: boolean, closed: boolean) {
        if (completed && !closed) {
            return 'border-green-600'
        }
        if (overdue && !closed) {
            return 'border-red-300'
        }
        return 'border-stone-500'
    }

    if (isLoading)
        return (
            <div className='w-full flex items-center justify-center'>
                <CircularProgress />
            </div>
        );

    if (!item.count) return <div className='w-full flex items-center justify-center font-bold text-lg'>По данному запросу записей не обнаружено</div>;

    return (
        <div className='w-full grid 2xl:grid-cols-4 lg:grid-cols-2 gap-4'>
            {item?.data?.map(function (val) {
                return (
                    <div key={val.id} className={clsx('rounded p-3 shadow-md overflow-hidden bg-white border-l-4', borderClass(val.is_completed, val.is_overdue, val.is_closed))}>
                        <div className='w-full text-lg font-bold flex items-center justify-between'>
                            <div onClick={() => setMessageItem(val)} className='flex flex-col gap-2 leading-none  cursor-pointer'>
                                <p className='font-bold text-sm leading-none'>{val.topic}</p>
                                {authId ? null : <p className='font-normal text-sm leading-none'>Ученик: {val.player.username}</p>}
                                <p className='font-normal text-sm leading-none'>Создано: {moment(val.created_datetime).format('DD.MM.YYYY HH:mm')}</p>
                                {val.overdue_at && (
                                    <p className='font-normal text-sm leading-none text-red-400'>Просрочено: {moment(val.overdue_at).format('DD.MM.YYYY HH:mm')}</p>
                                )}
                                {val.deadline && (
                                    <p className='font-normal text-sm leading-none'>Выполнить до: {moment(val.deadline).format('DD.MM.YYYY HH:mm')}</p>
                                )}
                            </div>
                            <div className='flex space-x-2'>
                                <IconButton onClick={() => setMessageItem(val)} size='small'>
                                    <Badge badgeContent={val.unread_count} color='info'>
                                        <EmailIcon fontSize='small' />
                                    </Badge>
                                </IconButton>

                                {authId ? null : (
                                    <IconButton onClick={() => setItem(val)} size='small'>
                                        <BorderColorIcon fontSize='small' />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                        <div className='w-full mt-4 text-sm leading-none flex items-center space-x-2'>
                            {val.is_completed && <p className={clsx('px-3 py-2 rounded-full', val.is_closed ? 'bg-gray-200' : 'bg-green-500 text-white')}>Выполнено</p>}
                            {val.is_closed && <p className='px-3 py-2 rounded-full bg-gray-200'>Закрыто</p>}
                            {val.is_overdue && (!val.is_completed || !val.is_closed) && <p className='px-3 py-2 rounded-full bg-red-100'>Просрочено</p>}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default HomeworkBody;
