import { FC, Dispatch, SetStateAction } from 'react';

import { Chip } from '@mui/material';

type Props = {
    /**
     * Функция записывающая массив фильтров для запроса.
     */
    setFilter: Dispatch<SetStateAction<any[]>>;

    /**
     * Функция сброса текущей страницы.
     */
    resetPage: () => void;

    /**
     * Массив примененных фильтров.
     */
    data: any;
};

const HomeworkFilter: FC<Props> = ({ data, setFilter, resetPage }) => {
    function changeFilter(tag: string) {
        let filter = data.slice();
        const id = filter.findIndex(data => data.tag === tag);
        const filterData = data.filter(data => data.tag === tag);

        if (id >= 0) {
            filter.splice(id, 1);
        }

        if (filterData[0]?.value === 'true') {
            filter.push({ tag, value: 'false' });
        } else if (!filterData[0]) {
            filter.push({ tag, value: 'true' });
        }

        resetPage();
        setFilter(filter);
    }

    function filterColor(tag) {
        const id = data.filter(val => val.tag === tag);

        switch (id[0]?.value) {
            case 'true':
                return 'success';
            case 'false':
                return 'error';
            default:
                return 'default';
        }
    }

    return (
        <div className='flex flex-wrap gap-2'>
            <Chip label='Выполненные' color={filterColor('isCompleted')} onClick={() => changeFilter('isCompleted')} clickable />
            <Chip label='Закрытые' color={filterColor('isClosed')} onClick={() => changeFilter('isClosed')} clickable />
            <Chip label='Просроченные' color={filterColor('isOverdue')} onClick={() => changeFilter('isOverdue')} clickable />
        </div>
    );
};

export default HomeworkFilter;
