import { FC } from 'react';
import Stats from '@mui/icons-material/QueryStats';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getSharkscope, getCurrentUser } from 'api/user';
import InfoCard from 'components/InfoCard/InfoCard';
import { Tooltip } from '@mui/material';

const SharkScopeCard: FC = () => {
    const sharkscopeStats = useQuery('sharkscope-stats', getSharkscope, { staleTime: 60000 });
    const currentUserQuery = useQuery('user', getCurrentUser, { staleTime: 60000 });

    return (
        <InfoCard color="bg-yellow-600" width="w-full lg:w-1/3" loading={sharkscopeStats.isLoading}>
            <div className="flex">
                <Stats className="mr-6 text-5xl lg:self-center self-start" />
                <div className="flex-1 flex-col flex flex-wrap">
                    <div className="flex items-center gap-1 flex-row flex-wrap mb-2 justify-between">
                        <div className="text-base">Shark, 30 дней</div>
                        <div>
                            <a
                                href={`https://ru.sharkscope.com/#Player-Statistics/Advanced-Search//networks/Player%20Group/players/${currentUserQuery.data?.shark_player_group}?filter=Date:${currentUserQuery.data?.date_joined_ts}~*`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                SharkScope
                            </a>
                        </div>
                    </div>

                    <Tooltip title={<p>Дата обновления: {moment(sharkscopeStats.data?.updated_at).format('DD.MM.YYYY')}</p>}>
                        <div className="flex gap-2 flex-row flex-wrap">
                            <div>ABI: {sharkscopeStats.data?.abi ? sharkscopeStats.data?.abi : '—'}</div>
                            <div>Entries: {sharkscopeStats.data?.entries ? sharkscopeStats.data?.entries : '—'}</div>
                            <div>Reentry: {sharkscopeStats.data?.reentry ? `${sharkscopeStats.data?.reentry}%` : '—'}</div>
                            <div>Turbo: {sharkscopeStats.data?.turbo ? `${sharkscopeStats.data?.turbo}%` : '—'}</div>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </InfoCard>
    );
};

export default SharkScopeCard;
