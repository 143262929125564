import { FC, useState, useEffect } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppOutlined from '@mui/icons-material/ExitToAppOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem, Drawer, Divider, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';

import { useLocation, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';

import useWindowSize from 'hooks/useWindowSize';
import menu from 'constants/menu';

import { logout } from 'store/ducks/auth/auth';
import { AppState } from 'store/configureStore';
import { appGetData } from 'store/ducks/app/app';

import { getForm } from 'api/form';
import { getReadCuratorCount } from 'api/curator';
import { getReadPlayerCount } from 'api/user';

import UserForm from './components/UserForm/UserForm';
import DashboardLink from './components/DashboardLink/DashboardLink';

import userPNG from 'assets/images/user.png';

const DRAWER_BREAKPOINT = 1024;

const Dashboard: FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const appLoading = useSelector((store: AppState) => store.app.loading);
    const profile = useSelector((store: AppState) => store.auth.profile);

    const { width: windowWidth } = useWindowSize();

    const [open, setOpen] = useState(window.innerWidth < DRAWER_BREAKPOINT ? false : true);
    const [header, setHeader] = useState('Главная');
    const [anchorEl, setAnchorEl] = useState(null);

    const { isLoading, data } = useQuery('form', getForm, { staleTime: 60000 });

    const { data: curatorCount } = useQuery(['read-homework-curator'], getReadCuratorCount, { staleTime: 60000 });
    const { data: playerCount } = useQuery(['read-homework-player'], getReadPlayerCount, { staleTime: 60000 });

    useEffect(() => {
        setHeader(
            menu.find(val => {
                const match = location.pathname.match(new RegExp(val.mask));
                return match && location.pathname === match[0];
            })?.name || 'Главная'
        );
    }, [location]);

    useEffect(() => {
        dispatch(appGetData());
    }, [dispatch]);

    const handleLogout = () => {
        setAnchorEl(null);
        queryClient.cancelQueries();
        queryClient.clear();
        queryClient.invalidateQueries();

        dispatch(logout());
    };

    const handleDrawerClose = () => {
        if (windowWidth < DRAWER_BREAKPOINT) setOpen(false);
    };

    if (!data || isLoading || appLoading)
        return (
            <div className="w-screen h-screen flex items-center justify-center">
                <CircularProgress />
            </div>
        );

    if (data && !data.form && data?.accounts?.length > 0) return <UserForm accounts={data.accounts || []} />;
    if (data && !data.form && data?.accounts?.length === 0) return <div>No accounts found</div>;

    return (
        <div className="h-screen w-screen flex">
            <Drawer
                variant={windowWidth < DRAWER_BREAKPOINT ? 'temporary' : 'persistent'}
                anchor="left"
                className={windowWidth < DRAWER_BREAKPOINT ? '' : `drawer ${open ? 'drawer-width' : ''}`}
                classes={{ paper: `bg-header border-r-0 drawer-width` }}
                open={open}
                onClose={() => setOpen(prev => !prev)}
            >
                <div className="bg-main-500 flex items-center justify-center" style={{ height: '80px' }}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/logos/logo_kot.svg`} alt="King of Tournaments" className="h-1/2" />
                    <p className="text-white font-medium text-lg leading-none ml-4">
                        Личный
                        <br />
                        Кабинет
                    </p>
                </div>
                {menu.map((val, i) => (
                    <DashboardLink
                        key={i}
                        route={val}
                        handleDrawerClose={handleDrawerClose}
                        badgeContent={val.route === '/students' ? curatorCount?.unread_count : 0 || val.route === '/player' ? playerCount?.unread_count : 0}
                        attention={val.route === '/students' ? curatorCount?.attention : null || val.route === '/player' ? playerCount?.attention : null}
                    />
                ))}
            </Drawer>
            <div className="w-full h-screen flex flex-col overflow-hidden">
                <header className="bg-header w-full flex items-center justify-between lg:px-5 px-2" style={{ height: '80px' }}>
                    <div className="flex items-center">
                        <button type="button" onClick={() => setOpen(prev => !prev)}>
                            <MenuIcon className="text-white" />
                        </button>
                        <p className="text-white text-2xl ml-4 font-medium">{header}</p>
                    </div>
                    <div className="h-12 w-12 rounded-full border-white border-4 overflow-hidden cursor-pointer" onClick={e => setAnchorEl(e.currentTarget)}>
                        <img src={userPNG} alt="user" className="w-full h-full" />
                    </div>
                </header>
                <main className="w-full flex-1 overflow-y-auto">
                    <div className="lg:p-5 p-2 min-h-full flex flex-col">
                        <Outlet />
                    </div>
                </main>
            </div>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                <MenuItem>
                    <ListItemIcon>
                        <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={profile?.username} className="font-medium text-gray-700" />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Выйти" className="font-medium text-gray-700" />
                </MenuItem>
            </Menu>
        </div>
    );
};

export default Dashboard;
