import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { UseQueryResult } from 'react-query';

import Card from 'components/Card/Card';

import { RoomCard } from 'types/backend/RoomCard';

interface RoomsCardsPRops {
    dispatch: React.Dispatch<any>;
    query: UseQueryResult<RoomCard[]>;
}

const RoomsCards: React.FC<RoomsCardsPRops> = ({ dispatch, query }) => {
    return (
        <div className="w-full mb-8 text-gray-700">
            {query.isFetching ? (
                <div className="w-full flex items-center justify-center">
                    <CircularProgress />
                </div>
            ) : query.data ? (
                <div className="w-full grid xl:grid-cols-4 lg:grid-cols-3 gap-5 grid-cols-1">
                    {query.data
                        .filter(item => !item.virtual)
                        .map(val => (
                            <Card
                                key={`${val.account_record_name}`}
                                room={val}
                                onChangeBalance={() => dispatch({ changeBalanceOpen: true, room: val })}
                                onCashflow={() => dispatch({ chasflowOpen: true, room: val })}
                                onDepositRequest={() => dispatch({ depositRequestOpen: true, room: val })}
                                onTransferBalance={() => dispatch({ transferBalanceOpen: true, room: val })}
                            />
                        ))}
                    {query.data
                        .filter(item => item.virtual)
                        .map(val => (
                            <Card
                                key={`${val.account_record_name} ${val.club_name}`}
                                room={val}
                                onChangeBalance={() => dispatch({ changeBalanceOpen: true, room: val })}
                                onCashflow={() => dispatch({ chasflowOpen: true, room: val })}
                                onDepositRequest={() => dispatch({ depositRequestOpen: true, room: val })}
                                onTransferBalance={() => dispatch({ transferBalanceOpen: true, room: val })}
                            />
                        ))}
                </div>
            ) : null}
        </div>
    );
};

export default RoomsCards;
