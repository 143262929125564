import axios from 'axios_template';
import blobAxios from 'helpers/blobAxios';

import { Transfer } from 'types/backend/Transfer';

export const getTransfers = async () => {
    // const { data } = await axios.get<{
    //     to_send: Transfer[];
    //     to_receive: Transfer[];
    // }>('/api/accounting/transfers/');
    const { data } = await axios.get<{
        to_send: Transfer[];
        to_receive: Transfer[];
    }>('/api/accounting/transfersv2/');

    return [
        ...data.to_send.map(val => ({ ...val, type: 'Отправить' })),
        ...data.to_receive.map(val => ({ ...val, type: 'Получить' })),
    ];
};

export const reserveTransfer = async ({ id, ...body }: { id: number, change_balance: boolean }) => {
    return axios.post(`/api/accounting/transfers/holdv2/${id}/`, body);
};

export const confirmTransfer = async ({ id, ...body }: { id: number, player_comment: string, fee?: number }) => {
    return axios.post(`/api/accounting/transfers/confirmv2/${id}/`, body);
};

export const sendTransferImage = async ({ transferId, image }: { transferId: number, image: Blob }) => {
    const formData = new FormData();
    formData.append('image', image, 'screenshot.png');

    return blobAxios.post(`/api/accounting/transfers/${transferId}/images/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
