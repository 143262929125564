import React from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import { get } from 'lodash';

export interface SelectProps {
    label: string;
    options?: any[];
    labelPath?: string;
    valuePath?: string;
    loading?: boolean;
    multiple?: boolean;
    value?: any;
    disabled?: boolean;

    onChange?: (val: any) => void;
}

const Select: React.FC<SelectProps> = ({
    label = 'Select',
    options = [],
    labelPath = 'label',
    valuePath = 'value',
    loading = false,
    multiple = true,
    disabled = false,
    ...props
}) => {
    return (
        <Autocomplete
            className="w-full"
            multiple={multiple}
            value={props.value}
            onChange={(_, value) => props.onChange(value)}
            options={options}
            loading={loading}
            disabled={disabled}
            isOptionEqualToValue={(option, value) => {
                if (typeof option === 'string') return option === value;

                return option?.value === value?.value;
            }}
            disableClearable
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip label={get(option, labelPath)} {...getTagProps({ index })} />
                ))
            }
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default Select;