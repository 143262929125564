import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from '@mui/material/Button';

const NotFound: React.FC = () => {
    return (
        <div className="w-full h-full flex items-center justify-center flex-col">
            <p className="text-9xl font-black mb-4">Error 404</p>
            <NavLink to="/">
                <Button size="large">Go Back to Homepage</Button>
            </NavLink>
        </div>
    );
};

export default NotFound;
