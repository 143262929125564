import { FC } from 'react';
import Star from '@mui/icons-material/StarOutline';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getBonuses } from 'api/user';
import InfoCard from 'components/InfoCard/InfoCard';
import { Tooltip } from '@mui/material';

const BonusCard: FC = () => {
    const bonuses = useQuery('home-bonuses', getBonuses, { staleTime: 60000 });

    return (
        <InfoCard color="bg-green-600" width="w-full lg:w-1/3" loading={bonuses.isLoading}>
            <div className="flex">
                <Star className="mr-6 text-5xl lg:self-center self-start" />
                <div className="flex-1 flex-col flex flex-wrap">
                    <div className="text-base mb-2">Бонус</div>
                    <Tooltip title={<p>Дата обновления: {moment(bonuses.data?.updated_at).format('DD.MM.YYYY')}</p>}>
                        <div className="flex gap-2 flex-row flex-wrap">
                            <div>AVG ABI: {bonuses.data?.avg_abi ? bonuses.data?.avg_abi : '—'}</div>
                            <div>AVG Entries: {bonuses.data?.avg_ts_month ? bonuses.data?.avg_ts_month : '—'}</div>
                            <div>Fair ROI: {bonuses.data?.fair_roi ? `${bonuses.data?.fair_roi}%` : '—'}</div>
                            <div>Бонус: {bonuses.data?.bonus ? `${bonuses.data?.bonus}$` : '—'}</div>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </InfoCard>
    );
};

export default BonusCard;
