import axios from 'axios';
import { toast } from 'react-toastify';

import { authenticate, logout } from 'store/ducks/auth/auth';

const axiosKS = axios.create({
    baseURL: 'https://api.kingsoftournaments.com/',
});

const toastErrors = (data: any) => {
    if (data?.error?.text) {
        return Object.values(data.error.text).forEach(val => toast.error(val));
    }

    if (typeof data.error !== 'string') {
        return Object.values(data.error).forEach((errors: any[]) => {
            errors.forEach(val => toast.error(val));
        });
    }

    toast.error(data.detail || data.error || 'Неизвестная ошибка');
};

export const setupInterceptors = (store: any) => {
    axiosKS.interceptors.request.use(config => {
        if (config.headers.Authorization) return config;

        const { access } = store.getState().auth;

        if (!access) return config;

        return {
            ...config,
            headers: { ...config.headers, Authorization: `ApiToken ${access}` },
        };
    });

    axiosKS.interceptors.response.use(
        res => {
            if (res?.data?.success && res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.count) {
                const data = { count: res?.data?.count, data: res?.data.data };
                return { ...res, data: data };
            }
            if (res?.data?.success && res?.data?.data !== null && res?.data?.data !== undefined) return { ...res, data: res?.data.data };

            return res;
        },
        async err => {
            const { refresh, access } = store.getState().auth;

            if (err && err.response && err.response?.status === 401 && access && err.config.url === '/api/token/refresh/') {
                store.dispatch(logout());

                return Promise.reject(err);
            }

            if (err && err.response && err.response.status !== 401) {
                toastErrors(err.response.data);

                return Promise.reject(err);
            }

            try {
                const { data } = await axiosKS.post('/api/token/refresh/', { refresh });

                store.dispatch(authenticate({ ...data, refresh }));

                err.config.headers['Authorization'] = data.access;

                return axios.request(err.config);
            } catch {
                return Promise.reject(err);
            }
        }
    );
};

export default axiosKS;
