import React from 'react';
import { useMutation } from 'react-query';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/lab/LoadingButton';
// import { useQuery } from 'react-query';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RoomCard } from 'types/backend/RoomCard';
import { requestPayouts } from 'api/payouts';
// import { getPlayerStats } from 'api/sharkstats';

import * as yup from 'yup';

export interface RequestPayoutProps {
    rooms: RoomCard[];
    onClose: () => void;
}

const twoScreenshotsRooms = [14];

const RequestPayout: React.FC<RequestPayoutProps> = (props: RequestPayoutProps) => {
    // const { data } = useQuery('playerStats30', getPlayerStats, {
    //     initialData: {
    //         abi_30: 0,
    //     },
    // });

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(
            yup.object().shape({
                rooms: yup.array().of(
                    yup.object().shape({
                        account_record_id: yup.number(),
                        screenshot: yup.string().url().required(),
                        screenshot2: yup.string().url(),
                    })
                ),
            })
        ),
    });

    const mutation = useMutation(requestPayouts, {
        onSuccess: () => {
            closeDialog();
        },
    });

    const sendPayoutRequest = handleSubmit(data => mutation.mutate(data.rooms as any));

    const closeDialog = () => {
        reset(
            { rooms: [] },
            {
                keepErrors: false,
                keepDirty: false,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
            }
        );
        props.onClose();
    };

    return (
        <>
            <DialogTitle>Запрос расчёта</DialogTitle>
            <form onSubmit={sendPayoutRequest} noValidate autoComplete="off">
                <DialogContent>
                    <DialogContentText className="mb-4 text-sm">
                        Расчёт за текущий пакет происходит по запросу игрока, при положительном результате по пакету.
                        <ul className="list-decimal list-inside pl-5">
                            {/*<li>В любой момент при результате пакета больше нуля.</li>
                            <i>
                                (ABI за 30 дней = ${data.abi_30}, 50 BI для расчёта = ${data.abi_30 * 50})
                            </i>
                            <li>Дистанция в 200 турниров, 15 дней со дня старта пакета.</li>*/}
                        </ul>
                        Ниже необходимо прикрепить ссылки на скриншоты.
                        <p className="text-red-500 font-semibold text-opacity-80">
                            На скринах должно быть видно баланс в кассе и историю последних транзакций.
                        </p>
                    </DialogContentText>
                    {props.rooms?.map((val, i) => {
                        return (
                            <React.Fragment key={val.account_record.id}>
                                <div hidden>
                                    <Controller
                                        name={`rooms.${i}.account_record_id`}
                                        control={control}
                                        defaultValue={val.account_record.id}
                                        render={() => <div />}
                                    />
                                </div>
                                <Controller
                                    name={`rooms.${i}.screenshot`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className="mb-4 w-full"
                                            label={`${val.account_record_name}, ${val.room.name} ${
                                                twoScreenshotsRooms.includes(val.room.id) ? '| скрин клиента, где видно кассу' : ''
                                            }`}
                                            // label={`${val.account_record_name}, ${val.room.name}`}
                                            variant="outlined"
                                            error={!!errors.rooms && !!errors.rooms[i]?.screenshot?.message}
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                />
                                <div hidden={!twoScreenshotsRooms.includes(val.room.id)}>
                                    <Controller
                                        name={`rooms.${i}.screenshot2`}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className="mb-4 w-full"
                                                label={`${val.account_record_name}, ${val.room.name} | скрин истории кешаутов/депозитов с сайта`}
                                                variant="outlined"
                                                error={!!errors.rooms && !!errors.rooms[i]?.screenshot2?.message}
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" disabled={mutation.isLoading} onClick={closeDialog}>
                        Отмена
                    </Button>
                    <Button color="primary" loading={mutation.isLoading} type="submit">
                        Подтвердить
                    </Button>
                </DialogActions>
            </form>
        </>
    );
};

export default RequestPayout;
