import axios from 'axios_template';

import { PlayerAccount } from 'types/backend/PlayerAccount';

export const getPlayerAccounts = async () => {
    const { data } = await axios.get<PlayerAccount[]>('/api/users/accounts/user/');

    return data.map(val => ({ id: val.room.id, name: val.room.name }));
};

export const getHistory = async ({ queryKey }) => {
    const [, rooms, cashflow, transfers, period] = queryKey;

    const { data } = await axios.post<{
        room: string;
        currency: string;
        type: string;
        amount: number;
        date: string;
    }>('/api/accounting/accountinghistory/', {
        rooms, cashflow, transfers, period
    });

    return data;
};
