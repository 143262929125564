export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SET_H2N_HANDS = "SET_H2N_HANDS";
export const SET_IGNORE_H2N_HANDS = "SET_IGNORE_H2N_HANDS";
export const SET_GG_HANDS = "SET_GG_HANDS";
export const SET_IGNORE_GG_HANDS = "SET_IGNORE_GG_HANDS";
export const SET_GG_SUMMARIES = "SET_GG_SUMMARIES";
export const SET_IGNORE_GG_SUMMARIES = "SET_IGNORE_GG_SUMMARIES";
export const SET_GG_SCREENS = "SET_GG_SCREENS";
export const SET_IGNORE_GG_SCREENS = "SET_IGNORE_GG_SCREENS";
export const RESET_STATE = "RESET_STATE";

interface HandsUploadState {
    uploadProgress: number;
    h2nHands: any;
    ignoreH2NHands: boolean;
    ggHands: any;
    ignoreGGHands: boolean;
    ggSummaries: any;
    ignoreGGSummaries: boolean;
    ggScreens: any;
    ignoreGGScreens: boolean;
}

export const initialState: HandsUploadState = {
    uploadProgress: 0,
    h2nHands: null,
    ignoreH2NHands: false,
    ggHands: null,
    ignoreGGHands: false,
    ggSummaries: null,
    ignoreGGSummaries: false,
    ggScreens: null,
    ignoreGGScreens: false,
};

export type Action =
    | { type: typeof SET_UPLOAD_PROGRESS, payload: number }
    | { type: typeof SET_H2N_HANDS, payload: any }
    | { type: typeof SET_IGNORE_H2N_HANDS, payload: boolean }
    | { type: typeof SET_GG_HANDS, payload: any }
    | { type: typeof SET_IGNORE_GG_HANDS, payload: boolean }
    | { type: typeof SET_GG_SUMMARIES, payload: any }
    | { type: typeof SET_IGNORE_GG_SUMMARIES, payload: boolean }
    | { type: typeof SET_GG_SCREENS, payload: any }
    | { type: typeof SET_IGNORE_GG_SCREENS, payload: boolean }
    | { type: typeof RESET_STATE };

export const filterReducer = (state: HandsUploadState, action: Action): HandsUploadState => {
    switch (action.type) {
        case SET_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: action.payload };
        case SET_H2N_HANDS:
            return { ...state, h2nHands: action.payload };
        case SET_IGNORE_H2N_HANDS:
            return { ...state, ignoreH2NHands: action.payload };
        case SET_GG_HANDS:
            return { ...state, ggHands: action.payload };
        case SET_IGNORE_GG_HANDS:
            return { ...state, ignoreGGHands: action.payload };
        case SET_GG_SUMMARIES:
            return { ...state, ggSummaries: action.payload };
        case SET_IGNORE_GG_SUMMARIES:
            return { ...state, ignoreGGSummaries: action.payload };
        case SET_GG_SCREENS:
            return { ...state, ggScreens: action.payload };
        case SET_IGNORE_GG_SCREENS:
            return { ...state, ignoreGGScreens: action.payload };
        case RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
