import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';

import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import { createCashflow, getMaxRoom } from 'api/rooms';
import { RoomCard } from 'types/backend/RoomCard';

export interface CashflowProps {
    room: RoomCard;
    onClose: () => void;
}

const Cashflow: React.FC<CashflowProps> = props => {
    const queryClient = useQueryClient();

    const maxRoomQuery = useQuery(['maxroom', props.room?.account_record.id], getMaxRoom, {
        enabled: !!props.room,
        initialData: 0,
    });

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(
            yup.object().shape({
                type: yup.string().required(),
                comment: yup.string().required('Скриншот обязательное поле').min(5),
                amount: yup
                    .number()
                    .required()
                    .min(0.01, 'Должно быть положительное число')
                    .when(['type'], {
                        is: (type: string) => type === 'deposit' || type === 'cashback',
                        then: yup.number(),
                        otherwise: yup.number().max(maxRoomQuery.data, 'Должно быть не больше баланса'),
                    }),
                change_balance: yup.boolean(),
            })
        ),
    });

    const mutation = useMutation(createCashflow, {
        onSuccess: () => {
            queryClient.invalidateQueries('dashboardrooms');
            queryClient.invalidateQueries('contract');
            queryClient.invalidateQueries('lastcontract');
            queryClient.invalidateQueries('contracttournaments');
            queryClient.invalidateQueries('bundle');
            queryClient.invalidateQueries('bundletournaments');
            queryClient.invalidateQueries('personalfunds');
            queryClient.invalidateQueries('cashflow');

            closeDialog();
        },
    });

    const onSubmit = handleSubmit(data => mutation.mutate({ account_record: props.room.account_record.id, ...data } as any));

    const closeDialog = () => {
        reset({
            type: '',
            amount: 0,
            comment: '',
            change_balance: true,
        });

        props.onClose();
    };

    const setAmount = () => {
        setValue('amount', maxRoomQuery.data);
        setValue('type', 'cashout');
    };

    return (
        <>
            <DialogTitle>Личные средства {props.room?.room.name}</DialogTitle>
            <form onSubmit={onSubmit} noValidate autoComplete="off">
                <DialogContent>
                    <Controller
                        name="type"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <FormControl variant="outlined" className="mb-4 w-full">
                                <InputLabel id="type-label">Тип</InputLabel>
                                <Select labelId="type-label" label="Тип" required {...field}>
                                    <MenuItem value="cashback">Кэшбэк</MenuItem>
                                    <MenuItem value="deposit">Депозит/Входящий перевод</MenuItem>
                                    <MenuItem value="cashout">Кэшаут/Исходящий перевод</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="amount"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                            <TextField
                                error={!!errors.amount?.message}
                                helperText={errors.amount?.message}
                                label="Сумма"
                                type="number"
                                variant="outlined"
                                className="mb-4 w-full"
                                {...field}
                            />
                        )}
                    />
                    <DialogContentText className="mb-8 text-sm">
                        Доступно максимально для вывода:{' '}
                        <button type="button" onClick={setAmount} className="text-blue-600 hover:text-blue-500 inline">
                            {maxRoomQuery.data}
                        </button>
                    </DialogContentText>
                    <Controller
                        name="comment"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                error={!!errors.comment?.message}
                                helperText={errors.comment?.message}
                                label="Скриншот перевода"
                                type="url"
                                variant="outlined"
                                className="mb-2 w-full"
                                required
                                {...field}
                            />
                        )}
                    />
                    <DialogContentText className="mb-8 text-sm">
                        Скриншот должен содержать данные об остатках и пополнении/выводе&nbsp; (
                        {/*<a href="https://prnt.sc/10mw2j8" target="_blank" rel="noreferrer" className="text-blue-600 hover:text-blue-500">
                            пример-bonus
                        </a>
                        ,&nbsp;*/}
                        <a href="https://prnt.sc/zbgo0c" target="_blank" rel="noreferrer" className="text-blue-600 hover:text-blue-500">
                            пример-dep
                        </a>
                        )
                    </DialogContentText>
                    <Controller
                        name="change_balance"
                        control={control}
                        defaultValue={true}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Изменить баланс"
                                control={
                                    <Checkbox
                                        {...field}
                                        disabled={props.room.virtual}
                                        checked={!!field.value}
                                        onChange={({ target }) => field.onChange(target.checked)}
                                    />
                                }
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" disabled={mutation.isLoading} onClick={closeDialog}>
                        Отмена
                    </Button>
                    <Button color="primary" loading={mutation.isLoading} type="submit">
                        Подтвердить
                    </Button>
                </DialogActions>
            </form>
        </>
    );
};

export default Cashflow;
