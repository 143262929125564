import { Navigate } from 'react-router-dom';

import Dashboard from 'layouts/Dashboard/Dashboard';

import Login from 'pages/Login/Login';
import Home from 'pages/Home/Home';
import Leaderboard from 'pages/Leaderboard/Leaderboard';
import Team from 'pages/Team/Team';
import Tournaments from 'pages/Tournaments/Tournaments';
import Documents from 'pages/Documents/Documents';
import History from 'pages/History/History';
import Video from 'pages/Video/Video';
import NotFound from 'pages/NotFound/NotFound';

import Students from 'pages/Students/Students';

const conditionalRedirect = (shouldRedirect: boolean, path: string, element: React.ReactElement) => {
    return shouldRedirect ? <Navigate to={path} /> : element;
};

const routes = (isLoggedIn: boolean, permissions: string[]) => [
    {
        path: '/login',
        element: conditionalRedirect(isLoggedIn, '/', <Login />),
    },
    {
        path: '/',
        element: conditionalRedirect(!isLoggedIn, 'login', <Dashboard />),
        children: [
            { path: '/', element: <Home />, permission: '17f7226e-0d33-4e82-bfc2-5b0d2fa145c2' },
            { path: '/history', element: <History />, permission: '1b354bce-a9ed-4ee7-b63e-11fb93390e32' },
            { path: '/leaderboard', element: <Leaderboard />, permission: 'd2b5c61f-02b5-4212-b018-25ea664f6898' },
            { path: '/team', element: <Team />, permission: '136a566e-3eb3-41ad-ad32-777dd8a7e592' },
            { path: '/tournaments', element: <Tournaments />, permission: '8e7d0e5f-0c05-4c26-9053-311309158c73' },
            { path: '/documents', element: <Documents />, permission: '8dda79e2-57e4-4225-bc64-948839abac9d' },
            {
                path: '/video',
                element: <Video />,
                permission: '673a6475-133c-47cb-a670-a4a5f8500722',
            },
            { path: '/students', element: <Students />, permission: 'c3b8eb57-7e64-4881-a25a-6cb4c5665aaf' },
            { path: '/player', element: <Students isStudent />, permission: 'f8d01948-e264-465e-9283-239b2062a425' },
        ].filter(val => permissions.includes(val.permission)),
    },
    { path: '*', element: <NotFound /> },
];

export default routes;
