import { getRoomLogoUrl } from 'helpers/room-logo';

const RoomLogo: any = (props) => {
    const container = document.createElement('div');
    const logo = document.createElement('img');

    logo.setAttribute('src', getRoomLogoUrl(props.value));
    //logo.setAttribute('alt', props.value);

    logo.style.height = '80%';

    container.classList.add('h-full', 'flex', 'items-center');
    //container.setAttribute('title', props.value);

    container.appendChild(logo);

    return container;
};

export default RoomLogo;
