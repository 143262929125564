import { UserInfo } from 'types/backend/UserInfo';
import { JWTToken } from 'types/JWTToken';

export const AUTHENTICATE = 'AUTH/AUTHENTICATE';
export const LOGIN = 'AUTH/LOGIN';
export const LOGOUT = 'AUTH/LOGOUT';
export const SET_LOADING = 'AUTH/SET_LOADING';
export const SET_PERMISSIONS = 'AUTH/SET_PERMISSIONS';
export const SET_PROFILE = 'AUTH/SET_PROFILE';

export interface Authenticate { type: typeof AUTHENTICATE; payload: JWTToken; }
export interface Logout { type: typeof LOGOUT; }
export interface Login { type: typeof LOGIN; payload: { username: string, password: string } }
export interface SetLoading { type: typeof SET_LOADING; payload: boolean; }
export interface SetPermissions { type: typeof SET_PERMISSIONS; payload: string[]; }
export interface SetProfile { type: typeof SET_PROFILE; payload: UserInfo; }

interface authState {
    access: string;
    refresh: string;
    loading: boolean;
    form: boolean;
    permissions: string[];
    profile: UserInfo;
}

type authActionTypes = Authenticate | Logout | SetLoading | Login | SetPermissions | SetProfile;

export const authenticate = (payload: JWTToken) => ({ type: AUTHENTICATE, payload });
export const login = (payload: { username: string, password: string }) => ({ type: LOGIN, payload });
export const logout = () => ({ type: LOGOUT });
export const setLoading = (payload: boolean) => ({ type: SET_LOADING, payload });
export const setPermissions = (payload: string[]) => ({ type: SET_PERMISSIONS, payload });
export const setProfile = (payload: UserInfo) => ({ type: SET_PROFILE, payload });

const initialState: authState = {
    access: '',
    refresh: '',
    loading: false,
    form: false,
    permissions: [],
    profile: null
};

const auth = (state: authState = initialState, action: authActionTypes): authState => {
    switch (action.type) {
        case AUTHENTICATE:
            return { ...state, ...action.payload, loading: false };
        case LOGIN:
            return { ...state, loading: true };
        case LOGOUT:
            return { ...state, access: '', refresh: '' };
        case SET_LOADING:
            return { ...state, loading: action.payload };
        case SET_PERMISSIONS:
            return { ...state, permissions: action.payload };
        case SET_PROFILE:
            return { ...state, profile: action.payload };
        default:
            return state;
    }
};

export default auth;
