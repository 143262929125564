import { FC } from 'react';

import { IconButton, DialogTitle, DialogContent, TextField, Typography } from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';

import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createReview } from 'api/review';

const schema = yup.object().shape({
    review: yup.string().required().max(1000),
});

type Props = {
    close: () => void;
};

const ReviewDialog: FC<Props> = ({ ...props }) => {
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            review: '',
        },
    });

    const mutation = useMutation(createReview, {
        onSuccess: () => {
            props.close();
        },
    });

    const onSubmit = handleSubmit(data => mutation.mutate(data));

    return (
        <>
            <DialogTitle className="font-roboto">
                Отзыв о работе фонда
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className="flex flex-col gap-4 pt-2" onSubmit={onSubmit}>
                    <Typography variant="body1" className="pb-2 font-roboto">
                        Здесь вы можете оставить отзыв о работе фонда. Он поможет нам стать лучше. Все отзывы анонимны, мы не видим от кого они пришли.
                    </Typography>
                    <Controller control={control} name="review" render={({ field }) => <TextField {...field} label="Отзыв" rows={5} multiline fullWidth />} />

                    <div className="pt-4">
                        <Button fullWidth variant="contained" size="large" type="submit" loading={mutation.isLoading}>
                            Отправить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default ReviewDialog;
