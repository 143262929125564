import axios from 'axios_template';

export const requestPayouts = (body: {
    id: number;
    screenshot: string;
}[]) => {
    return axios.post('/api/accounting/payoutrequestsv2/', body);
};

// interface requestExists {
//     exists: boolean;
// }

// export const requestPayoutExists = async () => {
//     const { data } = await axios.get<requestExists>('/api/accounting/payoutrequests/exists/');
//
//     return data;
// };
