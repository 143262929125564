import Axios from 'axios';
import { QueryClient } from 'react-query';
import { toast } from 'react-toastify';

// import { auth_authenticate, auth_logout } from 'store/auth/auth';
import { authenticate, logout } from 'store/ducks/auth/auth';

const blobAxios = Axios.create({
    baseURL: 'https://api.kingsoftournaments.com/',
    responseType: 'blob',
});

export const setupBlobInterceptors = (store: any, queryClient: QueryClient) => {
    blobAxios.interceptors.request.use(config => {
        if (config.headers?.Authorization) return config;

        const { access } = store.getState().auth;

        if (!access) return config;

        return {
            ...config,
            headers: { ...config.headers, Authorization: `ApiToken ${access}` },
        };
    });

    blobAxios.interceptors.response.use(
        res => {
            if (res?.status === 200 && res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.count) {
                const data = { count: res?.data?.count, data: res?.data.data };
                return { ...res, data: data };
            }
            if (res?.status === 200 && res?.data?.data !== null && res?.data?.data !== undefined) return { ...res, data: res?.data.data };

            return res;
        },
        async err => {
            const { refresh, access } = store.getState().auth;

            if (err.response.status === 401 && access && err.config.url === '/api/token/refresh/') {
                queryClient.cancelQueries();
                queryClient.clear();
                queryClient.invalidateQueries();

                store.dispatch(logout());

                return Promise.reject(err);
            }

            if (err.response.status !== 401 || !refresh) {
                if (err.response.data.detail !== 'Given token not valid for any token type') {
                    toast.error(err.response.data.detail || err.response.data.error || 'Неизвестная ошибка');
                }

                return Promise.reject(err);
            }

            try {
                const { data } = await blobAxios.post('/api/token/refresh/', { refresh });

                store.dispatch(authenticate({ ...data, refresh }));

                err.config.headers['Authorization'] = data.access;

                return blobAxios.request(err.config);
            } catch {
                return Promise.reject(err);
            }
        }
    );
};

export default blobAxios;
