import React from 'react';
import Divider from '@mui/material/Divider';

const Documents: React.FC = () => {
    return (
        <main className="lg:mt-0 mt-3">
            <a
                href="https://youtu.be/law2V1a_nAw"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-400"
                target="_blank"
            >
                Презентация команды КОТ
            </a>
            <Divider className="my-4" />
            <a
                href="https://docs.google.com/spreadsheets/d/1yjjZL8pfTpnm3wyqw0vcLPeEW4rtqGgRw8Bu_Bih4w8/edit?pli=1#gid=1730112052"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-400"
                target="_blank"
            >
                Внутренние документы
            </a>
            <Divider className="my-4" />
            <a href="https://youtu.be/ybtOiRh-Yi0" rel="noreferrer" className="text-blue-600 hover:text-blue-400" target="_blank">
                Видео: Старт сотрудничества
            </a>
            <Divider className="my-4" />
            <a
                href="https://docs.google.com/document/d/1hfq_5BnOVztmEMwm4q_oMsIx8gudYl1TKA-BRyLvVy4/edit?usp=sharing"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-400"
                target="_blank"
            >
                Договор
            </a>
            <Divider className="my-4" />
            <a
                href="https://docs.google.com/document/d/1OG0sk3wpn2j9S-eO_Ep0Fo2vIknmLROQqrZw1do7qGo/edit?usp=sharing"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-400"
                target="_blank"
            >
                FAQ
            </a>
        </main>
    );
};

export default Documents;
