import { FC, Dispatch, SetStateAction } from 'react';
import moment from 'moment';

import { IconButton, CircularProgress } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { deleteNote, getStudentNotes } from 'api/curator';

type Props = {
    /**
     * Массив выбранных игроков.
     */
    players: { id: number; username: string }[];

    /**
     * Кол-во записей на странице.
     */
    perPage: number;

    /**
     * Номер текущей страницы.
     */
    page: number;

    /**
     * Функция выбора элемента для редактирования.
     */
    setItem: (value) => void;

    /**
     * Функция записывающее общее число записей.
     */
    setCount: Dispatch<SetStateAction<number>>;
};

const NotesBody: FC<Props> = ({ players, perPage, page, setItem, setCount }) => {
    const queryClient = useQueryClient();

    const { data: item, isLoading } = useQuery(['notes', players, perPage, page], getStudentNotes, {
        onSuccess: data => setCount(data.count),
    });

    const { mutate } = useMutation(deleteNote, {
        onSuccess: () => {
            queryClient.invalidateQueries('notes');
            queryClient.invalidateQueries('student-notes');
        },
    });

    if (isLoading)
        return (
            <div className='w-full flex items-center justify-center'>
                <CircularProgress />
            </div>
        );

    return (
        <div className='w-full grid 2xl:grid-cols-4 lg:grid-cols-2 gap-4'>
            {item?.data?.map(function (val, index) {
                return (
                    <div
                        key={val.id}
                        className='rounded p-3 flex flex-col shadow-md overflow-hidden bg-white border-2'
                        style={{ borderColor: val.note_type?.color || '#bbb' }}
                    >
                        <div className='w-full text-base flex items-center justify-between'>
                            <div className='leading-none'>
                                <p className='text-sm font-semibold'>{val.username}</p>
                                <p className='font-normal text-sm leading-none'>Автор: {val.created_by}</p>
                                <p className='font-normal text-sm leading-none'>{moment(val.created_datetime).format('DD.MM.YYYY')}</p>
                            </div>
                            <div className='flex space-x-2'>
                                {index === 0 ? (
                                    <IconButton className='bg-white' onClick={() => setItem(val)}>
                                        <BorderColorIcon fontSize='small' />
                                    </IconButton>
                                ) : null}

                                <IconButton className='bg-white' onClick={() => mutate(val.id)}>
                                    <DeleteIcon fontSize='small' />
                                </IconButton>
                            </div>
                        </div>
                        <div className='w-full mt-5 bg-gray-100 p-2 flex-1 rounded'>
                            <p className='text-base w-full overflow-hidden dont-break-out'>{val.text}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default NotesBody;
