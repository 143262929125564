import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import authReducer from './ducks/auth/auth';
import appReducer from './ducks/app/app';
import tournamentsReducer from './ducks/tournaments/tournaments';

import sagaWatchers from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const STATE_KEY = 'STATEv5';

const composeEnhancers =
    (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reduxState =
    process.env.NODE_ENV === 'production'
        ? applyMiddleware(sagaMiddleware)
        : composeEnhancers(applyMiddleware(...middleware));

const reducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    tournaments: tournamentsReducer
});

export type AppState = ReturnType<typeof reducer>;

const persistState = (state: AppState) => {
    try {
        const jsonState = JSON.stringify(state);

        localStorage.setItem(STATE_KEY, jsonState);
    } catch (e) {
        console.error(e);
    }
};

const loadState = () => {
    try {
        const jsonState = localStorage.getItem(STATE_KEY);

        return jsonState ? JSON.parse(jsonState) : undefined;
    } catch (e) {
        console.error(e);
    }
};

const store = createStore(reducer, loadState(), reduxState);

store.subscribe(() => persistState(store.getState()));

sagaWatchers.forEach(watcher => sagaMiddleware.run(watcher));

export default store;
