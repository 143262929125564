import { FC, useEffect, useState } from 'react';

import Header from 'components/Header/Header';
import HomeworkFilter from './HomeworkFilter';

import { Button, Dialog, useMediaQuery } from '@mui/material';
import theme from 'themes/mainTheme';
import Pagination from 'components/Pagination/Pagination';
import HomeworkDialog from '../HomeworkDialog/HomeworkDialog';
import HomeworkBody from './HomeworkBody';
import Messages from '../Messages/Messages';
import useLocalStorage from 'hooks/useLocalStorage';

interface HomeworkProps {
    /**
     * массив выбранных игроков.
     */
    players: { id: number; username: string }[];

    /**
     * ИД авторизованного пользователя.
     */
    authId?: number;

    /**
     * Функция возврата.
     */
    back: () => void;

    /**
     * Флаг запрета действий, при множественном выборе игроков.
     */
    hideWhenMutliple?: boolean;
}

const Homework: FC<HomeworkProps> = ({ players, authId, hideWhenMutliple, back }) => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [item, setItem] = useState(null);
    const [messageItem, setMessageItem] = useState(null);
    const [data, setLocalData] = useLocalStorage(
        "homework_filter",
        [
            {
                "tag": "isClosed",
                "value": "false"
            }
        ],
      );

    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (item) setOpen(true);
    }, [item]);

    useEffect(() => {
        if (!open) setTimeout(() => setItem(null), 100);
    }, [open]);

    if (messageItem) return <Messages id={messageItem.id} authId={authId} player={messageItem.player} back={() => setMessageItem(null)} />;

    return (
        <div className='w-full'>
            <Header back={back} title='Домашние Задания'>
                {players.length ? `${players.map(val => val.username).join(', ')}: ` : ''}
            </Header>
            <HomeworkFilter setFilter={setLocalData} resetPage={() => setPage(1)} data={data} />
            <div className='w-full flex items-center mb-3 md:flex-row s:flex-col-reverse items-end'>
                {count !== undefined && (
                    <Pagination
                        current={page}
                        range={perPage}
                        onChangePage={newPage => setPage(newPage)}
                        onChangeRange={newRange => setPerPage(newRange)}
                        total={count}
                    />
                )}

                {(hideWhenMutliple && players.length > 1) || authId ? null : (
                    <div className='flex w-full md:justify-end s:justify-start mt-4'>
                        <Button variant='contained' size='large' onClick={() => setOpen(true)}>
                            Добавить
                        </Button>
                    </div>
                )}
            </div>
            <HomeworkBody
                players={players}
                authId={authId}
                perPage={perPage}
                page={page}
                filter={data}
                setMessageItem={setMessageItem}
                setItem={setItem}
                setCount={setCount}
            />
            <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
                <HomeworkDialog item={item} playerIds={players.map(val => val.id)} players={players} close={() => setOpen(false)} />
            </Dialog>
        </div>
    );
};

export default Homework;
