import { FC } from 'react';

import { Typography } from '@mui/material';

/**
 * Свойства компонента.
 */
type Props = {
    /**
     * Общее количество элементов в списке.
     */
    count: number;

    /**
     * Номер текущей страницы (отсчёт начинается с единицы).
     */
    current: number;
};

/**
 * Отображает кол-во элементов пагинации.
 */
const PaginationRange: FC<Props> = ({ count, current }: Props) => {
    return (
        <Typography variant='body2' className='ml2 mr2'>
            {`${current} из ${count}`}
        </Typography>
    );
};

export default PaginationRange;
