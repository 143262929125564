import axios from 'axios_template';

// import { DepositRequest } from 'types/backend/Deposit';
import { DepositList } from 'types/backend/DepositList';

export const getDepositRequests = async () => {
    // const { data } = await axios.get<Deposit[]>('/api/accounting/deposits/');
    const { data } = await axios.get<{
        id: number;
        room_name: string;
        room_short_name: string;
        account_name: string;
        amount: number;
        comment: string;
        processed_datetime: string;
        created_datetime: string;
    }[]>('/api/accounting/depositrequests/');

    return data;
};

export const deleteDepositRequest = async (id: number) => {
    // return axios.delete(`/api/accounting/deposits/${id}/`);
    return axios.delete(`/api/accounting/depositrequests/${id}/`);
};

export const getDepositsListRequest = async() => {
    // const { data } = await axios.get<DepositList[]>('/api/accounting/deposits/tableau/');
    const { data } = await axios.get<DepositList[]>('/api/accounting/depositrequests/tableau/');

    return data;
}

export const reserveDeposit = async (body: { deposit_id: number }) => {
    // return axios.post(`/api/accounting/deposits/tableau/`, body);
    return axios.post(`/api/accounting/depositrequests/tableau/`, body);
};
