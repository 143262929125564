import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import StepContent from '@mui/material/StepContent';
// import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';

import { useForm, Controller } from 'react-hook-form';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import { getResidencies, getCountries, saveForm } from 'api/form';

export interface UserFormProps {
    accounts: {
        room_id: number;
        room_name: string;
        room_account_id: number;
        room_account_name: string;
        account_record_id: number;
        description: string;
        email: boolean; // used for any relevant info such as ewallet address (login, email, crypto address etc)
        email_field_name: string;
    }[];
}

const questions = [
    'Расскажите о себе: хобби, работа, учеба.',
    'Опыт в покере: сколько играете и каких достигли результатов?',
    'Краткосрочная и долгосрочная цели в покере.',
    'Ожидаемый доход от покера через пол года.',
    'Что вам нравится и что не нравилось в предыдущих тренировках?',
    'Ваши сильные и слабые стороны в покере.',
    'Сколько времени уделяете покеру и работе над ним?',
    'С каким покерным софтом вы знакомы и насколько хорошо?',
    'В какое время предпочитаете играть?',
    'Есть ли что-то, что вы хотели бы добавить или сообщить?',
];

const UserForm: React.FC<UserFormProps> = ({ accounts }) => {
    const queryClient = useQueryClient();

    const [step, setStep] = React.useState(0);

    const {
        handleSubmit,
        watch,
        trigger,
        control,
        formState: { errors },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(
            yup.object().shape({
                birthday: yup.date().required(),
                telegram: yup.string(), // temp disabled telegram field
                // country_id: yup.number().required(),
                residence_country_id: yup.number().required(),
                // city_id: yup.mixed().required(),
                no_skrill: yup.boolean().required(),
                skrill_usd: yup.string().email().when('no_skrill', {
                    is: false,
                    then: yup.string().required(),
                }),
                skrill_eur: yup.string().email().when('no_skrill', {
                    is: false,
                    then: yup.string().required(),
                }),
                skrill_residence_id: yup.string().when('no_skrill', {
                    is: false,
                    then: yup.string().required(),
                }),
                rooms: yup.array().of(
                    yup.object().shape({
                        room_id: yup.number(),
                        room_account_id: yup.number(),
                        account_record_id: yup.number(),
                        hasEmail: yup.boolean(),
                        room_account_name: yup.string(),
                        email: yup.string().when(['hasEmail', 'room_account_name'], {
                            is: (hasEmail: boolean, room_account_name: string) => hasEmail && room_account_name,
                            then: yup.string().required().min(5),
                            otherwise: yup.string(),
                        }),
                        balance: yup.number().when(['room_account_name'], {
                            is: (room_account_name: string) => !!room_account_name,
                            then: yup.number().required().min(0),
                            otherwise: yup.number(),
                        }),
                        screenshot: yup.string().when(['room_account_name'], {
                            is: (room_account_name: string) => !!room_account_name,
                            then: yup.string().url().required(),
                            otherwise: yup.string().url(),
                        }),
                    })
                ),
                questions: yup.array().of(
                    yup.object().shape({
                        question: yup.string(),
                        answer: yup.string().required('Ответ обязателен'),
                    })
                ),
            })
        ),
        defaultValues: {
            birthday: '',
            telegram: '',
            residence_country_id: '',
            // city_id: '',
            no_skrill: false,
            skrill_usd: '',
            skrill_eur: '',
            skrill_residence_id: '',
            rooms: [],
            questions: questions.map(question => ({ question, answer: '' })),
        },
    });

    const countriesQuery = useQuery('countries', getCountries);
    // const citiesQuery = useQuery(['cities', watch('residence_country_id')], getCities, { enabled: !!watch('residence_country_id') });
    const skrillResidenciesQuery = useQuery('region_residence', getResidencies);

    const saveFormMutation = useMutation(saveForm, {
        onSuccess: () => queryClient.invalidateQueries('form'),
    });

    const onSubmit = handleSubmit((data: any) => {
        // const a = {
        //     ...data,
        //     birthday: moment(data.birthday).format('YYYY-MM-DD'),
        //     city_id: data.city_id.id,
        //     rooms: data.rooms.filter(val => val.room_account_name)
        // }
        // console.log(a);
        saveFormMutation.mutate({
            ...data,
            birthday: moment(data.birthday).format('YYYY-MM-DD'),
            // city_id: data.city_id.id,
            rooms: data.rooms.filter(val => val.room_account_name),
            survey: data.questions,
        });
    });

    const onChangeStep = async (direction: number) => {
        let fields = [];
        let hasErrors = false;

        const newStep = step + direction;

        if (newStep === 1 && direction > 0) fields = ['birthday', 'residence_country_id'];
        else if (newStep === 2 && direction > 0) fields = ['skrill_usd', 'skrill_eur', 'skrill_residence_id'];

        if (fields.length) {
            await trigger(fields);

            hasErrors = fields.reduceRight((acc, key) => acc || !!errors[key]?.message, false);
        }

        if (!hasErrors) setStep(newStep);
    };

    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <div className="w-full lg:h-auto h-full bg-white lg:rounded-lg lg:shadow-main max-h-screen flex flex-col" style={{ maxWidth: 800 }}>
                <p className="mb-2 text-lg font-medium text-gray-700 mt-8 ml-6">Анкета нового игрока</p>
                <form className="flex-1 overflow-hidden flex flex-col ml-2" noValidate autoComplete="off" onSubmit={onSubmit}>
                    <div className="flex-1 overflow-y-auto">
                        <Stepper activeStep={step} orientation="vertical">
                            <Step>
                                <StepLabel>Персональные данные</StepLabel>
                                <StepContent>
                                    <div>
                                        <div className="flex lg:flex-row flex-col mb-4">
                                            <div className="lg:w-1/2 lg:pr-2 lg:mb-0 w-full mb-4">
                                                <Controller
                                                    name="birthday"
                                                    control={control}
                                                    defaultValue={null}
                                                    render={({ field: { ref: _, ...rest } }) => (
                                                        <DatePicker
                                                            inputFormat="DD.MM.YYYY"
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    error={!!errors.birthday?.message}
                                                                    label="Дата Рождения"
                                                                    variant="outlined"
                                                                    helperText="Формат dd.mm.YYYY"
                                                                    required
                                                                    fullWidth
                                                                />
                                                            )}
                                                            {...rest}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex lg:flex-row flex-col">
                                            <div className="lg:w-1/2 lg:pr-2 lg:mb-0 w-full mb-4">
                                                <Controller
                                                    name="residence_country_id"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <FormControl error={!!errors.residence_country_id?.message} variant="outlined" fullWidth required>
                                                            <InputLabel id="country-select-label">Текущая cтрана</InputLabel>
                                                            <Select labelId="country-select-label" label="Текущая cтрана" required {...field}>
                                                                {(countriesQuery.data || []).map(val => (
                                                                    <MenuItem key={val.id} value={val.id}>
                                                                        {val.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                />
                                            </div>
                                            {/*<div className="lg:w-1/2 lg:pд-2 w-full">
                                                <Controller
                                                    name="city_id"
                                                    control={control}
                                                    defaultValue={null}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            onChange={(_, data) => field.onChange(data)}
                                                            options={citiesQuery.data || []}
                                                            loading={citiesQuery.isLoading}
                                                            getOptionLabel={(option: any) => option.name || ''}
                                                            // getOptionSelected={(opt: any, val: any) => opt.id === val.id}
                                                            noOptionsText="Не найдено"
                                                            disableClearable
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Город"
                                                                    variant="outlined"
                                                                    error={!!errors.city_id?.message}
                                                                    required
                                                                />
                                                            )}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>*/}
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Кошельки Skrill</StepLabel>
                                <StepContent>
                                    <div>
                                        <p className="text-red-500 mb-4 font-medium">
                                            Обязательно выполнить все шаги{' '}
                                            <a
                                                href="https://docs.google.com/document/d/1ZWMsgu9Wxxdlxq_ZGUiZSt9nMVSUdHPTuyquZzrWx3E/edit"
                                                className="text-blue-600 hover:text-blue-500"
                                                target="blank"
                                                rel="noreferr"
                                            >
                                                инструкции
                                            </a>
                                        </p>
                                        <div className="flex lg:flex-row flex-col">
                                            <div className="lg:w-1/2 lg:pr-2 lg:mb-0 w-full mb-4">
                                                <Controller
                                                    name="skrill_usd"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            error={!!errors.skrill_usd?.message}
                                                            label="USD кошелек"
                                                            variant="outlined"
                                                            helperText="Почта для USD кошелька Skrill"
                                                            type="email"
                                                            fullWidth
                                                            required
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="lg:w-1/2 lg:pl-2 w-full">
                                                <Controller
                                                    name="skrill_eur"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            error={!!errors.skrill_eur?.message}
                                                            label="EUR кошелек"
                                                            variant="outlined"
                                                            helperText="Почта для EUR кошелька Skrill (чаще всего совпадает с почтой USD кошелька)"
                                                            type="email"
                                                            fullWidth
                                                            required
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex lg:flex-row flex-col">
                                            <div className="lg:w-1/2 lg:pr-2 lg:mb-0 w-full mb-4">
                                                <Controller
                                                    name="skrill_residence_id"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <FormControl error={!!errors.skrill_residence_id?.message} variant="outlined" fullWidth required>
                                                            <InputLabel id="country-select-label">Резидения Skrill</InputLabel>
                                                            <Select labelId="country-select-label" label="Резиденция Skrill" required {...field}>
                                                                {(skrillResidenciesQuery.data || []).map(val => (
                                                                    <MenuItem key={val.id} value={val.id}>
                                                                        {val.description}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex lg:flex-row flex-col">
                                            <Controller
                                                name="no_skrill"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        label="У меня нет Skrill и я не могу его создать"
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={!!field.value}
                                                                onChange={({ target }) => field.onChange(target.checked)}
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Никнеймы в румах</StepLabel>
                                <StepContent>
                                    <div>
                                        <p className="text-red-500 mb-4 font-medium">
                                            Обязательно включить опцию публичной статистики в каждом руме (
                                            <a
                                                href="https://ru.sharkscope.com/#Opt-In_ru.html"
                                                className="text-blue-600 hover:text-blue-500"
                                                target="blank"
                                                rel="noreferr"
                                            >
                                                инструкции
                                            </a>
                                            )
                                        </p>
                                        {
                                            accounts.map((val, i) => {
                                                const isLast = i + 1 === accounts.length;

                                                return (
                                                    <div key={val.room_account_id} className={isLast ? '' : 'mb-6'}>
                                                        <p className="text-gray-700 font-medium">{val.room_name}</p>
                                                        {val.description ? <p className="text-gray-700 font-light">{val.description}</p> : null}
                                                        <div className="flex lg:flex-row flex-col mt-2 mb-4">
                                                            <div hidden>
                                                                <Controller
                                                                    name={`rooms.${i}.room_id`}
                                                                    control={control}
                                                                    defaultValue={val.room_id}
                                                                    render={() => <div />}
                                                                />
                                                                <Controller
                                                                    name={`rooms.${i}.room_account_id`}
                                                                    control={control}
                                                                    defaultValue={val.room_account_id}
                                                                    render={() => <div />}
                                                                />
                                                                <Controller
                                                                    name={`rooms.${i}.account_record_id`}
                                                                    control={control}
                                                                    defaultValue={val.account_record_id}
                                                                    render={() => <div />}
                                                                />
                                                                <Controller
                                                                    name={`rooms.${i}.hasEmail`}
                                                                    control={control}
                                                                    defaultValue={val.email}
                                                                    render={() => <div />}
                                                                />
                                                            </div>
                                                            <div className={`${val.email ? 'lg:w-1/2 w-full lg:pr-2' : 'w-full'} lg:mb-0 mb-4`}>
                                                                <Controller
                                                                    name={`rooms.${i}.room_account_name`}
                                                                    control={control}
                                                                    defaultValue={val.room_account_name}
                                                                    render={({ field }) => (
                                                                        <TextField label="Никнейм" variant="outlined" fullWidth {...field} />
                                                                    )}
                                                                />
                                                            </div>
                                                            {val.email ? (
                                                                <div className="lg:w-1/2 lg:pl-2 w-full">
                                                                    <Controller
                                                                        name={`rooms.${i}.email`}
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                label={val.email_field_name}
                                                                                error={!!errors.rooms && !!errors.rooms[i]?.email?.message}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                required={!!watch(`rooms.${i}.room_account_name`)}
                                                                                {...field}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className="flex lg:flex-row flex-col">
                                                            <div className="lg:w-1/2 w-full lg:pr-2 lg:mb-0 mb-4">
                                                                <Controller
                                                                    name={`rooms.${i}.balance`}
                                                                    control={control}
                                                                    defaultValue={0}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            label="Баланс"
                                                                            error={!!errors.rooms && !!errors.rooms[i]?.balance?.message}
                                                                            variant="outlined"
                                                                            type="number"
                                                                            required={!!watch(`rooms.${i}.room_account_name`)}
                                                                            fullWidth
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="lg:w-1/2 lg:pl-2 w-full">
                                                                <Controller
                                                                    name={`rooms.${i}.screenshot`}
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            label="Скриншот кассы"
                                                                            error={!!errors.rooms && !!errors.rooms[i]?.screenshot?.message}
                                                                            variant="outlined"
                                                                            type="url"
                                                                            required={!!watch(`rooms.${i}.room_account_name`)}
                                                                            fullWidth
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            // rooms.map((val, i) => {
                                            //     const isLast = i + 1 === rooms.length;
                                            //
                                            //     return (
                                            //         <div key={val.id} className={isLast ? '' : 'mb-6'}>
                                            //             <p className="text-gray-700 font-medium">{val.name}</p>
                                            //             {val.description ? <p className="text-gray-700 font-light">{val.description}</p> : null}
                                            //             <div className="flex lg:flex-row flex-col mt-2 mb-4">
                                            //                 <div hidden>
                                            //                     <Controller
                                            //                         name={`rooms.${i}.id`}
                                            //                         control={control}
                                            //                         defaultValue={val.id}
                                            //                         render={() => (<div />)}
                                            //                     />
                                            //                     <Controller
                                            //                         name={`rooms.${i}.hasEmail`}
                                            //                         control={control}
                                            //                         defaultValue={val.email}
                                            //                         render={() => (<div />)}
                                            //                     />
                                            //                 </div>
                                            //                 <div className={`${val.email ? 'lg:w-1/2 w-full lg:pr-2' : 'w-full'} lg:mb-0 mb-4`}>
                                            //                     <Controller
                                            //                         name={`rooms.${i}.nickname`}
                                            //                         control={control}
                                            //                         defaultValue=""
                                            //                         render={({ field }) => (
                                            //                             <TextField
                                            //                                 label="Никнейм"
                                            //                                 variant="outlined"
                                            //                                 fullWidth
                                            //                                 {...field}
                                            //                             />
                                            //                         )}
                                            //                     />
                                            //                 </div>
                                            //                 {
                                            //                     val.email ? (
                                            //                         <div className="lg:w-1/2 lg:pl-2 w-full">
                                            //                             <Controller
                                            //                                 name={`rooms.${i}.email`}
                                            //                                 control={control}
                                            //                                 defaultValue=""
                                            //                                 render={({ field }) => (
                                            //                                     <TextField
                                            //                                         label="Email"
                                            //                                         error={!!errors.rooms && !!errors.rooms[i]?.email?.message}
                                            //                                         variant="outlined"
                                            //                                         fullWidth
                                            //                                         required={!!watch(`rooms.${i}.nickname`)}
                                            //                                         {...field}
                                            //                                     />
                                            //                                 )}
                                            //                             />
                                            //                         </div>
                                            //                     ) : null
                                            //                 }
                                            //             </div>
                                            //             <div className="flex lg:flex-row flex-col">
                                            //                 <div className="lg:w-1/2 w-full lg:pr-2 lg:mb-0 mb-4">
                                            //                     <Controller
                                            //                         name={`rooms.${i}.balance`}
                                            //                         control={control}
                                            //                         defaultValue={0}
                                            //                         render={({ field }) => (
                                            //                             <TextField
                                            //                                 label="Баланс"
                                            //                                 error={!!errors.rooms && !!errors.rooms[i]?.balance?.message}
                                            //                                 variant="outlined"
                                            //                                 type="number"
                                            //                                 required={!!watch(`rooms.${i}.nickname`)}
                                            //                                 fullWidth
                                            //                                 {...field}
                                            //                             />
                                            //                         )}
                                            //                     />
                                            //                 </div>
                                            //                 <div className="lg:w-1/2 lg:pl-2 w-full">
                                            //                     <Controller
                                            //                         name={`rooms.${i}.screenshot`}
                                            //                         control={control}
                                            //                         defaultValue=""
                                            //                         render={({ field }) => (
                                            //                             <TextField
                                            //                                 label="Скриншот кассы"
                                            //                                 error={!!errors.rooms && !!errors.rooms[i]?.screenshot?.message}
                                            //                                 variant="outlined"
                                            //                                 type="url"
                                            //                                 required={!!watch(`rooms.${i}.nickname`)}
                                            //                                 fullWidth
                                            //                                 {...field}
                                            //                             />
                                            //                         )}
                                            //                     />
                                            //                 </div>
                                            //             </div>
                                            //         </div>
                                            //     );
                                            // })
                                        }
                                    </div>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Знакомство. Основная информация.</StepLabel>
                                <StepContent>
                                    <div className="flex flex-col gap-6 mt-4">
                                        {questions.slice(0, 5).map((question, index) => (
                                            <div key={index}>
                                                <Controller
                                                    name={`questions[${index}].answer` as any}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => <TextField label={question} variant="outlined" fullWidth multiline {...field} />}
                                                />
                                                {errors.questions && errors.questions[index] && errors.questions[index].answer && (
                                                    <p style={{ color: 'red' }}>{errors.questions[index].answer.message}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Знакомство. Профессиональные навыки.</StepLabel>
                                <StepContent>
                                    <div className="flex flex-col gap-6 mt-4">
                                        {questions.slice(5, 10).map((question, index) => (
                                            <div key={index + 5}>
                                                <Controller
                                                    name={`questions[${index + 5}].answer` as any}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => <TextField label={question} variant="outlined" fullWidth multiline {...field} />}
                                                />
                                                {errors.questions && errors.questions[index + 5] && errors.questions[index + 5].answer && (
                                                    <p style={{ color: 'red' }}>{errors.questions[index + 5].answer.message}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </div>
                    <div className="flex justify-end p-8">
                        {step !== 0 ? (
                            <Button className="mr-4" disabled={saveFormMutation.isLoading} type="button" onClick={() => onChangeStep(-1)}>
                                Назад
                            </Button>
                        ) : null}
                        {step !== 4 ? (
                            <Button variant="contained" color="primary" type="button" onClick={() => onChangeStep(+1)}>
                                Дальше
                            </Button>
                        ) : null}
                        {step === 4 ? (
                            <Button variant="contained" loading={saveFormMutation.isLoading} color="primary" type="submit">
                                Отправить
                            </Button>
                        ) : null}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserForm;
