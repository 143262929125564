import { ComponentPropsWithoutRef, FC, MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';

import { ClickAwayListener, Popper, Card, Grow, Box, PopperPlacementType } from '@mui/material';

/**
 * Значения, передаваемые для отрисовки содержимого всплывающей области.
 */
type ContentOptions = {
    /**
     * Инициирует скрытие всплывающей области.
     */
    close: () => void;
};

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Box> & {
    /**
     * Отображает содержимое всплывающей области. Если это свойство установлено,
     * то значение свойства `content` будет проигнорировано.
     * @param options Параметры для отрисовки содержимого.
     */
    renderContent?: (options: ContentOptions) => ReactNode;

    /**
     * Содержимое всплывающей области. Если элементу присвоено свойство
     * `renderContent`, то значение `content` будет проигнорировано.
     */
    content?: ReactNode;

    /**
     * Обрабатывает изменение состояния видимости всплывающей области.
     * @param open Указывает, открыта ли всплывающая область.
     */
    onToggle?: (open: boolean) => void;

    /**
     * Обрабатывает событие скрытия всплывающей области.
     */
    onClose?: () => void;

    /**
     * Обрабатывает событие показа всплывающей области.
     */
    onOpen?: () => void;

    /**
     * Запрещает открытие или скрытие всплывающей области.
     */
    disabled?: boolean;

    /**
     * Указывает, показана ли всплывающая область в данный момент.
     */
    open?: boolean;

    /**
     * Позиция для открытия всплывающей области.
     */
    placement?: PopperPlacementType;
};

/**
 * Последний сгенерированный идентификатор всплывающей области.
 */
let lastId: number = 0;

/**
 * Генерирует уникальный идетификатор всплывающей области.
 */
function generateId() {
    lastId += 1;
    return `dropdown_${lastId}`;
}

/**
 * Отображает всплывающую область.
 */
const Dropdown: FC<Props> = ({ renderContent, disabled, content, onToggle, onClick, onClose, onOpen, open: outerOpen, placement = 'bottom-end', ...props }) => {
    const id = useMemo(generateId, []);

    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const [open, setOpen] = useState<boolean>(Boolean(outerOpen));
    useEffect(() => setOpen(Boolean(outerOpen)), [outerOpen]);

    function toggle(nextOpen: boolean) {
        if (nextOpen) {
            onOpen?.();
        } else {
            onClose?.();
        }

        onToggle?.(nextOpen);
    }

    function handleClick(event: MouseEvent<HTMLDivElement>) {
        if (disabled) {
            return;
        }

        toggle(true);
        onClick?.(event);

        if (outerOpen == null) {
            setOpen(true);
        }
    }

    function handleClose() {
        if (disabled) {
            return;
        }

        toggle(false);

        if (outerOpen == null) {
            setOpen(false);
        }
    }

    const contentOptions: ContentOptions = {
        close: handleClose,
    };

    return (
        <>
            <Box
                {...props}
                className='flex'
                onClick={handleClick}
                // @ts-ignore
                ref={setAnchor}
            />
            <Popper className='z-500' anchorEl={anchor} open={open} id={id} transition disablePortal placement={placement}>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }} timeout={0}>
                            <Card className='mt-1 min-w-150 max-h-[50vh] overflow-auto' elevation={5}>
                                {renderContent ? renderContent(contentOptions) : content}
                            </Card>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default Dropdown;
