import axios from 'axios_template';
import { maxBy } from 'lodash';

import { PlayerBundle } from 'types/backend/PlayerBundle';
import { PlayerContract } from 'types/backend/PlayerContract';

export const getContract = async () => {
    // const { data } = await axios.get<{
    //     result: number,
    //     alltime_result: number,
    //     currencies: { rate: number, value: number, name: string }[]
    // }>('/api/accounting/reports/contract/result/');
    const { data } = await axios.get<{
        result: number,
        alltime_result: number,
        currencies: { rate: number, value: number, name: string }[]
    }>('/api/accounting/reports/contractresult/');

    return {
        result: data.result.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        alltime_result: data.alltime_result.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        currencies: data.currencies.map(val =>
            `${val.value.toLocaleString('en-US', { style: 'currency', currency: val.name })}${val.name !== 'USD' ? ` (${val.rate})` : ''}`
        ).join(', ')
    };
};

export const getLastContract = async (): Promise<PlayerContract & { last_bundle: PlayerBundle }> => {
    const { data } = await axios.get<PlayerContract>('/api/contracts/user/last/');

    return {
        ...data,
        last_bundle: maxBy(data.bundles, 'id')
    };
};

export const getBundle = async () => {
    // const { data } = await axios.get<{
    //     result: number,
    //     currencies: { rate: number, value: number, name: string }[]
    // }>('/api/accounting/reports/bundle/result/');
    const { data } = await axios.get<{
        result: number,
        currencies: { rate: number, value: number, name: string }[]
    }>('/api/accounting/reports/bundleresult/');

    return {
        result: data.result.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        currencies: data.currencies.map(val =>
            `${val.value.toLocaleString('en-US', { style: 'currency', currency: val.name })}${val.name !== 'USD' ? ` (${val.rate})` : ''}`
        ).join(', ')
    };
};

export const getContractTournaments = async () => {
    const { data } = await axios.get<string>('/api/shark/playergroup/totalentries/contract/');

    return data;
};

export const getBundleTournaments = async () => {
    const { data } = await axios.get<string>('/api/shark/playergroup/totalentries/bundle/');

    return data;
};
