import axios from 'axios_template';

import { UserInfo } from 'types/backend/UserInfo';
import { FrontendSettings } from 'types/backend/FrontendSettings';

export const getAlerts = () => {
    return axios.get<{
        id: number;
        text: string;
        type: 'success' | 'warning' | 'error'
    }[]>('/api/web/messages/');
};

export const getUserPermissions = async () => {
    const { data } = await axios.get('/api/users/permissions/');

    return data;
};

export const getProfile = async () => {
    const { data } = await axios.get<UserInfo>('/api/users/info/user/');

    return data;
};

export const getSettings = async () => {
    const { data } = await axios.get<FrontendSettings>('/api/settings/frontend/');

    return data;
}
