import axios from 'axios_template';

import { Player } from 'types/backend/Player';

export const getCurrentUser = async () => {
    const { data } = await axios.get<Player>('/api/users/current/');

    return data;
};

export const getPayout = async () => {
    const { data } = await axios.get<{
        payout: number;
        personal_share: number;
        total: number;
        min_personalfunds?: number;
    }>('/api/users/current/payout/');

    return data;
};

export const updateHomeworkPlayer = async ({ id, ...body }) => {
    const { data } = await axios.patch(`/api/curators/homework/${id}/player/`, body);

    return data;
};

export const getReadPlayerCount = async () => {
    const { data } = await axios.get(`/api/curators/notifications/?role=player`);

    return data;
};

export const getUserNicknames = async () => {
    const { data } = await axios.get<{
        nicknames: {
            country: string;
            country_name: string;
            last_activity: string;
            name: string;
            network: string;
        }[];
    }>('/api/shark/playergroup/alltournaments/');

    return {
        ...data,
        nicknames_tooltip: data.nicknames.map(val => `${val.name} (${val.network})`).join(', '),
    };
};

export const getBonuses = async () => {
    const { data } = await axios.get(`/api/accounting/bonus/`);

    return data;
};

export const getSharkscope = async () => {
    const { data } = await axios.get(`/api/shark/stats/30days/`);

    return data;
};

export const getDeductiblesCats = async () => {
    const { data } = await axios.get(`/api/accounting/deductibles/categories/`);

    return data;
};
