import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { login } from 'store/ducks/auth/auth';
import { AppState } from 'store/configureStore';

const loginSchema = yup.object().shape({
    username: yup.string().required('Логин обязательное поле'),
    password: yup.string().required('Пароль обязательное поле'),
});

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state: AppState) => state.auth.loading);

    const { handleSubmit, control, formState: { errors } } = useForm<{ username: string; password: string; }>({
        resolver: yupResolver(loginSchema),
    });

    const onSubmit = handleSubmit(data => {
        dispatch(login(data));
    });

    return (
        <main className='min-h-screen min-w-screen flex items-center justify-center'>
            <div className='pr-20 mr-20 border-r lg:block hidden'>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logos/logo_kot.svg`}
                    alt='King of Tournaments'
                    className='h-44'
                    style={{filter: 'invert(1)'}}
                />
            </div>
            <form style={{maxWidth: '300px'}} noValidate className='w-full' onSubmit={onSubmit}>
                <div className='flex items-center mb-6'>
                    <div className='pr-5 mr-5 border-r lg:hidden block'>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/logos/logo_kot.svg`}
                            alt='King of Tournaments'
                            className='h-14'
                            style={{filter: 'invert(1)'}}
                        />
                    </div>
                    <p className='font-medium text-2xl text-gray-700'>Личный кабинет</p>
                </div>
                <div className='mb-4'>
                    <Controller
                        name='username'
                        control={control}
                        defaultValue=''
                        render={({field}) => (
                            <TextField
                                error={!!errors.username?.message}
                                helperText={errors.username?.message}
                                label='Логин'
                                type='text'
                                variant='outlined'
                                className='w-full'
                                required
                                {...field}
                            />
                        )}
                    />
                </div>
                <div className='mb-8'>
                    <Controller
                        name='password'
                        control={control}
                        defaultValue=''
                        render={({field}) => (
                            <TextField
                                error={!!errors.password?.message}
                                helperText={errors.password?.message}
                                label='Пароль'
                                type='password'
                                variant='outlined'
                                className='w-full'
                                required
                                {...field}
                            />
                        )}
                    />
                </div>
                <div className='w-full text-right'>
                    <Button
                        loading={loading}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Вход
                    </Button>
                </div>
            </form>
        </main>
    );
};

export default Login;
