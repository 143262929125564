import axios from 'axios_template';

import { PersonalFunds } from 'types/backend/PersonalFunds';

export const getCashFlow = async () => {
    // const { data } = await axios.get<CashFlow[]>('/api/accounting/cashflows/');
    const { data } = await axios.get<{
        id: number;
        room_name: string;
        room_short_name: string;
        account_record_name: string;
        currency_name: string;
        amount: number;
        is_payout: boolean;
        is_processed: boolean;
        created_datetime: string;
    }[]>('/api/accounting/playerfundflow/');

    return data;
};

export const getPersonalFunds = async () => {
    // const { data } = await axios.get<{
    //     sum: number;
    //     currencies: PersonalFunds[];
    //     forex: { [key: string]: number };
    //     total_balance: {
    //         sum: number;
    //         currencies: { [key: string]: number };
    //     };
    // }>('/api/accounting/reports/personalfunds/sum/');
    const { data } = await axios.get<{
        sum: number;
        currencies: PersonalFunds[];
        forex: { [key: string]: number };
        total_balance: {
            sum: number;
            currencies: { [key: string]: number };
        };
    }>('/api/accounting/reports/personalfundssum/');

    return {
        ...data,
        sum: data.sum.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        currencies: data.currencies.map(val => ({
            ...val,
            amount: val.amount.toLocaleString('en-US', {
                style: 'currency',
                currency: val.currency_name
            }) + (val.currency_name === 'USD' ? '' : ` (${data.forex[val.currency_name]})`)
        })),
        total_balance: {
            ...data.total_balance,
            sum: data.total_balance.sum.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            currencies: Object.keys(data.total_balance.currencies)
                .map(currency => ({
                    currency,
                    sum: data.total_balance.currencies[currency].toLocaleString('en-US', {
                        style: 'currency', currency
                    }) + (currency === 'USD' ? '' : ` (${data.forex[currency]})`)
                }))
        }
    };
};
