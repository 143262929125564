import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import BalanceAccount from '@mui/icons-material/AccountBalanceOutlined';
import ContractIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PackIcon from '@mui/icons-material/ListAltOutlined';
import Receipt from '@mui/icons-material/ReceiptLong';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Dialog, useMediaQuery } from '@mui/material';
import moment from 'moment';

import { useQuery } from 'react-query';

import { getPersonalFunds } from 'api/dashboard';
import { getBundle, getBundleTournaments, getContract, getContractTournaments, getLastContract } from 'api/contract';
import { getCurrentUser, getPayout, getUserNicknames, getDeductiblesCats } from 'api/user';
import { getRooms } from 'api/rooms';
import { getTournamentsDataExpected } from 'api/tournaments';

import { RoomCard } from 'types/backend/RoomCard';
import { Transfer } from 'types/backend/Transfer';

import InfoCard from 'components/InfoCard/InfoCard';

import ReserveBalance from './components/ReserveBalance/ReserveBalance';
import ConfirmTransfer from './components/ConfirmTransfer/ConfirmTransfer';
import ChangeRoomBalance from './components/ChangeRoomBalance/ChangeRoomBalance';
import TransferToRoom from './components/TransferToRoom/TransferToRoom';
import DepositRequest from './components/DepositRequest/DepositRequest';
import CashFlow from './components/CashFlow/CashFlow';
import RequestPayout from './components/RequestPayout/RequestPayout';
import TransfersTable from './components/TransfersTable/TransfersTable';
import CashFlowTable from './components/CashFlowTable/CashFlowTable';
import DepositsTable from './components/DepositsTable/DepositsTable';
import DepositsListTable from './components/DepositsListTable/DepositsListTable';
import RoomsCards from './components/RoomsCards/RoomsCards';
import SharkScopeCard from './components/SharkScopeCard/SharkScopeCard';
import BonusCard from './components/BonusCard/BonusCard';
import ReviewDialog from './components/ReviewDialog/ReviewDialog';
import HandsModal from './components/HandsModal/HandsModal';
import theme from 'themes/mainTheme';

export interface HomeState {
    transfer: Transfer;
    room: RoomCard;
    reserveTransferOpen: boolean;
    confirmTransferOpen: boolean;
    changeBalanceOpen: boolean;
    chasflowOpen: boolean;
    transferBalanceOpen: boolean;
    depositRequestOpen: boolean;
    requestPayoutOpen: boolean;
}

const initialState: HomeState = {
    transfer: null,
    room: null,
    reserveTransferOpen: false,
    confirmTransferOpen: false,
    changeBalanceOpen: false,
    chasflowOpen: false,
    transferBalanceOpen: false,
    depositRequestOpen: false,
    requestPayoutOpen: false,
};

const reducer = (state = initialState, action: any) => ({ ...state, ...action });

const Home: React.FC = () => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, dispatch] = React.useReducer<React.Reducer<HomeState, any>>(reducer, initialState);
    const [showReviewDialog, setShowReviewDialog] = React.useState(false);
    const [showHandsModal, setShowHandsModal] = useState(false);
    const [handsModelForceClosed, setHandsModelForceClosed] = useState(false);

    const personalFundsQuery = useQuery('personalfunds', getPersonalFunds, { staleTime: 60000 });
    const currentUserQuery = useQuery('user', getCurrentUser, { staleTime: 60000 });
    const payoutQuery = useQuery('payout', getPayout, { staleTime: 60000 });
    const contractQuery = useQuery('contract', getContract, { staleTime: 60000 });
    const bundleQuery = useQuery('bundle', getBundle, { staleTime: 60000 });
    const lastContractQuery = useQuery('lastcontract', getLastContract, { staleTime: 60000 });
    const contractTournamentsQuery = useQuery('contracttournaments', getContractTournaments, { staleTime: 60000 });
    const bundleTournamentsQuery = useQuery('bundletournaments', getBundleTournaments, { staleTime: 60000 });
    const userNicknamesQuery = useQuery('usernicknames', getUserNicknames, { staleTime: 60000 });
    const roomsQuery = useQuery('dashboardrooms', getRooms, { staleTime: 60000 });
    const deductiblesCatsQuery = useQuery('deductibles-categories-balance', getDeductiblesCats, { staleTime: 60000 });
    const tournamentsDataExpectedQuery = useQuery('tournaments-data-expected', getTournamentsDataExpected, {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (
            tournamentsDataExpectedQuery.data?.h2n ||
            tournamentsDataExpectedQuery.data?.ggnetwork ||
            tournamentsDataExpectedQuery.data?.ggsummary ||
            tournamentsDataExpectedQuery.data?.ggscreen
        ) {
            if (!handsModelForceClosed) {
                setShowHandsModal(true);
            }
            // setShowHandsModal(true);
        } else if (showHandsModal) {
            setShowHandsModal(false);
        }
    }, [setShowHandsModal, showHandsModal, tournamentsDataExpectedQuery]);

    function handleCloseModal() {
        const today = moment();
        const deadline = moment().date(5);

        if (!today.isAfter(deadline)) {
            setShowHandsModal(false);
            setHandsModelForceClosed(true);
        }
    }

    return (
        <div>
            <div className="w-full flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4">
                <InfoCard
                    color="bg-yellow-500"
                    width="w-full lg:w-2/5"
                    loading={personalFundsQuery.isFetching || currentUserQuery.isFetching || payoutQuery.isFetching}
                >
                    <div className="flex">
                        <BalanceAccount className="mr-6 text-5xl lg:self-center self-start" />
                        <div className="flex-1 2xl:flex-row flex-col flex items-center">
                            <div className="flex-1 w-full flex">
                                <Tooltip
                                    title={
                                        <React.Fragment>
                                            <div className="mb-2">
                                                <p className="font-bold">Личные средства: {personalFundsQuery.data?.sum}</p>
                                                {personalFundsQuery.data?.currencies.map((val, i) => (
                                                    <p key={i} className="ml-4">
                                                        – {val.amount}
                                                    </p>
                                                ))}
                                            </div>
                                            <p className="font-bold">Общий баланс: {personalFundsQuery.data?.total_balance.sum}</p>
                                            {personalFundsQuery.data?.total_balance.currencies.map((val, i) => (
                                                <p key={i} className="ml-4">
                                                    – {val.sum}
                                                </p>
                                            ))}
                                        </React.Fragment>
                                    }
                                >
                                    <div>
                                        <p className="text-xl font-light">{personalFundsQuery.data?.sum}</p>
                                        <p className="text-sm font-bold">Личные средства</p>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="text-xs font-light self-end">
                                <a href="https://goo.su/2I57" target="_blank" rel="noreferrer">
                                    Уровень {currentUserQuery.data?.player_level.name} ({currentUserQuery.data?.player_level.group.name})
                                </a>
                                <Tooltip
                                    title={
                                        <React.Fragment>
                                            <p>
                                                Бекинг: ${payoutQuery.data?.payout}%, Доля игрока: ${payoutQuery.data?.personal_share}%
                                            </p>
                                            {payoutQuery.data?.min_personalfunds ? (
                                                <p className="mt-2">
                                                    Минимальный остаток личных средств: {payoutQuery.data.min_personalfunds}.<br />
                                                    <em>* рассчитано исходя из БР в 500 БИ среднего лимита за последние 30 дней</em>
                                                </p>
                                            ) : null}
                                        </React.Fragment>
                                    }
                                >
                                    <p>Общий процент: {payoutQuery.data?.total}%</p>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </InfoCard>
                <InfoCard
                    color="bg-green-500"
                    width="w-full lg:w-2/5"
                    loading={
                        contractQuery.isFetching ||
                        lastContractQuery.isFetching ||
                        contractTournamentsQuery.isFetching ||
                        deductiblesCatsQuery.isFetching ||
                        contractTournamentsQuery.isFetching
                    }
                >
                    <div className="flex">
                        <ContractIcon className="mr-6 text-5xl lg:self-center self-start" />
                        <div className="flex-1 2xl:flex-row flex-col flex items-center">
                            <div className="flex-1 w-full flex">
                                <Tooltip
                                    title={
                                        <>
                                            <p> {'Оллтайм: ' + contractQuery.data?.alltime_result} </p>
                                            <p> {'Контракт по валютам: ' + contractQuery.data?.currencies} </p>
                                            <br />
                                            <p className="font-bold">Долги:</p>
                                            {deductiblesCatsQuery.data?.map(item => (
                                                <p key={item.id} className="ml-4">
                                                    - {`${item.name}: $${item.balance}`}
                                                </p>
                                            ))}
                                        </>
                                    }
                                >
                                    <div>
                                        <p className="text-xl font-light">{contractQuery.data?.result}</p>
                                        <p className="text-sm font-bold">Контракт</p>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="text-xs font-light self-end">
                                <p>Дата начала: {moment(lastContractQuery.data?.start_datetime).format('DD.MM.YYYY')}</p>
                                <Tooltip title={userNicknamesQuery.data?.nicknames_tooltip}>
                                    <p>
                                        Турниров сыграно: {contractTournamentsQuery.data} / {lastContractQuery.data?.amount_games}
                                    </p>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </InfoCard>
                <InfoCard
                    color="bg-purple-500"
                    width="w-full lg:w-2/5"
                    loading={bundleQuery.isFetching || lastContractQuery.isFetching || bundleTournamentsQuery.isFetching || userNicknamesQuery.isFetching}
                >
                    <div className="flex">
                        <PackIcon className="mr-6 text-5xl lg:self-center self-start" />
                        <div className="flex-1 2xl:flex-row flex-col flex items-center">
                            <div className="flex-1 w-full flex">
                                <Tooltip title={bundleQuery.data?.currencies}>
                                    <div>
                                        <p className="text-xl font-light">{bundleQuery.data?.result}</p>
                                        <p className="text-sm font-bold">Пакет</p>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="text-xs font-light self-end">
                                <p>Дата начала: {moment(lastContractQuery.data?.last_bundle.start_datetime).format('DD.MM.YYYY')}</p>
                                <Tooltip title={userNicknamesQuery.data?.nicknames_tooltip}>
                                    <p>Турниров сыграно: {bundleTournamentsQuery.data}</p>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </InfoCard>
            </div>
            <div className="w-full flex justify-around lg:flex-row flex-col mb-4 mt-4 lg:space-x-8 lg:space-y-0 space-y-4">
                <SharkScopeCard />
                <BonusCard />
                <div className="w-full lg:w-1/3">
                    <div className="w-full flex justify-end mb-4">
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={<Receipt />}
                            disabled={currentUserQuery.isFetching || payoutQuery.isFetching || roomsQuery.isFetching}
                            onClick={() => {
                                dispatch({ requestPayoutOpen: true });
                            }}
                        >
                            Запросить расчёт
                        </Button>
                    </div>
                    <div className="w-full flex justify-end mb-4">
                        <Button
                            variant="outlined"
                            color="success"
                            size="large"
                            startIcon={<RateReviewIcon />}
                            onClick={() => {
                                setShowReviewDialog(true);
                            }}
                        >
                            Оставить отзыв
                        </Button>
                    </div>
                </div>
            </div>

            <div className="rounded shadow-md bg-white overflow-hidden mb-8">
                <TransfersTable dispatch={dispatch} />
            </div>

            <RoomsCards dispatch={dispatch} query={roomsQuery} />

            <p className="text-xl text-gray-700 font-medium mb-2">Доступные запросы на депозит</p>
            <div className="rounded shadow-md bg-white overflow-hidden mb-8">
                <DepositsListTable />
            </div>

            <p className="text-xl text-gray-700 font-medium mb-2">Движение личных средств</p>
            <div className="rounded shadow-md bg-white overflow-hidden mb-8">
                <CashFlowTable />
            </div>

            <p className="text-xl text-gray-700 font-medium mb-2">Заявки на депозит</p>
            <div className="rounded shadow-md bg-white overflow-hidden">
                <DepositsTable />
            </div>

            <Dialog fullWidth maxWidth="sm" open={state.reserveTransferOpen} onClose={() => dispatch({ reserveTransferOpen: false })} fullScreen={fullScreen}>
                <ReserveBalance transfer={state.transfer} onClose={() => dispatch({ reserveTransferOpen: false })} />
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={state.confirmTransferOpen} onClose={() => dispatch({ confirmTransferOpen: false })} fullScreen={fullScreen}>
                <ConfirmTransfer transfer={state.transfer} onClose={() => dispatch({ confirmTransferOpen: false })} />
            </Dialog>

            <Dialog maxWidth="sm" open={state.changeBalanceOpen} onClose={() => dispatch({ changeBalanceOpen: false })} fullScreen={fullScreen}>
                <ChangeRoomBalance room={state.room} onClose={() => dispatch({ changeBalanceOpen: false })} />
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={state.chasflowOpen} onClose={() => dispatch({ chasflowOpen: false })} fullScreen={fullScreen}>
                <CashFlow room={state.room} onClose={() => dispatch({ chasflowOpen: false })} />
            </Dialog>

            <Dialog maxWidth="sm" open={state.depositRequestOpen} onClose={() => dispatch({ depositRequestOpen: false })} fullScreen={fullScreen}>
                <DepositRequest room={state.room} onClose={() => dispatch({ depositRequestOpen: false })} />
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={state.transferBalanceOpen} onClose={() => dispatch({ transferBalanceOpen: false })} fullScreen={fullScreen}>
                <TransferToRoom room={state.room} rooms={roomsQuery.data} onClose={() => dispatch({ transferBalanceOpen: false })} />
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={state.requestPayoutOpen} onClose={() => dispatch({ requestPayoutOpen: false })} fullScreen={fullScreen}>
                <RequestPayout rooms={roomsQuery.data} onClose={() => dispatch({ requestPayoutOpen: false })} />
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={showReviewDialog} onClose={() => setShowReviewDialog(false)} fullScreen={fullScreen}>
                <ReviewDialog close={() => setShowReviewDialog(false)} />
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={showHandsModal} onClose={handleCloseModal} fullScreen={fullScreen}>
                <HandsModal close={handleCloseModal} />
            </Dialog>
        </div>
    );
};

export default Home;
