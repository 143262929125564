import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import routes from 'constants/routes';
import { AppState } from 'store/configureStore';

function App() {
    const isAuthorized = useSelector((state: AppState) => !!state.auth.access);
    const permissions = useSelector((state: AppState) => state.auth.permissions);

    const routing = useRoutes(routes(isAuthorized, permissions));

    return (
        <div className="ag-theme-material" id="app">
            <CssBaseline />

            {routing}

            <ToastContainer hideProgressBar />
        </div>
    );
}

export default App;
