import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from 'react-query';
import Table from 'components/Table/Table';
import { getDepositsListRequest } from 'api/deposits';
import ConfirmDepositAssignement from './ConfirmDepositAssignment';

interface DepositsListTableProps {}

const DepositsListTable: React.FC<DepositsListTableProps> = () => {
    const depositsListQuery = useQuery('depositsTableau', getDepositsListRequest, { staleTime: 60000 });
    // const queryClient = useQueryClient();

    const [depositId, setDepositId] = React.useState(null);
    const [confirmDepositAssignDialogOpen, setConfirmDepositAssignDialogOpen] = React.useState(false);

    // const mutation = useMutation(reserveDeposit, {
    //     onSuccess: () => {
    //         queryClient.invalidateQueries('transfers');
    //         queryClient.invalidateQueries('depositsTableau');
    //     }
    // });

    return (
        <>
            <Table
                size="small"
                loading={depositsListQuery.isLoading}
                struct={[
                    { field: 'room_name', name: 'Рум' },
                    { field: 'currency_name', name: 'Валюта' },
                    { field: 'amount', name: 'Сумма' },
                    { field: 'comment', name: 'Комментарий' },
                    {
                        field: 'created_datetime',
                        name: 'Дата',
                        render: val => moment(val).format('DD.MM.YYYY HH:mm'),
                    },
                    {
                        field: 'actions',
                        name: '',
                        render: (_, row) => (
                            <div className="flex items-center space-x-2">
                                <Tooltip title="Забронировать выбранный запрос на депозит">
                                    <div className="w-full grid flex justify-end">
                                        <LoadingButton
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                                setDepositId(row.id);
                                                setConfirmDepositAssignDialogOpen(true);
                                            }}
                                        >
                                            Забронировать
                                        </LoadingButton>
                                    </div>
                                </Tooltip>
                            </div>
                        ),
                    },
                ]}
                rows={depositsListQuery.data || []}
            />

            <ConfirmDepositAssignement open={confirmDepositAssignDialogOpen} depositId={depositId} onClose={() => setConfirmDepositAssignDialogOpen(false)} />
        </>
    );
};

export default DepositsListTable;
