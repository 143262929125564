import React from 'react';
import moment from 'moment';

import CheckOutlined from '@mui/icons-material/CheckOutlined';
import ClearOutlined from '@mui/icons-material/ClearOutlined';

import { useQuery } from 'react-query';

import Table from 'components/Table/Table';

import { getCashFlow } from 'api/dashboard';

interface CashFlowTableProps {
}

const CashFlowTable: React.FC<CashFlowTableProps> = () => {
    const cashFlowQuery = useQuery('cashflow', getCashFlow, { staleTime: 60000 });

    return (
        <Table
            size="small"
            loading={cashFlowQuery.isLoading}
            struct={[
                { field: 'room_short_name', name: 'Рум' },
                { field: 'account_record_name', name: 'Аккаунт' },
                { field: 'amount', name: 'Сумма' },
                { field: 'currency_name', name: 'Валюта' },
                {
                    field: 'is_payout',
                    name: 'Тип',
                    render: (val, _) => val ? 'Расчёт за пакет' : 'ЛС'
                },
                {
                    field: 'created_datetime',
                    name: 'Дата',
                    render: val => moment(val).format('DD.MM.YYYY HH:mm:ss')
                },
                {
                    field: 'comment',
                    name: 'Комментарий',
                },
                {
                    field: 'is_processed',
                    name: '',
                    render: (_, row) => {
                        if (row.approved) return <CheckOutlined fontSize="small" className="text-green-600" />;
                        if (row.rejected) return <ClearOutlined fontSize="small" className="text-red-600" />;

                        return null;
                    }
                },
            ]}
            rows={cashFlowQuery.data || []}
            showPagination
        />
    );
};

export default CashFlowTable;
